import { Injectable } from "@angular/core";

import { LoggingService } from "@services/logging.service";
import { AnimalExtended, CohortExtended, JobPharmaDetailService, TaskValue } from "./job-pharma-detail.service";
import { AnimalService } from '../../../animals/services/animal.service';
import { CohortService } from '../../../cohort/services/cohort.service';
import { SampleService } from '../../../samples';
import { TaskService } from "../../../tasks/task.service";
import { ProtocolService } from "../../../protocol";
import { ConfirmOptions, ConfirmService } from "@common/confirm";

import { getSafeProp, notEmpty, uniqueArrayFromPropertyPath } from "@common/util";
import { DroppableEvent } from "@common/droppable-event";
import { Animal, Cohort, Entity, Job, Material, ProtocolInstance, Sample, SampleGroup, TaskInstance } from "@common/types";
import { TaskType } from "../../../tasks/models";
import { DataManagerService } from "@services/data-manager.service";
import { SaveChangesService } from "@services/save-changes.service";
import { isEmpty } from "lodash";
import { isAnyTaskEndState } from "src/app/tasks/util/is-any-task-end-state";
import { OverlayService } from "@services/overlay-service";
import { BulkAssignResult } from "../modals";
import { pluralize } from "@common/util/pluralize";

export interface TaskCohortMap {
    [taskInstanceKey: string]: {
        cohorts?: Entity<Cohort>[];
        task?: Entity<TaskInstance>;
        materials?: Entity<Material>[];
        sampleGroupsWithSamples?: Entity<SampleGroup>[];
        nonSampleSourceMaterials?: Entity<Material>[];
    }
}

export interface TaskMaterialMap {
    [taskInstanceKey: string]: {
        animals?: Entity<Animal>[];
        task?: Entity<TaskInstance>;
        materials?: Entity<Material>[];
        sampleGroupsWithSamples?: Entity<SampleGroup>[];
        nonSampleSourceMaterials?: Entity<Material>[];
    }
}

@Injectable()
export class JobPharmaTableService {
    readonly COMPONENT_LOG_TAG = 'job-pharma-table-service';
    readonly AMOUNT_ENTITIES_LIMIT = 150;
    constructor(
        private dataManager: DataManagerService,
        private loggingService: LoggingService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private animalService: AnimalService,
        private cohortService: CohortService,
        private sampleService: SampleService,
        private taskService: TaskService,
        private protocolService: ProtocolService,
        private saveChangesService: SaveChangesService,
        private overlayService: OverlayService,
        private confirmService: ConfirmService
        ){}


    public async onDropToProtocolInstance(
        job: Entity<Job>, 
        protocolInstance: Entity<ProtocolInstance>, 
        isLocked: boolean, 
        event: DroppableEvent
    ): Promise<any> {
        if (!this.checkIfIsInViewPort(event)) {
            return;
        }
        if (isLocked) {
            this.loggingService.logWarning(
                'Cannot add to task - Task is locked.',
                null,
                this.COMPONENT_LOG_TAG,
                true
            );
            return;
        }

        
        const dragged = this.jobPharmaDetailService.getDragged();
        if (dragged && dragged?.entities && dragged?.entities?.length > 0) {
            switch (dragged.type) {
                case 'AnimalPlaceholder':
                    await this.onDropAnimalPlaceholdersToProtocolInstance(protocolInstance, dragged.entities);
                    const animals = dragged.entities
                        .filter((animalPlaceholder: any) => animalPlaceholder.Material && animalPlaceholder.Material.Animal)
                        .map((animalPlaceholder: any) => animalPlaceholder.Material.Animal);
                    if (animals?.length > 0) {
                        await this.addAnimalsToProtocol(job, protocolInstance, animals);
                    }
                    break;   

                case 'Placeholder':
                    await this.onDropPlaceholdersToProtocolInstance(protocolInstance, dragged.entities);
                    const cohorts = dragged.entities.filter((placeholder: any) => placeholder.JobCohort).map((placeholder: any) => placeholder.JobCohort.Cohort);
                    if (cohorts?.length > 0) {
                        await this.addCohortsToProtocol(job, protocolInstance, cohorts);
                    }
                    break;

                case 'Cohort':
                    await this.addCohortsToProtocol(job, protocolInstance, dragged.entities);
                    break;
                
                case 'Animal':
                    await this.addAnimalsToProtocol(job, protocolInstance, dragged.entities);
                    break;

                case 'Sample':
                    await this.addSamplesToProtocol(job, protocolInstance, dragged.entities);
                    break;
            }
        } else {
            const animals = this.animalService.draggedAnimals as Entity<Animal & AnimalExtended>[];
            if (animals?.length > 0) {
                await this.addAnimalsToProtocol(job, protocolInstance, animals);
                this.animalService.draggedAnimals = [];
                return;
            }

            const cohorts = this.cohortService.draggedCohorts as Entity<Cohort & CohortExtended>[];
            if (cohorts?.length > 0) {
                await this.addCohortsToProtocol(job, protocolInstance, cohorts);
                this.cohortService.draggedCohorts = [];
                return;
            }

            const samples = this.sampleService.draggedSamples as Entity<Sample>[];
            if (samples?.length > 0) {
                await this.addSamplesToProtocol(job, protocolInstance, samples);
                this.sampleService.draggedSamples = [];
                return;
            } 
        }
    }

    private checkIfIsInViewPort(event: DroppableEvent): boolean {
        const rect = document.querySelector('.job-pharma-tabset.tasks ~ .tab-content').getBoundingClientRect();
        return (event.event.clientX >= rect.left &&
            event.event.clientX <= rect.right &&
            event.event.clientY >= rect.top &&
            event.event.clientY <= rect.bottom);
    }

    private async addAnimalsToProtocol(
        job: Entity<Job>,
        protocolInstance: Entity<ProtocolInstance>, 
        animals: Entity<Animal & AnimalExtended>[]
    ): Promise<void> {
        if (protocolInstance?.entityAspect?.entityState?.isAdded()) {
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
        }

        const isContinue = await this.jobPharmaDetailService.showModalAnimalsToProtocol(protocolInstance, animals);
        if (!isContinue) {
            return;
        }

        const materials = animals.map(a => a.Material) as Entity<Material>[];

        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            const sampleGroups = await this.jobPharmaDetailService.getSampleGroupsWithSamples(protocolInstance.TaskInstance);
            const sources = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroups, materials);
            
            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups(protocolInstance.TaskInstance as Entity<TaskInstance>[]);
            if (hasEndStateTask) {
                return;
            }

            if (!isEmpty(sampleGroups) && !isEmpty(sources)) {
                const totalNumberOfSamplesToCreate = this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroups, sources.length);
                const confirm = await this.jobPharmaDetailService.showCreateSamplesModal(totalNumberOfSamplesToCreate);
                if (!confirm) {
                    return;
                }
                
                const success = await this.jobPharmaDetailService.addAnimalsToProtocol(job, protocolInstance, materials);
                if (!success) {
                    return;
                }
                await this.jobPharmaDetailService.handleSampleCreates(sampleGroups, sources);
                return;
            }
        }

        await this.jobPharmaDetailService.addAnimalsToProtocol(job, protocolInstance, materials);
    }

    private async addCohortsToProtocol(
        job: Entity<Job>,
        protocolInstance: Entity<ProtocolInstance>,
        cohorts: Entity<Cohort & CohortExtended>[],      
    ): Promise<void> {
        if (protocolInstance?.entityAspect?.entityState?.isAdded()) {
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
        }

        const isContinue = await this.jobPharmaDetailService.showModalCohortsToProtocol(protocolInstance, cohorts);
        if (!isContinue) {
            return;
        }

        await this.dataManager.ensureRelationships(cohorts, [
            'CohortMaterial.Material',
            'CohortMaterial.Material.Animal',
            'CohortMaterial.Material.Sample']);
      
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            const sampleGroups = await this.jobPharmaDetailService.getSampleGroupsWithSamples(protocolInstance.TaskInstance);
            const materials = cohorts.flatMap(cohort => cohort.CohortMaterial.flatMap(cm => cm.Material));
            const sources = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroups, materials);

            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups(protocolInstance.TaskInstance as Entity<TaskInstance>[]);
            if (hasEndStateTask) {
                return;
            }

            if (!isEmpty(sampleGroups) && !isEmpty(sources)) {
                const totalNumberOfSamplesToCreate = this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroups, sources.length);
                const confirm = await this.jobPharmaDetailService.showCreateSamplesModal(totalNumberOfSamplesToCreate);
                if (!confirm) {
                    return;
                }
                
                const success = await this.jobPharmaDetailService.addCohortsToProtocol(job, protocolInstance, cohorts);
                if (!success) {
                    return;
                }
                await this.jobPharmaDetailService.handleSampleCreates(sampleGroups, sources, false);
                // TODO: find a faster way to refresh dependant views
                this.jobPharmaDetailService.notifyJobArrayChanged('JobMaterial');
                this.jobPharmaDetailService.notifyJobArrayChanged('TaskJob');
                return;
            }
        }

        await this.jobPharmaDetailService.addCohortsToProtocol(job, protocolInstance, cohorts);
        // TODO: find a faster way to refresh dependant views
        this.jobPharmaDetailService.notifyJobArrayChanged('JobMaterial');
        this.jobPharmaDetailService.notifyJobArrayChanged('TaskJob');
    }

    private async addSamplesToProtocol(
        job: Entity<Job>,
        protocolInstance: Entity<ProtocolInstance>, 
        samples: Entity<Sample>[]
    ) {
        if (protocolInstance?.entityAspect?.entityState?.isAdded()) {
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
        }

        const materials = samples.map(s => s.Material) as Entity<Material>[];

        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            const sampleGroups = await this.jobPharmaDetailService.getSampleGroupsWithSamples(protocolInstance.TaskInstance);
            const sources = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroups, materials);

            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups(protocolInstance.TaskInstance as Entity<TaskInstance>[]);
            if (hasEndStateTask) {
                return;
            }

            if (!isEmpty(sampleGroups) && !isEmpty(sources)) {               
                const totalNumberOfSamplesToCreate = this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroups, sources.length);
                const confirm = await this.jobPharmaDetailService.showCreateSamplesModal(totalNumberOfSamplesToCreate);
                if (!confirm) {
                    return;
                }
                
                const success = await this.jobPharmaDetailService.addAnimalsToProtocol(job, protocolInstance, materials);
                if (!success) {
                    return;
                }
                await this.jobPharmaDetailService.handleSampleCreates(sampleGroups, sources);
                return;
            }
        }

        await this.jobPharmaDetailService.addAnimalsToProtocol(job, protocolInstance, materials);
    }

    private async onDropAnimalPlaceholdersToProtocolInstance(protocolInstance: Entity<ProtocolInstance>, animalPlaceholders: any) {
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            await this.dataManager.ensureRelationships(protocolInstance.TaskInstance, ['SampleGroup.Sample']);

            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups(protocolInstance.TaskInstance as Entity<TaskInstance>[]);
            if (hasEndStateTask) {
                return;
            }
        }

        animalPlaceholders.forEach((placeholder: any) => {
            protocolInstance.TaskInstance.forEach((task: any) => {
                if (!task.WorkflowTask.NoMaterials) {
                    this.jobPharmaDetailService.addAnimalPlaceholderToTask(task, placeholder);
                }
            });
        });
    }

    private async onDropPlaceholdersToProtocolInstance(protocolInstance: Entity<ProtocolInstance>, placeholders: any) {
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            await this.dataManager.ensureRelationships(protocolInstance.TaskInstance, ['SampleGroup.Sample']);

            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups(protocolInstance.TaskInstance as Entity<TaskInstance>[]);
            if (hasEndStateTask) {
                return;
            }
        }

        placeholders.forEach((placeholder: any) => {
            protocolInstance.TaskInstance.forEach((task: any) => {
                if (!task.WorkflowTask.NoMaterials) {
                    this.jobPharmaDetailService.addPlaceholderToTask(task, placeholder);
                }
            });
        });
    }

    public async onDropToJob(job: Entity<Job>) {
        const draggedTasks = this.taskService.draggedTasks;
        const draggedProtocols = this.protocolService.draggedProtocols;
        if (notEmpty(draggedTasks)) {
            const defaultTaskStatusKey = await this.jobPharmaDetailService.getDefaultTaskStatusKey();
            const tasks = draggedTasks
                .filter(t => this.taskIsValidType(t))
                .map(t => {
                    return {
                        C_WorkflowTask_key: t.C_WorkflowTask_key,
                        C_TaskStatus_key: defaultTaskStatusKey,
                        NoMaterials: t.NoMaterials,
                    }
                }) as TaskValue[];
            this.taskService.draggedTasks = [];
            return await this.jobPharmaDetailService.addTasks(job, tasks);
        } else if (notEmpty(draggedProtocols)) {
            this.protocolService.draggedProtocols = [];
            return await this.jobPharmaDetailService.addProtocols(job, draggedProtocols);
        }
    }

    private taskIsValidType(workflowTask: any): boolean {
        const taskType = getSafeProp(workflowTask, 'cv_TaskType.TaskType');
        return (taskType === TaskType.Job);
    }

    public async onDropToTask(
        job: Entity<Job>,
        task: Entity<TaskInstance>,
        event: DroppableEvent
    ): Promise<any> {
        if (!this.checkIfIsInViewPort(event)) {
            return;
        }
        if (task.IsLocked) {
            this.loggingService.logWarning(
                'Cannot add to task - Task is locked.',
                null,
                this.COMPONENT_LOG_TAG,
                true
            );
            return;
        }
        
        const dragged = this.jobPharmaDetailService.getDragged();
        if (dragged && dragged?.entities && dragged?.entities?.length > 0) {
            switch (dragged.type) {
                case 'AnimalPlaceholder':
                    await this.onDropAnimalPlaceholdersToTask(task, dragged.entities);
                    const animals = dragged.entities
                        .filter((animalPlaceholder: any) => animalPlaceholder.Material && animalPlaceholder.Material.Animal)
                        .map((animalPlaceholder: any) => animalPlaceholder.Material.Animal);
                    if (animals?.length > 0) {
                        await this.addAnimalsToTask(job, task, animals);
                    }
                    break;   

                case 'Placeholder':
                    await this.onDropPlaceholderToTask(task, dragged.entities);
                    const cohorts = dragged.entities.filter((placeholder: any) => placeholder.JobCohort).map((placeholder: any) => placeholder.JobCohort.Cohort);
                    if (cohorts?.length > 0) {
                        await this.addCohortsToTask(job, task, cohorts);
                    }
                    break;

                case 'Cohort':
                    await this.addCohortsToTask(job, task, dragged.entities);
                    break;
                
                case 'Animal':
                    await this.addAnimalsToTask(job, task, dragged.entities);
                    break;

                case 'Sample':
                    await this.addSamplesToTask(job, task, dragged.entities);
                    break;
            }
        } else {
            const animals = this.animalService.draggedAnimals as Entity<Animal & AnimalExtended>[];
            if (animals?.length > 0) {
                await this.addAnimalsToTask(job, task, animals);
                this.animalService.draggedAnimals = [];
                return;
            }

            const cohorts = this.cohortService.draggedCohorts as Entity<Cohort & CohortExtended>[];
            if (cohorts?.length > 0) {
                await this.addCohortsToTask(job, task, cohorts);
                this.cohortService.draggedCohorts = [];
                return;
            }

            const samples = this.sampleService.draggedSamples as Entity<Sample>[];
            if (samples?.length > 0) {
                await this.addSamplesToTask(job, task, samples);
                this.sampleService.draggedSamples = [];
                return;
            } 
        }
    }

    private async addAnimalsToTask(
        job: Entity<Job>,
        task: Entity<TaskInstance>, 
        animals: Entity<Animal & AnimalExtended>[]
    ): Promise<void> {
        if (task?.entityAspect?.entityState?.isAdded()) {
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
        }

        const isContinue = await this.jobPharmaDetailService.showModalAnimalsToTask(task, animals);
        if (!isContinue) {
            return;
        }

        const materials = animals.map(a => a.Material) as Entity<Material>[];
        
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            const sampleGroups = await this.jobPharmaDetailService.getSampleGroupsWithSamples([task]);
            const sources = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroups, materials);
            
            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups([task]);
            if (hasEndStateTask) {
                return;
            }

            if (!isEmpty(sampleGroups) && !isEmpty(sources)) {
                const totalNumberOfSamplesToCreate = this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroups, sources.length);
                const confirm = await this.jobPharmaDetailService.showCreateSamplesModal(totalNumberOfSamplesToCreate);
                if (!confirm) {
                    return;
                }
                
                const success = await this.jobPharmaDetailService.addAnimalsToTask(job, task, materials);
                if (!success) {
                    return;
                }
                await this.jobPharmaDetailService.handleSampleCreates(sampleGroups, sources);
                return;
            }
        }

        await this.jobPharmaDetailService.addAnimalsToTask(job, task, materials);
    }

    private async addCohortsToTask(
        job: Entity<Job>,
        task: Entity<TaskInstance>,
        cohorts: Entity<Cohort & CohortExtended>[],      
    ): Promise<void> {
        if (task?.entityAspect?.entityState?.isAdded()) {
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
        }

        await this.dataManager.ensureRelationships(cohorts, ['CohortMaterial.Material']);

        const materials = cohorts.flatMap(cohort => cohort.CohortMaterial.flatMap(cm => cm.Material));

        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            const sampleGroups = await this.jobPharmaDetailService.getSampleGroupsWithSamples([task]);
            const sources = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroups, materials);

            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups([task]);
            if (hasEndStateTask) {
                return;
            }

            if (!isEmpty(sampleGroups) && !isEmpty(sources)) {
                const totalNumberOfSamplesToCreate = this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroups, sources.length);
                const confirm = await this.jobPharmaDetailService.showCreateSamplesModal(totalNumberOfSamplesToCreate);
                if (!confirm) {
                    return;
                }

                const success = await this.jobPharmaDetailService.assignCohortToTaskImmediately(job, task, cohorts);
                if (!success) {
                    return;
                }

                await this.jobPharmaDetailService.handleSampleCreates(sampleGroups, sources, false);
                // TODO: find a faster way to refresh dependant views

                this.jobPharmaDetailService.notifyJobArrayChanged('JobMaterial');
                this.jobPharmaDetailService.notifyJobArrayChanged('TaskJob');
                return;
            }
        }

        await this.jobPharmaDetailService.assignCohortToTaskImmediately(job, task, cohorts);
        // TODO: find a faster way to refresh dependant views
        this.jobPharmaDetailService.notifyJobArrayChanged('JobMaterial');
        this.jobPharmaDetailService.notifyJobArrayChanged('TaskJob');
    }

    private async addSamplesToTask(
        job: Entity<Job>,
        task: Entity<TaskInstance>, 
        samples: Entity<Sample>[]
    ) {
        if (task?.entityAspect?.entityState?.isAdded()) {
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
        }

        const materials = samples.map(s => s.Material) as Entity<Material>[];

        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            const sampleGroups = await this.jobPharmaDetailService.getSampleGroupsWithSamples([task]);
            const sources = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroups, materials);

            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups([task]);
            if (hasEndStateTask) {
                return;
            }

            if (!isEmpty(sampleGroups) && !isEmpty(sources)) {
                const totalNumberOfSamplesToCreate = this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroups, sources.length);
                const confirm = await this.jobPharmaDetailService.showCreateSamplesModal(totalNumberOfSamplesToCreate);
                if (!confirm) {
                    return;
                }
                
                const success = await this.jobPharmaDetailService.addAnimalsToTask(job, task, materials);
                if (!success) {
                    return;
                }
                await this.jobPharmaDetailService.handleSampleCreates(sampleGroups, sources);
                return;
            }
        }

        await this.jobPharmaDetailService.addAnimalsToTask(job, task, materials);
    }

    private async onDropAnimalPlaceholdersToTask(task: Entity<TaskInstance>, animalplaceholders: any[]) {
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            await this.dataManager.ensureRelationships([task], ['SampleGroup.Sample']);

            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups([task]);
            if (hasEndStateTask) {
                return;
            }
        }

        animalplaceholders.forEach((placeholder: any) => {
            this.jobPharmaDetailService.addAnimalPlaceholderToTask(task, placeholder);
        });
    }

    private async onDropPlaceholderToTask(task: Entity<TaskInstance>, placeholders: any) {
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            await this.dataManager.ensureRelationships([task], ['SampleGroup.Sample']);

            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups([task]);
            if (hasEndStateTask) {
                return;
            }
        }

        placeholders.forEach((placeholder: any) => {
            this.jobPharmaDetailService.addPlaceholderToTask(task, placeholder);
        });
    }

    /**
     * 
     * @param tasks 
     * @returns {Promise<boolean>} true if any member tasks (with sample groups and samples created) are end state, otherwise false
     */
    private async handleEndStateTasksWithSampleGroups(tasks: Entity<TaskInstance>[]): Promise<boolean> {
        // Gets all member tasks that are associated with a sample group that has samples created
        const memberTasksWithSampleGroups = tasks
            .filter(ti => ti.SampleGroup.length > 0)
            .filter(ti => ti.SampleGroup.find(sg => sg.Sample.length > 0))
            .flatMap(ti => ti.MemberTaskInstance);
        if (isAnyTaskEndState(memberTasksWithSampleGroups)) {
            await this.jobPharmaDetailService.showCompletedTasksModal();
            return true
        }

        return false;
    }

    public async onBulkAssign(job: Entity<Job>, data: BulkAssignResult) {
        const { taskMaterialMap, taskCohortMap } = await this.prepareBulkAssign(data);

        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            const hasEndStateTask = await this.handleEndStateTasksWithSampleGroups(data.selectedTasks);
            if (hasEndStateTask) {
                return;
            }

            const confirm = await this.showBulkAssignCreateSamplesModal(data, taskMaterialMap, taskCohortMap);
            if (!confirm) {
                return;
            }
        }

        await this.bulkAssign(job, data, taskMaterialMap, taskCohortMap);
    }

    private async prepareBulkAssign(data: BulkAssignResult) {
        let taskMaterialMap: TaskMaterialMap = {};
        if (data && data.selectedAnimals.length > 0 && data.selectedTasks.length > 0) {
            taskMaterialMap = await this.prepareBulkAssignAnimalToTasks(data.selectedTasks, data.selectedAnimals);
        }

        let taskCohortMap: TaskCohortMap = {};
        if (data && data.selectedCohorts.length > 0 && data.selectedTasks.length > 0) {
            taskCohortMap = await this.prepareBulkAssignCohortsToTasks(data.selectedTasks, data.selectedCohorts);
        }

        return {
            taskMaterialMap,
            taskCohortMap
        }
    }

    private async prepareBulkAssignAnimalToTasks(tasks: Entity<TaskInstance>[], animals: Entity<Animal>[]) {
        const taskMap: TaskMaterialMap = {};
        for (const task of tasks) {
            taskMap[task.C_TaskInstance_key] = { task };

            const currentMaterialKeys = uniqueArrayFromPropertyPath(task, 'MemberTaskInstance.TaskMaterial').map((tm: any) => tm.C_Material_key);
            const uniqueAnimals = animals.filter((animal: any) => !currentMaterialKeys.includes(animal.C_Material_key));
            taskMap[task.C_TaskInstance_key].animals = uniqueAnimals;

            const materials = uniqueAnimals.map(a => a.Material) as Entity<Material>[];
            taskMap[task.C_TaskInstance_key].materials = materials;

            if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
                const sampleGroupsWithSamples = await this.jobPharmaDetailService.getSampleGroupsWithSamples([task]);
                taskMap[task.C_TaskInstance_key].sampleGroupsWithSamples = sampleGroupsWithSamples as Entity<SampleGroup>[];
    
                const nonSampleSourceMaterials = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroupsWithSamples, materials);
                taskMap[task.C_TaskInstance_key].nonSampleSourceMaterials = nonSampleSourceMaterials;
            }
        }
        
        return taskMap;
    }

    private async prepareBulkAssignCohortsToTasks(tasks: Entity<TaskInstance>[], cohorts: Entity<Cohort>[]) {
        const taskMap: TaskCohortMap = {};
        for (const task of tasks) {
            taskMap[task.C_TaskInstance_key] = { task };

            
            const currentCohortKeys = new Set(task.MemberTaskInstance.flatMap(mti => mti.TaskCohort).map(tc => tc.C_Cohort_key));
            const uniqueCohorts = cohorts.filter((cohort: any) => !currentCohortKeys.has(cohort.C_Cohort_key));
            taskMap[task.C_TaskInstance_key].cohorts = uniqueCohorts;

            const materials = uniqueCohorts.flatMap(cohort => cohort.CohortMaterial.flatMap(cm => cm.Material)) as Entity<Material>[];
            taskMap[task.C_TaskInstance_key].materials = materials;

            if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
                const sampleGroupsWithSamples = await this.jobPharmaDetailService.getSampleGroupsWithSamples([task]);
                taskMap[task.C_TaskInstance_key].sampleGroupsWithSamples = sampleGroupsWithSamples as Entity<SampleGroup>[];

                const nonSampleSourceMaterials = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroupsWithSamples, materials);
                taskMap[task.C_TaskInstance_key].nonSampleSourceMaterials = nonSampleSourceMaterials;
            }
        }
        
        return taskMap;
    }

    private async showBulkAssignCreateSamplesModal(data: BulkAssignResult, taskMaterialMap: TaskMaterialMap, taskCohortMap: TaskCohortMap) {
        let totalNumberOfSamplesToCreate = 0;
        for (const task of data.selectedTasks) {
            if (taskMaterialMap[task.C_TaskInstance_key]) {
                const { sampleGroupsWithSamples, nonSampleSourceMaterials } = taskMaterialMap[task.C_TaskInstance_key];
                totalNumberOfSamplesToCreate += this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroupsWithSamples, nonSampleSourceMaterials.length);
            }

            if (taskCohortMap[task.C_TaskInstance_key]) {
                const { sampleGroupsWithSamples, nonSampleSourceMaterials } = taskCohortMap[task.C_TaskInstance_key];
                totalNumberOfSamplesToCreate += this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroupsWithSamples, nonSampleSourceMaterials.length);
            }
        }

        if (totalNumberOfSamplesToCreate > 0) {
            const confirm = await this.jobPharmaDetailService.showCreateSamplesModal(totalNumberOfSamplesToCreate);
            return confirm;
        }

        return true;
    }

    private async bulkAssignAnimalsToTasks(job: Entity<Job>, batchMap: TaskMaterialMap) {
        const taskKeys = Object.keys(batchMap);
        if (isEmpty(taskKeys)) {
            return;
        }

        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            for (const taskKey of taskKeys) {
                const { task, materials, sampleGroupsWithSamples, nonSampleSourceMaterials } = batchMap[taskKey];
                const success = await this.jobPharmaDetailService.addAnimalsToTask(job, task, materials);
                if (success) {
                    await this.jobPharmaDetailService.handleSampleCreates(sampleGroupsWithSamples, nonSampleSourceMaterials);
                }
            }

            return;
        }

        const requests: Promise<boolean>[] = [];
        for (const taskKey of taskKeys) {
            const { task, materials } = batchMap[taskKey];
            requests.push(this.jobPharmaDetailService.addAnimalsToTask(job, task, materials));
        }
        await Promise.all(requests);
    }

    private async bulkAssignCohortsToTasks(job: Entity<Job>, batchMap: TaskCohortMap) {
        const taskKeys = Object.keys(batchMap);
        if (isEmpty(taskKeys)) {
            return;
        }

        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            for (const taskKey of taskKeys) {
                const { task, cohorts, sampleGroupsWithSamples, nonSampleSourceMaterials } = batchMap[taskKey];
                const success = await this.jobPharmaDetailService.assignCohortToTaskImmediately(job, task, cohorts);
                if (success) {
                    await this.jobPharmaDetailService.handleSampleCreates(sampleGroupsWithSamples, nonSampleSourceMaterials);
                }

                this.jobPharmaDetailService.notifyJobArrayChanged('JobMaterial');
                this.jobPharmaDetailService.notifyJobArrayChanged('TaskJob');
            }
            return;
        }

        const requests: Promise<boolean>[] = [];
        for (const taskKey of taskKeys) {
            const { task, cohorts } = batchMap[taskKey];
            requests.push(this.jobPharmaDetailService.addCohortsToTask(job, task, cohorts));
        }
        await Promise.all(requests);
    }

    private async bulkAssignCohortsToProtocol(job: Entity<Job>, protocolInstance: Entity<ProtocolInstance>, batchMap: TaskCohortMap) {
        const taskKeys = Object.keys(batchMap);
        if (isEmpty(taskKeys)) {
            return;
        }

        const uniqueCohorts = new Set(Object.values(batchMap).flatMap(v => v.cohorts)) as Set<Entity<Cohort & CohortExtended>>;
        const success = await this.jobPharmaDetailService.addCohortsToProtocol(job, protocolInstance, [...uniqueCohorts]);
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag() && success) {
            for (const taskKey of taskKeys) {
                const { sampleGroupsWithSamples, nonSampleSourceMaterials } = batchMap[taskKey];
                await this.jobPharmaDetailService.handleSampleCreates(sampleGroupsWithSamples, nonSampleSourceMaterials);
            }
        }

        this.jobPharmaDetailService.notifyJobArrayChanged('JobMaterial');
        this.jobPharmaDetailService.notifyJobArrayChanged('TaskJob');
    }

    private async bulkAssign(job: Entity<Job>, data: BulkAssignResult, taskMaterialMap: TaskMaterialMap, taskCohortMap: TaskCohortMap) {
        const overlayId = this.overlayService.show("");
        try {
             // Find if it has free tasks or protocol tasks
            const freeTasks = data.selectedTasks.filter((t: any) => t.ProtocolTask === null);

            // Handle individual animals
            if (Object.keys(taskMaterialMap).length > 0) {
                // 5 entities are created each time animal is assigned to a task
                const entityCount = data.selectedAnimals.length * data.selectedTasks.length * 5;
                if (freeTasks.length > 0 || entityCount <= this.AMOUNT_ENTITIES_LIMIT) {
                    // Check if we have animals already assigned to the task, then assign them
                    await this.bulkAssignAnimalsToTasks(job, taskMaterialMap);
                } else {
                    await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
                    await this.bulkAssignAnimalsToTasks(job, taskMaterialMap);
                }
            }

            // Handle cohorts
            if (Object.keys(taskCohortMap).length > 0) {
                const taskInstances = uniqueArrayFromPropertyPath(data.selectedTasks[0], 'ProtocolInstance.TaskInstance');
                const toProtocol = data.selectedTasks.length === taskInstances.length;
                // Cohorts are assigned to individual tasks
                if (freeTasks.length > 0 || !toProtocol) {
                    await this.bulkAssignCohortsToTasks(job, taskCohortMap);
                } else {
                    // Cohorts are assigned to whole protocol
                    await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
                    const protocolInstances = uniqueArrayFromPropertyPath(data.selectedTasks[0], 'ProtocolInstance');
                    await this.bulkAssignCohortsToProtocol(job, protocolInstances[0], taskCohortMap);
                }
            }
        } finally {
            this.overlayService.hide(overlayId);
        }
    }

    removeProtocolInstance(protocolInstance: ProtocolInstance): void {
        if (!this.canRemoveProtocolInstance(protocolInstance)) {
            return;
        }
        
        this.protocolService.removeProtocolInstance(protocolInstance);
        this.loggingService.logSuccess('Protocol Deleted', null, this.COMPONENT_LOG_TAG, true);
        this.jobPharmaDetailService.tabRefresh("tasks", "outline");
    }

    async removeProtocolInstances(protocolInstances: ProtocolInstance[]): Promise<void> {
        const protocolInstancesToRemove = this.findProtocolInstancesEligibleToBeRemoved(protocolInstances);
        if (protocolInstancesToRemove.length === 0) {
            this.loggingService.logWarning(
                "All selected protocols have associated workflow data and/or sample groups and cannot be removed.", 
                null, 
                this.COMPONENT_LOG_TAG, 
                true
            );
            return;
        }

        const confirmOptions = this.createRemoveProtocolInstancesConfirmOptions(protocolInstancesToRemove.length, protocolInstances.length);
        try {
            await this.confirmService.confirm(confirmOptions);
        } catch {
            return;
        }

        this.protocolService.removeProtocolInstances(protocolInstancesToRemove);
        this.loggingService.logSuccess('Eligible Protocols Deleted', null, this.COMPONENT_LOG_TAG, true);
        this.jobPharmaDetailService.tabRefresh("tasks", "outline");
    }

    canRemoveProtocolInstance(protocolInstance: ProtocolInstance): boolean {
        return protocolInstance.TaskInstance.every(ti => {
            return !this.isLocked(ti) &&
                !this.isEndState(ti) &&
                !this.hasDateComplete(ti) &&
                !this.hasSampleGroups(ti) &&
                !this.hasTaskOutputSets(ti) &&
                !this.hasEndStateMemberTasks(ti)
        });
    }

    private isLocked(taskInstance: TaskInstance): boolean {
        return taskInstance.IsLocked;
    }
    
    private isEndState(taskInstance: TaskInstance): boolean {
        return taskInstance.cv_TaskStatus?.IsEndState;
    }
    
    private hasDateComplete(taskInstance: TaskInstance): boolean {
        return Boolean(taskInstance.DateComplete);
    }

    private hasSampleGroups(taskInstance: TaskInstance): boolean {
        return taskInstance.SampleGroup?.length > 0;
    }

    private hasTaskOutputSets(taskInstance: TaskInstance): boolean {
        return taskInstance.TaskOutputSet?.length > 0;
    }

    private hasEndStateMemberTasks(taskInstance: TaskInstance): boolean {
        const memberTaskInstances = taskInstance.MemberTaskInstance;
        if (memberTaskInstances?.length === 0) {
            return false;
        }

        return memberTaskInstances.some(ti => {
            return this.isEndState(ti);
        });
    }

    private findProtocolInstancesEligibleToBeRemoved(protocolInstances: ProtocolInstance[]): ProtocolInstance[] {
        const protocolInstancesToRemove: ProtocolInstance[] = [];
        for (const protocol of protocolInstances) {
            if (this.canRemoveProtocolInstance(protocol)) {
                protocolInstancesToRemove.push(protocol);
            }
        }

        return protocolInstancesToRemove;
    }

    private createRemoveProtocolInstancesConfirmOptions(
        protocolsToRemoveLength: number, 
        selectedProtocolsLength: number
    ): ConfirmOptions {
        let message = `${protocolsToRemoveLength} ${pluralize(protocolsToRemoveLength, 'protocol')} out of ${selectedProtocolsLength} ${pluralize(selectedProtocolsLength, 'protocol')} can be removed.`;
        if (protocolsToRemoveLength !== selectedProtocolsLength) {
            message += " The remaining protocols have associated workflow data and/or sample groups and cannot be removed.";
        }
        message += " Do you wish to continue?";
        const options: ConfirmOptions = {
            title: 'Remove Protocols',
            message: message,
            yesButtonText: "Remove",
            isDanger: true,
        };

        return options;
    }
}
