<table class="table table-bordered job-pharma-samples-groups">
    <thead>
        <tr class="detail-grid-header">
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>

            <!-- Remove -->
            <th *ngIf="!readonly">
                <ng-container *ngIf="canRemoveAnySampleGroup()">
                    <a (click)="removeSampleGroups()"
                       class="remove-sample-groups">
                        <i class="fa fa-remove remove-item" title="Delete all eligible sample groups."></i>
                    </a>
                </ng-container>
            </th>

            <!-- Expand -->
            <th>
                <a (click)="bulkExpandClick()" class="source-toggle-link">
                    <i *ngIf="bulkExpanded" class="fa fa-chevron-up" title="Hide all sources"></i>
                    <i *ngIf="!bulkExpanded" class="fa fa-chevron-down" title="Show all sources"></i>
                </a>
            </th>

            <th *ngIf="visible.protocol">Protocol</th>
            <th *ngIf="visible.taskAlias">Task Name</th>
            <th *ngIf="visible.numSources">Sources</th>
            <th *ngIf="visible.numSamples">
                <bulk-edit-header 
                    [headerText]="'Samples per Source'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkNumSamples()">
                    <div modal-content>
                        <input [(ngModel)]="bulkNumSamples"
                               type="number"
                               min="1"
                               step="1"
                               class="form-control input-small"
                               name="sampleGroup-bulk-numSamples" />
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.numTotal">Samples</th>
            <th *ngIf="visible.type">
                <bulk-edit-header 
                    [headerText]="'Type'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkSampleTypeKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkSampleTypeKey"
                                             [vocabChoices]="sampleTypes"
                                             [keyFormatter]="sampleTypeKeyFormatter"
                                             [optionFormatter]="sampleTypeFormatter"
                                             [nullable]="false">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.status">
                <bulk-edit-header 
                    [headerText]="'Status'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkSampleStatusKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkSampleStatusKey"
                                             [vocabChoices]="sampleStatuses"
                                             [keyFormatter]="sampleStatusKeyFormatter"
                                             [optionFormatter]="sampleStatusFormatter"
                                             [nullable]="false">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>

            <th *ngIf="visible.preservation && activeFields.includes('C_PreservationMethod_key')">
                <bulk-edit-header 
                    [headerText]="'Preservation'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkPreservationMethodKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkPreservationMethodKey"
                                             [vocabChoices]="preservationMethods"
                                             [keyFormatter]="preservationMethodKeyFormatter"
                                             [optionFormatter]="preservationMethodFormatter"
                                             [required]="requiredFields.includes('C_PreservationMethod_key')"
                                             [nullable]="!requiredFields.includes('C_PreservationMethod_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>

            <th *ngIf="visible.containerType && activeFields.includes('Material.C_ContainerType_key')">
                <bulk-edit-header 
                    [headerText]="'Container'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkContainerTypeKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkContainerTypeKey"
                                             [vocabChoices]="containerTypes"
                                             [keyFormatter]="containerTypeKeyFormatter"
                                             [optionFormatter]="containerTypeFormatter"
                                             [required]="requiredFields.includes('Material.C_ContainerType_key')"
                                             [nullable]="!requiredFields.includes('Material.C_ContainerType_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>

            <th *ngIf="visible.harvestDate && activeFields.includes('DateHarvest')">
                <bulk-edit-header 
                    [headerText]="'Harvest Date'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkDateHarvest()">
                    <div modal-content>
                        <climb-ngb-date #harvestDateControl="ngModel"
                                        name="Harvest Date"
                                        [(ngModel)]="bulkDateHarvest"
                                        [required]="requiredFields.includes('DateHarvest')">
                        </climb-ngb-date>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.expirationDate && activeFields.includes('DateExpiration')">
                <bulk-edit-header 
                    [headerText]="'Expiration Date'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkDateExpiration()">
                    <div modal-content>
                        <climb-ngb-date #expirationDateControl="ngModel"
                                        name="Expiration Date"
                                        [(ngModel)]="bulkDateExpiration"
                                        [required]="requiredFields.includes('DateExpiration')">
                        </climb-ngb-date>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.timePoint && activeFields.includes('TimePoint')">
                <bulk-edit-header 
                    [headerText]="'Time Point'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkTimePoint()">
                    <div modal-content class="d-flex flex-row">
                        <input type="number"
                               min="0"
                               step="any"
                               name="sampleGroup-bulk-timePoint"
                               [(ngModel)]="bulkTimePoint"
                               decimalFormatter
                               [decimalPlaces]="3"
                               class="form-control input-small" 
                               [required]="requiredFields.includes('TimePoint')"/>
                        <active-vocab-select [(model)]="bulkTimeUnitKey"
                                             [vocabChoices]="timeUnits"
                                             [keyFormatter]="timeUnitKeyFormatter"
                                             [optionFormatter]="timeUnitFormatter"
                                             [required]="requiredFields.includes('TimePoint')"
                                             [nullable]="!requiredFields.includes('TimePoint')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.subtype && activeFields.includes('C_SampleSubtype_key')">
                <bulk-edit-header 
                    [headerText]="'Subtype'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkSampleSubtypeKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkSampleSubtypeKey"
                                             [vocabChoices]="sampleSubtypes"
                                             [keyFormatter]="sampleSubtypeKeyFormatter"
                                             [optionFormatter]="sampleSubtypeFormatter"
                                             [required]="requiredFields.includes('C_SampleSubtype_key')"
                                             [nullable]="!requiredFields.includes('C_SampleSubtype_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.processing && activeFields.includes('C_SampleProcessingMethod_key')">
                <bulk-edit-header 
                    [headerText]="'Processing'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkSampleProcessingMethodKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkSampleProcessingMethodKey"
                                             [vocabChoices]="sampleProcessingMethods"
                                             [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                             [optionFormatter]="sampleProcessingMethodFormatter"
                                             [required]="requiredFields.includes('C_SampleProcessingMethod_key')"
                                             [nullable]="!requiredFields.includes('C_SampleProcessingMethod_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.sendTo && activeFields.includes('SendTo')">
                <bulk-edit-header 
                    [headerText]="'Send To'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkSendTo()">
                    <div modal-content>
                        <input [(ngModel)]="bulkSendTo"
                               type="text"
                               maxlength="150"
                               class="form-control input-small"
                               name="sampleGroup-bulk-sendTo" 
                               [required]="requiredFields.includes('SendTo')"/>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.analysis && activeFields.includes('C_SampleAnalysisMethod_key')">
                <bulk-edit-header 
                    [headerText]="'Analysis'"
                    [readonly]="readonly"
                    (updateClicked)="updateBulkSampleAnalysisMethodKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="bulkSampleAnalysisMethodKey"
                                             [vocabChoices]="sampleAnalysisMethods"
                                             [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                             [optionFormatter]="sampleAnalysisMethodFormatter"
                                             [required]="requiredFields.includes('C_SampleAnalysisMethod_key')"
                                             [nullable]="!requiredFields.includes('C_SampleAnalysisMethod_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.specialInstructions && activeFields.includes('SpecialInstructions')">
                <bulk-edit-header 
                    [headerText]="'Special Instructions'"
                    (updateClicked)="updateBulkSpecialInstructions()">
                    <div modal-content>
                        <textarea 
                            [(ngModel)]="bulkSpecialInstructions"
                            [required]="requiredFields.includes('SpecialInstructions')"
                            name="sampleGroup-bulk-specialInstructions"
                            maxlength="1000"
                            class="form-control input-medium">
                        </textarea>
                    </div>
                </bulk-edit-header>
            </th>
        </tr>
    </thead>
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <tbody *ngIf="!loading">
        <tr *ngFor="let row of rows; let index = index"
            [ngClass]="{'selected-row': !isSampleGroupLocked(row.sampleGroup) && row.isSelected}"
            class="sample-group-row">
            <!-- Select -->
            <td *ngIf="!readonly"
                class="icon-cell">
                <input *ngIf="!doesSampleGroupHaveAssociatedSamples(row.sampleGroup)"
                       type="checkbox"
                       name="select-samples-group-{{index}}"
                       [(ngModel)]="row.isSelected"
                       (ngModelChange)="isSelectedChanged()" 
                       />
            </td>

            <!-- Remove -->
            <td class="icon-cell"
                *ngIf="!readonly">
                <a *ngIf="!isSampleGroupLocked(row.sampleGroup)"
                    (click)="removeSampleGroup(row)" 
                    class="remove-sample-group">
                    <i class="fa fa-remove remove-item" title="Remove Sample Group"></i>
                </a>
            </td>

            <!-- Expand -->
            <td class="icon-cell">
                <a (click)="rowExpandClick(row)"
                   class="source-toggle-link"
                   *ngIf="(row.sampleGroup.SampleGroupSourceMaterial.length > 0)">
                    <i *ngIf="row.expanded" class="fa fa-chevron-up" title="Hide sources"></i>
                    <i *ngIf="!row.expanded" class="fa fa-chevron-down" title="Show sources"></i>
                </a>
            </td>

            <!-- Protocol -->
            <td *ngIf="visible.protocol"
                [ngClass]="row.classes.task">
                <ng-container *ngIf="row.taskFirst" class="sample-group-protocol-name">
                    {{row.task?.ProtocolInstance?.ProtocolAlias}}
                </ng-container>
            </td>
            <!-- Task -->
            <td *ngIf="visible.taskAlias"
                [ngClass]="row.classes.task">
                <ng-container *ngIf="row.taskFirst" class="sample-group-task-name">
                    {{row.task?.TaskAlias}}
                </ng-container>
            </td>
            <!-- NumSources -->
            <td *ngIf="visible.numSources">
                <div *ngIf="row.numSources && row.numSourceAnimals">
                        <ng-container *ngIf="row.expanded">
                            <ng-container *ngFor="let material of row.sampleGroup.SampleGroupSourceMaterial">
                                <div class="label-with-remove" *ngIf= "material.Material && material.Material.Animal">
                                    <a *ngIf="canRemoveSampleGroupSourceMaterial(row.sampleGroup) && !readonly"
                                        (click)="removeSampleGroupSourceMaterial(row.sampleGroup, material)" 
                                        class="remove-animal-sample-group">
                                        <i class="fa fa-remove remove-animal-sample-group" title="Remove Sample Group Source"></i>
                                    </a>
                                    {{material.Material.Animal?.AnimalName}}
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="!row.expanded && row.numSourceAnimals">
                            {{row.numSourceAnimals}}
                            <img src="/images/animals.png" width="12" height="12" alt="Animal" title="Animal" />
                        </ng-container>
                </div>
                <div *ngIf="row.numSources && row.numSourceSamples">
                    <ng-container *ngIf="row.expanded">
                        <div *ngFor="let sample of row.sampleGroup.SampleGroupSourceMaterial">
                            <div  class="label-with-remove"  *ngIf = "sample.Material && sample.Material.Sample">
                                <a *ngIf="canRemoveSampleGroupSourceMaterial(row.sampleGroup) && !readonly"
                                    (click)="removeSampleGroupSourceMaterial(row.sampleGroup, sample)" 
                                    class="remove-animal-sample-group">
                                    <i class="fa fa-remove remove-animal-sample-group" title="Remove Sample Group Source"></i>
                                </a>
                                {{sample.Material.Sample?.SampleName}}
                            </div>

                        </div>
                    </ng-container>
                    <ng-container *ngIf="!row.expanded && row.numSourceSamples">
                    {{row.numSourceSamples}}
                    <img src="/images/samples.png" width="12" height="12" alt="Sample" title="Sample" />
                    </ng-container>
                </div>
                <div *ngIf="row.numSources && row.numSourceAnimalPlaceholders">
                    <ng-container *ngIf="row.expanded">
                        <div *ngFor="let placeholder of row.sampleGroup.SampleGroupSourceMaterial">
                            <div class="label-with-remove" *ngIf = "placeholder.AnimalPlaceholder && !placeholder.AnimalPlaceholder.Material">
                                <a *ngIf="canRemoveSampleGroupSourceMaterial(row.sampleGroup) && !readonly"
                                    (click)="removeSampleGroupSourceMaterial(row.sampleGroup, placeholder)" 
                                    class="remove-animal-sample-group" >
                                    <i class="fa fa-remove remove-animal-sample-group" title="Remove Sample Group Source"></i>
                                </a>
                                {{placeholder.AnimalPlaceholder.Name}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!row.expanded">
                        {{row.numSourceAnimalPlaceholders}}
                        <img src="/images/AnimalPlaceholder.png" width="12" height="12" alt="Placeholder" title="Placeholder" />
                    </ng-container>
                </div>
                <span *ngIf="!(row.numSourceAnimals) && !(row.numSourceSamples) && !(row.numSourceAnimalPlaceholders)">
                    {{row.numSources}}
                </span>
            </td>
            <!-- NumSamples -->
            <td *ngIf="visible.numSamples">
                <input 
                    [(ngModel)]="row.sampleGroup.NumSamples"
                    (ngModelChange)="recordSampleGroupChange($event, row, 'NumSamples')"
                    [disabled]="isSampleGroupLocked(row.sampleGroup)"
                    type="number"
                    min="0"
                    step="1"
                    class="form-control input-small"
                    name="sampleGroup-{{row.sampleGroup.C_SampleGroup_key}}-numSamples" />
            </td>
            <!-- NumTotal -->
            <td *ngIf="visible.numTotal"
                class="num-cell col-size-fit">
                <ng-container>
                    {{ (row.numSourceAnimals + row.numSourceSamples + row.numSourceAnimalPlaceholders) * row.sampleGroup.NumSamples }}
                </ng-container>
            </td>

            <!-- Type -->
            <td *ngIf="visible.type">
                <active-vocab-select 
                    [(model)]="row.sampleGroup.C_SampleType_key"
                    (modelChange)="recordSampleGroupChange($event, row, 'C_SampleType_key')"
                    [vocabChoices]="sampleTypes"
                    [keyFormatter]="sampleTypeKeyFormatter"
                    [optionFormatter]="sampleTypeFormatter"
                    [nullable]="false"
                    [readonly]="isSampleGroupLocked(row.sampleGroup)">
                </active-vocab-select>
            </td>

            <!-- Status -->
            <td *ngIf="visible.status">
                <active-vocab-select 
                    [(model)]="row.sampleGroup.C_SampleStatus_key"
                    (modelChange)="recordSampleGroupChange($event, row, 'C_SampleStatus_key')"
                    [vocabChoices]="sampleStatuses"
                    [keyFormatter]="sampleStatusKeyFormatter"
                    [optionFormatter]="sampleStatusFormatter"
                    [nullable]="false"
                    [readonly]="isSampleGroupLocked(row.sampleGroup)">
                </active-vocab-select>
            </td>

            <!-- Preservation -->
            <td *ngIf="visible.preservation && activeFields.includes('C_PreservationMethod_key')">
                <active-vocab-select 
                    [(model)]="row.sampleGroup.C_PreservationMethod_key"
                    (modelChange)="recordSampleGroupChange($event, row, 'C_PreservationMethod_key')"
                    [vocabChoices]="preservationMethods"
                    [keyFormatter]="preservationMethodKeyFormatter"
                    [optionFormatter]="preservationMethodFormatter"
                    [required]="requiredFields.includes('C_PreservationMethod_key')"
                    [nullable]="!requiredFields.includes('C_PreservationMethod_key')"
                    [readonly]="isSampleGroupLocked(row.sampleGroup)">
                </active-vocab-select>
            </td>

            <!-- Container Type-->
            <td *ngIf="visible.containerType && activeFields.includes('Material.C_ContainerType_key')">
                <active-vocab-select 
                    [(model)]="row.sampleGroup.C_ContainerType_key"
                    (modelChange)="recordSampleGroupChange($event, row, 'C_ContainerType_key')"
                    [vocabChoices]="containerTypes"
                    [keyFormatter]="containerTypeKeyFormatter"
                    [optionFormatter]="containerTypeFormatter"
                    [required]="requiredFields.includes('Material.C_ContainerType_key')"
                    [nullable]="!requiredFields.includes('Material.C_ContainerType_key')"
                    [readonly]="isSampleGroupLocked(row.sampleGroup)"></active-vocab-select>
            </td>

            <!-- Harvest Date -->
            <td *ngIf="visible.harvestDate && activeFields.includes('DateHarvest')">
                <climb-ngb-date #dateControl="ngModel"
                    [(ngModel)]="row.sampleGroup.DateHarvest"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="recordSampleGroupChange($event, row, 'DateHarvest')"
                    [disabled]="isSampleGroupLocked(row.sampleGroup)" 
                    [container]="'body'"
                    [required]="requiredFields.includes('DateHarvest')"></climb-ngb-date>
            </td>

            <!-- Expiration Date -->
            <td *ngIf="visible.expirationDate && activeFields.includes('DateExpiration')">
                <climb-ngb-date #dateControl="ngModel"
                    [(ngModel)]="row.sampleGroup.DateExpiration"
                    (ngModelChange)="recordSampleGroupChange($event, row, 'DateExpiration')"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="isSampleGroupLocked(row.sampleGroup)" 
                    [container]="'body'"
                    [required]="requiredFields.includes('DateExpiration')"></climb-ngb-date>
            </td>

            <!-- Time Point -->
            <td *ngIf="visible.timePoint && activeFields.includes('TimePoint')">
                <div class="d-flex flex-row">
                    <input 
                        type="number"
                        min="0"
                        step="any"
                        name="sampleGroup-{{row.sampleGroup.C_SampleGroup_key}}-timePoint"
                        [(ngModel)]="row.sampleGroup.TimePoint"
                        (ngModelChange)="recordSampleGroupChange($event, row, 'TimePoint')"
                        decimalFormatter
                        [decimalPlaces]="3"
                        class="form-control input-small"
                        [disabled]="isSampleGroupLocked(row.sampleGroup)" 
                        [required]="requiredFields.includes('TimePoint')"/>
                    <active-vocab-select 
                        [(model)]="row.sampleGroup.C_TimeUnit_key"
                        (modelChange)="recordSampleGroupChange($event, row, 'C_TimeUnit_key')"
                        [vocabChoices]="timeUnits"
                        [keyFormatter]="timeUnitKeyFormatter"
                        [optionFormatter]="timeUnitFormatter"
                        [readonly]="isSampleGroupLocked(row.sampleGroup)"
                        [required]="requiredFields.includes('TimePoint')"
                        [nullable]="!requiredFields.includes('TimePoint')"></active-vocab-select>
                </div>
            </td>

            <!-- Subtype -->
            <td *ngIf="visible.subtype && activeFields.includes('C_SampleSubtype_key')">
                <active-vocab-select 
                    [(model)]="row.sampleGroup.C_SampleSubtype_key"
                    (modelChange)="recordSampleGroupChange($event, row, 'C_SampleSubtype_key')"
                    [vocabChoices]="sampleSubtypes"
                    [keyFormatter]="sampleSubtypeKeyFormatter"
                    [optionFormatter]="sampleSubtypeFormatter"
                    [required]="requiredFields.includes('C_SampleSubtype_key')"
                    [nullable]="!requiredFields.includes('C_SampleSubtype_key')"
                    [readonly]="isSampleGroupLocked(row.sampleGroup)"></active-vocab-select>
            </td>

            <!-- Processing -->
            <td *ngIf="visible.processing && activeFields.includes('C_SampleProcessingMethod_key')">
                <active-vocab-select 
                    [(model)]="row.sampleGroup.C_SampleProcessingMethod_key"
                    (modelChange)="recordSampleGroupChange($event, row, 'C_SampleProcessingMethod_key')"
                    [vocabChoices]="sampleProcessingMethods"
                    [keyFormatter]="sampleProcessingMethodKeyFormatter"
                    [optionFormatter]="sampleProcessingMethodFormatter"
                    [required]="requiredFields.includes('C_SampleProcessingMethod_key')"
                    [nullable]="!requiredFields.includes('C_SampleProcessingMethod_key')"
                    [readonly]="isSampleGroupLocked(row.sampleGroup)"></active-vocab-select>
            </td>

            <!-- Send To -->
            <td *ngIf="visible.sendTo && activeFields.includes('SendTo')">
                <input 
                    [(ngModel)]="row.sampleGroup.SendTo"
                    (ngModelChange)="recordSampleGroupChange($event, row, 'SendTo')"
                    type="text"
                    maxlength="150"
                    class="form-control input-small"
                    name="sampleGroup-sendTo"
                    [disabled]="isSampleGroupLocked(row.sampleGroup)"
                    [required]="requiredFields.includes('SendTo')"/>
            </td>

            <!-- Analysis -->
            <td *ngIf="visible.analysis && activeFields.includes('C_SampleAnalysisMethod_key')">
                <active-vocab-select 
                    [(model)]="row.sampleGroup.C_SampleAnalysisMethod_key"
                    (modelChange)="recordSampleGroupChange($event, row, 'C_SampleAnalysisMethod_key')"
                    [vocabChoices]="sampleAnalysisMethods"
                    [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                    [optionFormatter]="sampleAnalysisMethodFormatter"
                    [required]="requiredFields.includes('C_SampleAnalysisMethod_key')"
                    [nullable]="!requiredFields.includes('C_SampleAnalysisMethod_key')"
                    [readonly]="isSampleGroupLocked(row.sampleGroup)"></active-vocab-select>
            </td>

            <!-- Special Instructions -->
            <td *ngIf="visible.specialInstructions && activeFields.includes('SpecialInstructions')">
                <textarea 
                    [(ngModel)]="row.sampleGroup.SpecialInstructions"
                    (ngModelChange)="recordSampleGroupChange($event, row, 'SpecialInstructions')"
                    [required]="requiredFields.includes('SpecialInstructions')"
                    [disabled]="isSampleGroupLocked(row.sampleGroup)"
                    name="sampleGroup-specialInstructions-{{index}}"
                    maxlength="1000"
                    rows="1"
                    class="form-control input-medium">
                </textarea>
            </td>
        </tr>
    </tbody>
</table>
