import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { 
    AnimalClinicalObservation,
    AnimalDiagnosticObservation, 
    ClinicalObservationDetail, 
    cv_ClinicalObservation, 
    cv_ClinicalObservationBodySystem, 
    cv_ClinicalObservationModifier1, 
    cv_ClinicalObservationModifier2, 
    cv_ClinicalObservationModifier3, 
    cv_ClinicalObservationModifier4, 
    cv_ClinicalObservationStatus, 
    cv_Modifier1, 
    cv_Modifier2, 
    cv_Modifier3, 
    cv_Modifier4, 
    Resource 
} from "@common/types";
import { FeatureFlagService, IS_GLP, LARGE_ANIMAL } from "@services/feature-flags.service";
import { ClinicalService } from "../../clinical.service";
import { AuthService } from "@services/auth.service";
import { ResourceService } from "src/app/resources";
import { VocabularyService } from "src/app/vocabularies/vocabulary.service";
import { ClinicalVocabService } from "../../clinical-vocab.service";
import { LoggingService } from "@services/logging.service";
import { uniqueArrayFromPropertyPath } from "@common/util";
import { IFacet } from "@common/facet";
import { forkJoin, Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HealthRecord } from "../clinical-detail.component";
import { ObservationChooserComponent } from "../../observation-chooser/observation-chooser.component";

type AnyObservationExtensions = {
    confirmStatus: boolean,
    historyExpanded: boolean,
    [key:string]: unknown
}
export type AnyObservation = AnimalClinicalObservation & AnimalDiagnosticObservation & AnyObservationExtensions;  
export type AnyModifier = cv_Modifier1 | cv_Modifier2 | cv_Modifier3 | cv_Modifier4;
@Component({
    selector: 'observations-table',
    templateUrl: 'observations-table.component.html'
})
export class ObservationsTableComponent implements OnInit, OnDestroy {
    readonly CLINICAL_TYPE: string = "clinical";
    readonly DIAGNOSTIC_TYPE: string = "diagnostic";
    @Input() observationType: "clinical" | "diagnostic";

    @Input() observations: AnyObservation[];
    
    @Input() healthRecord: HealthRecord;

    @Input() facet: IFacet;

    @ViewChild("clinicalObservationChooser") 
    clinicalObservationChooser:TemplateRef<ObservationChooserComponent>;

    @ViewChild("diagnosticObservationChooser")
    diagnosticObservationChooser: TemplateRef<ObservationChooserComponent>;

    largeAnimalEnabled: boolean;
    isGLP: boolean;

    clinicalObservationStatuses: cv_ClinicalObservationStatus[];
    resources: Resource[];

    clinicalObservations: cv_ClinicalObservation[];
    diagnosticObservations: cv_ClinicalObservation[];

    selectedObservation: AnyObservation;

    // a map of all modifiers types, where the key is the slot and the value is an array of a modifier type. 
    modifiersMap: Record<string, AnyModifier[]> = {
        "1": [],
        "2": [],
        "3": [],
        "4": []
    }

    clinicalVocabSub: Subscription;
    
    constructor(
                private authService: AuthService,
                private clinicalService: ClinicalService,
                private clinicalVocabService: ClinicalVocabService,
                private featureFlagService: FeatureFlagService,
                private loggingService: LoggingService,
                private ngbModal: NgbModal,
                private resourceService: ResourceService,
                private vocabularyService: VocabularyService
    ) {

    }


    async ngOnInit(): Promise<void> {
        this.largeAnimalEnabled = this.featureFlagService.isFlagOn(LARGE_ANIMAL);
        this.isGLP = this.featureFlagService.isFlagOn(IS_GLP); 

        await this._getCVs();
        if (this.largeAnimalEnabled) {
            await this._initModifierMap();
            for (const observation of this.observations) {
                this._updateClinicalModifierOptions(observation as AnyObservation);
            }
        }
    }

    ngOnDestroy(): void {
        this.clinicalVocabSub.unsubscribe();
    }

    /**
     * Gets the following vocabularies in list form:
     * - Clinical Observation Statuses
     * - Resources
     * - If the "LargeAnimal" feature flag is on:
     *    - Clinical Observations
     *    - Modifiers 1-4
     * - If the "LargeAnimal" feature flag is off
     *    - Clinical Observations 
     */
    private async _getCVs(): Promise<void> {
        this.clinicalVocabSub = forkJoin([
            this.clinicalVocabService.clinicalObservationStatuses$,
            this.clinicalVocabService.resources$
        ]).subscribe(([clinicalObservationStatuses, resources]) => {
            this.clinicalObservationStatuses = clinicalObservationStatuses;
            this.resources = resources;
        });

        if (this.largeAnimalEnabled) {
            const allObservations = await this.vocabularyService.getClinicalObservationsWithModifiers();
            this.clinicalObservations = allObservations.filter((e: cv_ClinicalObservation) => e.TrackInWorkflow === false);
            this.diagnosticObservations = allObservations;
        } else {
            this.clinicalObservations = await this.clinicalVocabService.clinicalObservations$.toPromise();
        }

    }

    /**
     * Initializes the map of modifiers, setting the corresponding modifier lists to each selection slot. 
     */
    private async _initModifierMap(): Promise<void> {
        const promises:Promise<AnyModifier[]>[] = []
        for (let i = 1; i <= 4; i++) {
            promises.push(this.vocabularyService.getCV(`cv_Modifiers${i}`));
        }

        try {
            const allModifiers = await Promise.all(promises);

            this.modifiersMap["1"] = allModifiers[0];
            this.modifiersMap["2"] = allModifiers[1];
            this.modifiersMap["3"] = allModifiers[2];
            this.modifiersMap["4"] = allModifiers[3];

        } catch(error: any) {
            this.loggingService.logError(error.message, error, "observations-table", false);
        }
    }

    /**
     * Adds a new AnimalClinicalObservation or AnimalDiagnosticObservation. 
     * Sets the observing resource as the current user.
     * Sets the clinical observation status as the default according to the Vocabularies facet.
     * Updates all modifier options if the "LargeAnimal" feature flag is enabled.
     */
    async addObservation(): Promise<AnyObservation> {
        let newObservation: AnyObservation;

        const initialValues = {
            C_Material_key: this.healthRecord.Animal.C_Material_key,
            JobName: this._getJobNames(),
            ObservedByUsername: this.authService.getCurrentUserName()
        };

        this._splitFunctionality(
            async () => newObservation = await this._createClinicalObservation(initialValues) as AnyObservation,
            () => newObservation = this.clinicalService.createDiagnosticObservation(initialValues)
        );

        const resource = await this.resourceService.getCurrentUserResource();
        if (resource) {
            newObservation.C_Resource_key = resource.C_Resource_key;
        }

        newObservation.cv_ClinicalObservationStatus = await this.vocabularyService.getCVDefault("cv_ClinicalObservationStatuses");
        newObservation.DateObserved = new Date();

        if (this.largeAnimalEnabled) {
            this._updateClinicalModifierOptions(newObservation);
        }

        return newObservation;
    }

    /**
     * 
     * @param observationModal the template reference in the section.
     * @param observation the observation row in the section. 
     */
    openObservationChooser(observation: AnyObservation) {
        this.selectedObservation = observation;
        this._splitFunctionality(
            () => this.ngbModal.open(this.clinicalObservationChooser),
            () => this.ngbModal.open(this.diagnosticObservationChooser)
        );
    }

    /**
     * Selects an observation.
     * @param observation the selected observation
     */
    onObservationClicked(observation:any) {
        this.selectedObservation = observation;
    }

    /**
     * Sets the appropriate related entities according to the "LargeAnimal" feature flag. 
     * @param selected the clinical observation, or clinical observation's link to a body system, that was selected. 
     */
    onSelectObservations(selected: cv_ClinicalObservationBodySystem & cv_ClinicalObservation[]) {
        if (this.largeAnimalEnabled) {
            this._setObservationAndBodySystem(selected);
        }
        else {
            this._setObservationDetails(selected);
        }
    }

    /**
     * Sets the "ReviewDate" of the Observation and the "LastConfirmedDateTime" of the Health Record to be the current time. 
     * @param observation the observation that is confirmed.
     */
    confirmObservation(observation: AnyObservation): void {
        const now = new Date();
        observation.ReviewDate = now
        this.healthRecord.LastConfirmedDateAndTime = now 
        observation.confirmStatus = true;
        this.clinicalService.updateReviewDate(observation);
    }

    /**
     * Removes the observation (Clinical or Diagnostic, depending on the "observationType" input.)
     * @param observation the observation that should be removed.
     */
    removeObservation(observation: AnyObservation) {
        this._splitFunctionality(
            () => this.clinicalService.deleteClinicalObservation(observation),
            () => this.clinicalService.deleteDiagnosticObservation(observation)
        );
    }

    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }

    observationStatusKeyFormatter = (value: any) => {
        return value.C_ClinicalObservationStatus_key;
    }
    observationStatusFormatter = (value: any) => {
        return value.ClinicalObservationStatus;
    }

    
    /**
     * 
     * @param observation adds the observation options to the available 
     * @returns 
     */
    private _updateClinicalModifierOptions(observation: AnyObservation): void {
        if (!observation || !observation.cv_ClinicalObservation) {
            return;
        }
        // get all modifiers from the observation's cv_ClinicalObservation value.
        const modifierVocabOptionMap: Record<string, AnyModifier[]> = {
            "1": observation.cv_ClinicalObservation.cv_ClinicalObservationModifier1.map((e: cv_ClinicalObservationModifier1) => e.cv_Modifier1),
            "2": observation.cv_ClinicalObservation.cv_ClinicalObservationModifier2.map((e: cv_ClinicalObservationModifier2) => e.cv_Modifier2),
            "3": observation.cv_ClinicalObservation.cv_ClinicalObservationModifier3.map((e: cv_ClinicalObservationModifier3) => e.cv_Modifier3),
            "4": observation.cv_ClinicalObservation.cv_ClinicalObservationModifier4.map((e: cv_ClinicalObservationModifier4) => e.cv_Modifier4)
        };

        // get all modifiers that are assigned to the cv_ClinicalObservation entity.
        for (let i = 1; i <= 4; i++) {
            const modifierVocabOptionKey = `modifier${i}VocabOptions`;
            const modifierKey = `C_Modifier${i}_key`;
            const modifierName = `Modifier${i}`
            
            // generate the "All" label for the selector. 
            if (modifierVocabOptionMap[i.toString()].length == this.modifiersMap[i.toString()].length) {

                observation[modifierVocabOptionKey] = [
                    ...modifierVocabOptionMap[i.toString()],
                    ...this.modifiersMap[i.toString()].filter((e: AnyModifier) => !modifierVocabOptionMap[i.toString()].includes(e))
               ];
            } else {
                const allLabel: any = { isLabel: true };
                allLabel[modifierKey] = 0;
                allLabel[modifierName] = 'All';
                // this is not ideal, but it is more clear than extending each object with the "modifierXVocabOptions"
            
                observation[modifierVocabOptionKey] = [
                     ...modifierVocabOptionMap[i.toString()],
                     allLabel,
                     ...this.modifiersMap[i.toString()].filter((e: AnyModifier) => !modifierVocabOptionMap[i.toString()].includes(e))
                ];
            }
        }
    }

    /**
     * A utility function to split functionality between diagnostic and clinical types. 
     * Only applicable to void type callbacks. 
     * @param clinicalCallback a callback function if the component type is "clinical"
     * @param diagnosticCallback a callback function if the component type is "diagnostic"
     */
    private _splitFunctionality(clinicalCallback:() => void, diagnosticCallback:() => void): void {
        if (this.observationType === this.CLINICAL_TYPE) {
            clinicalCallback.apply(this);
        } else {
            diagnosticCallback.apply(this);
        }
    }

    /**
     * Creates a Clinical Observation, which includes creating a new ClinicalObservationDetail if the workgroup is not GLP. 
     * @param initialValues the initial values to be turned into a Clinical Observation
     * @returns a new AnimalClinicalObservation entity. 
     */
    private async _createClinicalObservation(initialValues: Record<string, string | number>): Promise<AnimalClinicalObservation> {
        const newObservation = this.clinicalService.createClinicalObservation(initialValues);
        if (!this.isGLP) {
            const defaultClinicalObservation = await this.vocabularyService.getCVDefault("cv_ClinicalObservations");
            
            this._createNewObservationDetails(newObservation, [defaultClinicalObservation]);
        }
        return newObservation;
    }

    /**
     * Create new observations for those in selected that are not in current observation
     * Adds many cv_ClinicalObservations added to a single AnimalClinicalObservation object.
     * @param observation - current AnimalClinicalObservation
     * @param observationCVs - selected cv_ClinicalObservationStatuses
     */
    private _createNewObservationDetails(observation: any, observationCVs: cv_ClinicalObservation[]) {
        // find observations in selection missing from current
        const currentObservationCVs = uniqueArrayFromPropertyPath(
            observation, 'ClinicalObservationDetail.cv_ClinicalObservation'
        );
        const missingObservationCVs = observationCVs.filter((selected) => {
            return currentObservationCVs.indexOf(selected) < 0;
        });

        // create observations missing from current
        for (const observationCV of missingObservationCVs) {
            this.clinicalService.createObservationDetail(
                {
                    C_AnimalClinicalObservation_key: observation ? observation.C_AnimalClinicalObservation_key : null,
                    C_ClinicalObservation_key: observationCV.C_ClinicalObservation_key,
                }
            );
        }
    }

    /**
     * Gets the name of the job(s) that the health record's animal is a part of.
     * @returns the names of the currently enrolled job if GLP, its jobs in descending order of creation if not. 
     */
    private _getJobNames(): string {
        let jobMaterials = this.healthRecord.Animal.Material.JobMaterial;
        let jobNames = '';
        if (jobMaterials) {
            if (this.isGLP) {
                jobMaterials = jobMaterials.filter((jm: any) => !jm.DateOut);
            } else {
                jobMaterials = jobMaterials.sort((a: any, b: any) => b.DateCreated - a.DateCreated);
            }
            jobNames = jobMaterials.map((jm: any) => jm.Job.JobID).join(",");
        }
        return jobNames;
    }

    /**
     * If there is a clinical observation's body system selected, it will set that. Otherwise, it will reset clinical observation, body system, and modifier information.
     * Either way, potential modifier options are updated. 
     * @param selected the clinical observation's link to a body system
     */
    private _setObservationAndBodySystem(selected: cv_ClinicalObservationBodySystem): void {
        if (selected) {
            this.selectedObservation.C_ClinicalObservation_key = selected.C_ClinicalObservation_key;
            this.selectedObservation.C_BodySystem_key = selected.C_BodySystem_key;
        } else {
            // clear all modifier/body system information
            this.selectedObservation.C_ClinicalObservation_key = null;
            this.selectedObservation.C_BodySystem_key = null;
            this.selectedObservation.cv_Modifier1 = null;
            this.selectedObservation.cv_Modifier2 = null;
            this.selectedObservation.cv_Modifier3 = null;
            this.selectedObservation.cv_Modifier4 = null;
        }
        this._updateClinicalModifierOptions(this.selectedObservation);
    }

    /**
     * Sets the AnimalClinicalObservation's list of attached Clinical Observations, adding and deleting them as needed.  
     * @param selected the list of clinical observation vocabularies
     */
    private _setObservationDetails(selected: cv_ClinicalObservation[]): void {
        this._createNewObservationDetails(this.selectedObservation, selected);

        const currentDetails = uniqueArrayFromPropertyPath(this.selectedObservation, "ClinicalObservationDetail");

        const missingObservationDetails = currentDetails.filter((currentDetail: ClinicalObservationDetail) => {
            return selected.find((e: cv_ClinicalObservation) => e.C_ClinicalObservation_key === currentDetail.C_ClinicalObservation_key) === undefined;
        });

        for (const detail of missingObservationDetails) {
            this.clinicalService.deleteObservationDetail(detail);
        }
    }
}