import { Injectable } from "@angular/core";
import { JobMaterial } from "@common/types";
import { DataManagerService } from "@services/data-manager.service";
import { EntityQuery, Predicate } from "breeze-client";
@Injectable()
export class JobPharmaDataAccessService {
    constructor(
        private dataManagerService: DataManagerService,

    ) { }
    
    // TODO: Replace Breeze query with GraphQL query
    async getSampleJobMaterials(jobKey: number, pageNumber: number): Promise<JobMaterial[]> {
        const predicates = [
            Predicate.create("Material.cv_MaterialType.MaterialType", "==", "'Sample'"),
            Predicate.create("C_Job_key", "==", jobKey)
        ];
        const expands = [
            "Material.Sample",
            "Material.cv_MaterialType",
            "Material.MaterialSourceMaterial.SourceMaterial.Animal",
            "Material.MaterialSourceMaterial.SourceMaterial.Sample"
        ];
        const query = EntityQuery.from("JobMaterials")
            .expand(expands.join(", "))
            .where(Predicate.and(predicates))
            .skip(50 * (pageNumber - 1))
            .take(50);

        const result = await this.dataManagerService.executeQuery(query);

        return result.results as unknown as JobMaterial[];
    }

    // TODO: Replace Breeze query with GraphQL query
    async getSampleCount(jobKey: number): Promise<number>{
        const predicates = [
            Predicate.create("Material.cv_MaterialType.MaterialType", "==", "'Sample'"),
            Predicate.create("C_Job_key", "==", jobKey)
        ];
        const expands = [
            "Material.Sample",
            "Material.cv_MaterialType",
        ];
        const query = EntityQuery.from("JobMaterials")
            .expand(expands.join(", "))
            .where(Predicate.and(predicates));

        return this.dataManagerService.returnQueryCount(query);
    }
}