import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { ReportingService } from './reporting.service';

@Component({
    selector: 'label-chooser',
    templateUrl: './label-chooser.component.html',
    styles: [`
        /* even out vertical spacing of alert box */
        .alert {
            margin-top: 11px;
            margin-bottom: 11px;
        }
    `]
})
export class LabelChooserComponent implements OnInit {
    @Input() samples: any[];
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    reportType: string;
    reportTypes: SelectItem[];

    noSamplesSelected: boolean;

    constructor(private reportingService: ReportingService) {}

    ngOnInit() {

        if (!this.samples) {
            this.samples = [];
        }

        this.noSamplesSelected = this.samples.length === 0;

        this.reportType = 'Sample Label';
        this.reportTypes = [
            { key: 'SampleLabel', display: 'Sample Label' }
        ];
    }

    cancelClicked() {
        this.close.emit();
    }

    printClicked() {
        let printPromise = Promise.resolve();

        switch (this.reportType) {
            case 'SampleLabel':
                printPromise = this.printSampleLabel();
                break;

            default: break;
        }

        printPromise.then(() => {
            this.close.emit();
        });
    }

    printSampleLabel(): Promise<void> {
        const sampleKeys = this.samples.map((sample) => {
            return sample.C_Material_key;
        });
        this.reportingService.requestSampleLabel(sampleKeys);

        return Promise.resolve();
    }

}

class SelectItem {
    key: string;
    display: string;
}
