import { Component, Input, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnimalVocabService } from '../animals/services/animal-vocab.service';
import { BirthVocabService } from '../birth/birth-vocab.service';
import { ClinicalVocabService } from '../clinical/clinical-vocab.service';
import { sortObjectArrayByAccessor, uniqueArrayOnProperty } from '../common/util';
import { HousingVocabService } from '../housing/services/housing-vocab.service';
import { JobVocabService } from '../jobs/job-vocab.service';
import { JobService } from '../jobs/job.service';
import { MatingVocabService } from '../matings/mating-vocab.service';
import { OrderVocabService } from '../orders/order-vocab.service';
import { ResourceVocabService } from '../resources';
import { FeatureFlagService } from '../services/feature-flags.service';
import { SampleVocabService } from '../samples/sample-vocab.service';
import { LoggingService } from '../services/logging.service';
import { TranslationService } from '../services/translation.service';
import { WebApiService } from '../services/web-api.service';
import { StudyVocabService } from '../studies/study-vocab.service';
import { StudyService } from '../studies/study.service';
import { TaskVocabService } from '../tasks/task-vocab.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { WorkflowVocabService } from '../workflow/services/workflow-vocab.service';
import { AuditFilterVisibility } from './audit-filter-visibility';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { cv_JobCharacteristicLinkType, cv_JobStatus, cv_JobSubtype } from "@common/types";

@Component({
    selector: 'audit-filter-templates',
    templateUrl: './audit-filter-templates.component.html'
})

export class AuditFilterTemplatesComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @ViewChild('animalsTmpl') animalsTmpl: TemplateRef<any>;
    @ViewChild('animalIdentifiersTmpl') animalIdentifiersTmpl: TemplateRef<any>;
    @ViewChild('animalCommentsTmpl') animalCommentsTmpl: TemplateRef<any>;
    @ViewChild('animalCommentStatusesTmpl') animalCommentStatusesTmpl: TemplateRef<any>;
    @ViewChild('animalCommentAnimalNamesTmpl') animalCommentAnimalNamesTmpl: TemplateRef<any>;
    @ViewChild('animalCommentAnimalIDsTmpl') animalCommentAnimalIDsTmpl: TemplateRef<any>;
    @ViewChild('microchipIdentifiersTmpl') microchipIdentifiersTmpl: TemplateRef<any>;
    @ViewChild('samplesTmpl') samplesTmpl: TemplateRef<any>;
    @ViewChild('sampleIdentifiersTmpl') sampleIdentifiersTmpl: TemplateRef<any>;
    @ViewChild('sourceMaterialsTmpl') sourceMaterialsTmpl: TemplateRef<any>;
    @ViewChild('sourceIdentifiersTmpl') sourceIdentifiersTmpl: TemplateRef<any>;
    @ViewChild('jobsTmpl') jobsTmpl: TemplateRef<any>;
    @ViewChild('studiesTmpl') studiesTmpl: TemplateRef<any>;
    @ViewChild('jobStatusesTmpl') jobStatusesTmpl: TemplateRef<any>;
    @ViewChild('jobTypesTmpl') jobTypesTmpl: TemplateRef<any>;
    @ViewChild('jobSubtypesTmpl') jobSubtypesTmpl: TemplateRef<any>;
    @ViewChild('workflowTaskKeysTmpl') workflowTaskKeysTmpl: TemplateRef<any>;
    @ViewChild('taskNamesTmpl') taskNamesTmpl: TemplateRef<any>;
    @ViewChild('protocolTaskNamesTmpl') protocolTaskNamesTmpl: TemplateRef<any>;
    @ViewChild('taskStatusesTmpl') taskStatusesTmpl: TemplateRef<any>;
    @ViewChild('dueDateRangeTmpl') dueDateRangeTmpl: TemplateRef<any>;
    @ViewChild('linesTmpl') linesTmpl: TemplateRef<any>;
    @ViewChild('taxonsTmpl') taxonsTmpl: TemplateRef<any>;
    @ViewChild('standardPhrasesTmpl') standardPhrasesTmpl: TemplateRef<any>;
    @ViewChild('variablePhraseTypesTmpl') variablePhraseTypesTmpl: TemplateRef<any>;
    @ViewChild('groupsTmpl') groupsTmpl: TemplateRef<any>;
    @ViewChild('testArticlesTmpl') testArticlesTmpl: TemplateRef<any>;
    @ViewChild('jobCharacteristicsTmpl') jobCharacteristicsTmpl: TemplateRef<any>;
    @ViewChild('institutionsTmpl') institutionsTmpl: TemplateRef<any>;
    @ViewChild('animalPlaceholdersTmpl') animalPlaceholdersTmpl: TemplateRef<any>;
    @ViewChild('groupPlaceholdersTmpl') groupPlaceholdersTmpl: TemplateRef<any>;
    @ViewChild('cohortsTmpl') cohortsTmpl: TemplateRef<any>;
    @ViewChild('housingsTmpl') housingsTmpl: TemplateRef<any>;
    @ViewChild('materialPoolTypesTmpl') materialPoolTypesTmpl: TemplateRef<any>;
    @ViewChild('materialPoolStatusesTmpl') materialPoolStatusesTmpl: TemplateRef<any>;
    @ViewChild('animalStatusesTmpl') animalStatusesTmpl: TemplateRef<any>;
    @ViewChild('housingDateRangeTmpl') housingDateRangeTmpl: TemplateRef<any>;
    @ViewChild('matingsTmpl') matingsTmpl: TemplateRef<any>;
    @ViewChild('matingStatusesTmpl') matingStatusesTmpl: TemplateRef<any>;
    @ViewChild('animalMatingStatusesTmpl') animalMatingStatusesTmpl: TemplateRef<any>;
    @ViewChild('matingTypesTmpl') matingTypesTmpl: TemplateRef<any>;
    @ViewChild('matingDateRangeTmpl') matingDateRangeTmpl: TemplateRef<any>;
    @ViewChild('lineShortNamesTmpl') lineShortNamesTmpl: TemplateRef<any>;
    @ViewChild('studyTypesTmpl') studyTypesTmpl: TemplateRef<any>;
    @ViewChild('studyStatusesTmpl') studyStatusesTmpl: TemplateRef<any>;
    @ViewChild('birthsTmpl') birthsTmpl: TemplateRef<any>;
    @ViewChild('birthStatusesTmpl') birthStatusesTmpl: TemplateRef<any>;
    @ViewChild('locationsTmpl') locationsTmpl: TemplateRef<any>;
    @ViewChild('locationTypesTmpl') locationTypesTmpl: TemplateRef<any>;
    @ViewChild('parentLocationsTmpl') parentLocationsTmpl: TemplateRef<any>;
    @ViewChild('taskTypesTmpl') taskTypesTmpl: TemplateRef<any>;
    @ViewChild('ordersAnimalsTmpl') ordersAnimalsTmpl: TemplateRef<any>;
    @ViewChild('protocolsTmpl') protocolsTmpl: TemplateRef<any>;
    @ViewChild('resourceNameTmpl') resourceNameTmpl: TemplateRef<any>;
    @ViewChild('emailAddressTmpl') emailAddressTmpl: TemplateRef<any>;
    @ViewChild('resourceTypesTmpl') resourceTypesTmpl: TemplateRef<any>;
    @ViewChild('usernamesTmpl') usernamesTmpl: TemplateRef<any>;
    @ViewChild('activeResourceTmpl') activeResourceTmpl: TemplateRef<any>;
    @ViewChild('resourceGroupsTmpl') resourceGroupsTmpl: TemplateRef<any>;
    @ViewChild('usersTmpl') usersTmpl: TemplateRef<any>;
    @ViewChild('userNameTmpl') userNameTmpl: TemplateRef<any>;
    @ViewChild('firstNameTmpl') firstNameTmpl: TemplateRef<any>;
    @ViewChild('lastNameTmpl') lastNameTmpl: TemplateRef<any>;
    @ViewChild('roleNameTmpl') roleNameTmpl: TemplateRef<any>;
    @ViewChild('facetNameTmpl') facetNameTmpl: TemplateRef<any>;
    @ViewChild('hasReadAccessTmpl') hasReadAccessTmpl: TemplateRef<any>;
    @ViewChild('hasWriteAccessTmpl') hasWriteAccessTmpl: TemplateRef<any>;
    @ViewChild('isStudyAdministratorTmpl') isStudyAdministratorTmpl: TemplateRef<any>;
    @ViewChild('taskFlagMessageTmpl') taskFlagMessageTmpl: TemplateRef<any>;
    @ViewChild('taskStatusTmpl') taskStatusTmpl: TemplateRef<any>;
    @ViewChild('activeTaskStatusTmpl') activeTaskStatusTmpl: TemplateRef<any>;
    @ViewChild('endStateTaskStatusTmpl') endStateTaskStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultTaskStatusTmpl') defaultTaskStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultEndStateTaskStatusTmpl') defaultEndStateTaskStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultAutoEndStateTaskStatusTmpl') defaultAutoEndStateTaskStatusTmpl: TemplateRef<any>;
    @ViewChild('activeTaskFlagMessageTmpl') activeTaskFlagMessageTmpl: TemplateRef<any>;
    @ViewChild('animalClassificationTmpl') animalClassificationTmpl: TemplateRef<any>;
    @ViewChild('activeAnimalClassificationTmpl') activeAnimalClassificationTmpl: TemplateRef<any>;
    @ViewChild('defaultAnimalClassificationTmpl') defaultAnimalClassificationTmpl: TemplateRef<any>;
    @ViewChild('animalCommentStatusTmpl') animalCommentStatusTmpl: TemplateRef<any>;
    @ViewChild('activeAnimalCommentStatusTmpl') activeAnimalCommentStatusTmpl: TemplateRef<any>;
    @ViewChild('endStateAnimalCommentStatusTmpl') endStateAnimalCommentStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultAnimalCommentStatusTmpl') defaultAnimalCommentStatusTmpl: TemplateRef<any>;
    @ViewChild('permanentAnimalCommentStatusTmpl') permanentAnimalCommentStatusTmpl: TemplateRef<any>;
    @ViewChild('animalStatusTmpl') animalStatusTmpl: TemplateRef<any>;
    @ViewChild('activeAnimalStatusTmpl') activeAnimalStatusTmpl: TemplateRef<any>;
    @ViewChild('endStateAnimalStatusTmpl') endStateAnimalStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultAnimalStatusTmpl') defaultAnimalStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultClinicalStatusAnimalStatusTmpl') defaultClinicalStatusAnimalStatusTmpl: TemplateRef<any>;
    @ViewChild('jobSubtypeTmpl') jobSubtypeTmpl: TemplateRef<any>;
    @ViewChild('activeJobSubtypeTmpl') activeJobSubtypeTmpl: TemplateRef<any>;
    @ViewChild('defaultJobSubtypeTmpl') defaultJobSubtypeTmpl: TemplateRef<any>;
    @ViewChild('taskOutcomeTmpl') taskOutcomeTmpl: TemplateRef<any>;
    @ViewChild('activeTaskOutcomeTmpl') activeTaskOutcomeTmpl: TemplateRef<any>;
    @ViewChild('defaultTaskOutcomeTmpl') defaultTaskOutcomeTmpl: TemplateRef<any>;
    @ViewChild('jobStatusTmpl') jobStatusTmpl: TemplateRef<any>;
    @ViewChild('activeJobStatusTmpl') activeJobStatusTmpl: TemplateRef<any>;
    @ViewChild('endStateJobStatusTmpl') endStateJobStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultJobStatusTmpl') defaultJobStatusTmpl: TemplateRef<any>;
    @ViewChild('animalUseTmpl') animalUseTmpl: TemplateRef<any>;
    @ViewChild('activeAnimalUseTmpl') activeAnimalUseTmpl: TemplateRef<any>;
    @ViewChild('defaultAnimalUseTmpl') defaultAnimalUseTmpl: TemplateRef<any>;
    @ViewChild('clinicalObservationStatusTmpl') clinicalObservationStatusTmpl: TemplateRef<any>;
    @ViewChild('activeClinicalObservationStatusTmpl') activeClinicalObservationStatusTmpl: TemplateRef<any>;
    @ViewChild('endStateClinicalObservationStatusTmpl') endStateClinicalObservationStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultClinicalObservationStatusTmpl') defaultClinicalObservationStatusTmpl: TemplateRef<any>;
    @ViewChild('jobTypeTmpl') jobTypeTmpl: TemplateRef<any>;
    @ViewChild('activeJobTypeTmpl') activeJobTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultJobTypeTmpl') defaultJobTypeTmpl: TemplateRef<any>;
    @ViewChild('physicalMarkerTypeTmpl') physicalMarkerTypeTmpl: TemplateRef<any>;
    @ViewChild('activePhysicalMarkerTypeTmpl') activePhysicalMarkerTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultPhysicalMarkerTypeTmpl') defaultPhysicalMarkerTypeTmpl: TemplateRef<any>;
    @ViewChild('plateStatusTmpl') plateStatusTmpl: TemplateRef<any>;
    @ViewChild('activePlateStatusTmpl') activePlateStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultPlateStatusTmpl') defaultPlateStatusTmpl: TemplateRef<any>;
    @ViewChild('birthStatusTmpl') birthStatusTmpl: TemplateRef<any>;
    @ViewChild('activeBirthStatusTmpl') activeBirthStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultBirthStatusTmpl') defaultBirthStatusTmpl: TemplateRef<any>;
    @ViewChild('bodyConditionScoreTmpl') bodyConditionScoreTmpl: TemplateRef<any>;
    @ViewChild('activeBodyConditionScoreTmpl') activeBodyConditionScoreTmpl: TemplateRef<any>;
    @ViewChild('defaultBodyConditionScoreTmpl') defaultBodyConditionScoreTmpl: TemplateRef<any>;
    @ViewChild('shipmentAnimalConditionTmpl') shipmentAnimalConditionTmpl: TemplateRef<any>;
    @ViewChild('activeShipmentAnimalConditionTmpl') activeShipmentAnimalConditionTmpl: TemplateRef<any>;
    @ViewChild('defaultShipmentAnimalConditionTmpl') defaultShipmentAnimalConditionTmpl: TemplateRef<any>;
    @ViewChild('clinicalObservationTmpl') clinicalObservationTmpl: TemplateRef<any>;
    @ViewChild('activeClinicalObservationTmpl') activeClinicalObservationTmpl: TemplateRef<any>;
    @ViewChild('experimentalClinicalObservationTmpl') experimentalClinicalObservationTmpl: TemplateRef<any>;
    @ViewChild('defaultClinicalObservationTmpl') defaultClinicalObservationTmpl: TemplateRef<any>;
    @ViewChild('resourceGroupTmpl') resourceGroupTmpl: TemplateRef<any>;
    @ViewChild('activeResourceGroupTmpl') activeResourceGroupTmpl: TemplateRef<any>;
    @ViewChild('defaultResourceGroupTmpl') defaultResourceGroupTmpl: TemplateRef<any>;
    @ViewChild('resourceTypeTmpl') resourceTypeTmpl: TemplateRef<any>;
    @ViewChild('activeResourceTypeTmpl') activeResourceTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultResourceTypeTmpl') defaultResourceTypeTmpl: TemplateRef<any>;
    @ViewChild('iacucProtocolTmpl') iacucProtocolTmpl: TemplateRef<any>;
    @ViewChild('activeIACUCProtocolTmpl') activeIACUCProtocolTmpl: TemplateRef<any>;
    @ViewChild('defaultIACUCProtocolTmpl') defaultIACUCProtocolTmpl: TemplateRef<any>;
    @ViewChild('matingPurposeTmpl') matingPurposeTmpl: TemplateRef<any>;
    @ViewChild('activeMatingPurposeTmpl') activeMatingPurposeTmpl: TemplateRef<any>;
    @ViewChild('defaultMatingPurposeTmpl') defaultMatingPurposeTmpl: TemplateRef<any>;
    @ViewChild('matingStatusTmpl') matingStatusTmpl: TemplateRef<any>;
    @ViewChild('activeMatingStatusTmpl') activeMatingStatusTmpl: TemplateRef<any>;
    @ViewChild('endStateMatingStatusTmpl') endStateMatingStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultMatingStatusTmpl') defaultMatingStatusTmpl: TemplateRef<any>;
    @ViewChild('paymentTermTmpl') paymentTermTmpl: TemplateRef<any>;
    @ViewChild('activePaymentTermTmpl') activePaymentTermTmpl: TemplateRef<any>;
    @ViewChild('defaultPaymentTermTmpl') defaultPaymentTermTmpl: TemplateRef<any>;
    @ViewChild('matingTypeTmpl') matingTypeTmpl: TemplateRef<any>;
    @ViewChild('activeMatingTypeTmpl') activeMatingTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultMatingTypeTmpl') defaultMatingTypeTmpl: TemplateRef<any>;
    @ViewChild('approvalNumbersTmpl') approvalNumbersTmpl: TemplateRef<any>;
    @ViewChild('animalCharacteristicNameTmpl') animalCharacteristicNameTmpl: TemplateRef<any>;
    @ViewChild('activeAnimalCharacteristicTmpl') activeAnimalCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('speciesAnimalCharacteristicTmpl') speciesAnimalCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('lastReviewedAnimalCharacteristicTmpl') lastReviewedAnimalCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('jobCharacteristicNameTmpl') jobCharacteristicNameTmpl: TemplateRef<any>;
    @ViewChild('activeJobCharacteristicTmpl') activeJobCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('linkTypeJobCharacteristicTmpl') linkTypeJobCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('lastReviewedJobCharacteristicTmpl') lastReviewedJobCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('studyCharacteristicNameTmpl') studyCharacteristicNameTmpl: TemplateRef<any>;
    @ViewChild('activeStudyCharacteristicTmpl') activeStudyCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('typeStudyCharacteristicTmpl') typeStudyCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('lastReviewedStudyCharacteristicTmpl') lastReviewedStudyCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('courierTmpl') courierTmpl: TemplateRef<any>;
    @ViewChild('activeCourierTmpl') activeCourierTmpl: TemplateRef<any>;
    @ViewChild('defaultCourierTmpl') defaultCourierTmpl: TemplateRef<any>;
    @ViewChild('exitReasonTmpl') exitReasonTmpl: TemplateRef<any>;
    @ViewChild('activeExitReasonTmpl') activeExitReasonTmpl: TemplateRef<any>;
    @ViewChild('defaultExitReasonTmpl') defaultExitReasonTmpl: TemplateRef<any>;
    @ViewChild('genotypeTmpl') genotypeTmpl: TemplateRef<any>;
    @ViewChild('activeGenotypeTmpl') activeGenotypeTmpl: TemplateRef<any>;
    @ViewChild('defaultGenotypeTmpl') defaultGenotypeTmpl: TemplateRef<any>;
    @ViewChild('sampleCharacteristicNameTmpl') sampleCharacteristicNameTmpl: TemplateRef<any>;
    @ViewChild('activeSampleCharacteristicTmpl') activeSampleCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('typeSampleCharacteristicTmpl') typeSampleCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('lastReviewedSampleCharacteristicTmpl') lastReviewedSampleCharacteristicTmpl: TemplateRef<any>;
    @ViewChild('orderIdTmpl') orderIdTmpl: TemplateRef<any>;
    @ViewChild('orderTypesTmpl') orderTypesTmpl: TemplateRef<any>;
    @ViewChild('lineStatusTmpl') lineStatusTmpl: TemplateRef<any>;
    @ViewChild('activeLineStatusTmpl') activeLineStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultLineStatusTmpl') defaultLineStatusTmpl: TemplateRef<any>;
    @ViewChild('observedByTmpl') observedByTmpl: TemplateRef<any>;
    @ViewChild('observedByUsernameTmpl') observedByUsernameTmpl: TemplateRef<any>;
    @ViewChild('observationStatusesTmpl') observationStatusesTmpl: TemplateRef<any>;
    @ViewChild('orderIdOrdersAnimalsTmpl') orderIdOrdersAnimalsTmpl: TemplateRef<any>;
    @ViewChild('orderIdOrdersLocationsTmpl') orderIdOrdersLocationsTmpl: TemplateRef<any>;
    @ViewChild('sampleAnalysisTmpl') sampleAnalysisTmpl: TemplateRef<any>;
    @ViewChild('activeSampleAnalysisTmpl') activeSampleAnalysisTmpl: TemplateRef<any>;
    @ViewChild('defaultSampleAnalysisTmpl') defaultSampleAnalysisTmpl: TemplateRef<any>;
    @ViewChild('sampleConditionTmpl') sampleConditionTmpl: TemplateRef<any>;
    @ViewChild('activeSampleConditionTmpl') activeSampleConditionTmpl: TemplateRef<any>;
    @ViewChild('defaultSampleConditionTmpl') defaultSampleConditionTmpl: TemplateRef<any>;
    @ViewChild('materialOriginTmpl') materialOriginTmpl: TemplateRef<any>;
    @ViewChild('activeMaterialOriginTmpl') activeMaterialOriginTmpl: TemplateRef<any>;
    @ViewChild('defaultMaterialOriginTmpl') defaultMaterialOriginTmpl: TemplateRef<any>;
    @ViewChild('breedingStatusTmpl') breedingStatusTmpl: TemplateRef<any>;
    @ViewChild('activeBreedingStatusTmpl') activeBreedingStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultBreedingStatusTmpl') defaultBreedingStatusTmpl: TemplateRef<any>;
    @ViewChild('activeTaskTmpl') activeTaskTmpl: TemplateRef<any>;
    @ViewChild('nameFormatTypeTmpl') nameFormatTypeTmpl: TemplateRef<any>;
    @ViewChild('animalNameTmpl') animalNameTmpl: TemplateRef<any>;
    @ViewChild('animalIdentifierTmpl') animalIdentifierTmpl: TemplateRef<any>;
    @ViewChild('assignedToTmpl') assignedToTmpl: TemplateRef<any>;
    @ViewChild('completedTmpl') completedTmpl: TemplateRef<any>;
    @ViewChild('completedByTmpl') completedByTmpl: TemplateRef<any>;
    @ViewChild('collectedTmpl') collectedTmpl: TemplateRef<any>;
    @ViewChild('reviewedTmpl') reviewedTmpl: TemplateRef<any>;
    @ViewChild('dietTmpl') dietTmpl: TemplateRef<any>;
    @ViewChild('activeDietTmpl') activeDietTmpl: TemplateRef<any>;
    @ViewChild('defaultDietTmpl') defaultDietTmpl: TemplateRef<any>;
    @ViewChild('generationTmpl') generationTmpl: TemplateRef<any>;
    @ViewChild('activeGenerationTmpl') activeGenerationTmpl: TemplateRef<any>;
    @ViewChild('defaultGenerationTmpl') defaultGenerationTmpl: TemplateRef<any>;
    @ViewChild('locationTypeTmpl') locationTypeTmpl: TemplateRef<any>;
    @ViewChild('activeLocationTypeTmpl') activeLocationTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultLocationTypeTmpl') defaultLocationTypeTmpl: TemplateRef<any>;
    @ViewChild('filesNameTmpl') filesNameTmpl: TemplateRef<any>;
    @ViewChild('constructIdentifierTmpl') constructIdentifierTmpl: TemplateRef<any>;
    @ViewChild('classNameTmpl') classNameTmpl: TemplateRef<any>;
    @ViewChild('itemNameTmpl') itemNameTmpl: TemplateRef<any>;
    @ViewChild('urgentClinicalObservationTmpl') urgentClinicalObservationTmpl: TemplateRef<any>;
    @ViewChild('observedDateRangeTmpl') observedDateRangeTmpl: TemplateRef<any>;
    @ViewChild('observationsTmpl') observationsTmpl: TemplateRef<any>;
    @ViewChild('defaultHealthTechnicianTmpl') defaultHealthTechnicianTmpl: TemplateRef<any>;
    @ViewChild('inactivityTimeoutTmpl') inactivityTimeoutTmpl: TemplateRef<any>;
    @ViewChild('facetTypeTmpl') facetTypeTmpl: TemplateRef<any>;
    @ViewChild('facetSettingFieldTmpl') facetSettingFieldTmpl: TemplateRef<any>;
    @ViewChild('activeFacetSettingTmpl') activeFacetSettingTmpl: TemplateRef<any>;
    @ViewChild('requiredFacetSettingTmpl') requiredFacetSettingTmpl: TemplateRef<any>;
    @ViewChild('complianceTmpl') complianceTmpl: TemplateRef<any>;
    @ViewChild('activeComplianceTmpl') activeComplianceTmpl: TemplateRef<any>;
    @ViewChild('defaultComplianceTmpl') defaultComplianceTmpl: TemplateRef<any>;
    @ViewChild('institutionTypeTmpl') institutionTypeTmpl: TemplateRef<any>;
    @ViewChild('activeInstitutionTypeTmpl') activeInstitutionTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultInstitutionTypeTmpl') defaultInstitutionTypeTmpl: TemplateRef<any>;
    @ViewChild('lineTypeTmpl') lineTypeTmpl: TemplateRef<any>;
    @ViewChild('activeLineTypeTmpl') activeLineTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultLineTypeTmpl') defaultLineTypeTmpl: TemplateRef<any>;
    @ViewChild('dateInRangeTmpl') dateInRangeTmpl: TemplateRef<any>;
    @ViewChild('dateOutRangeTmpl') dateOutRangeTmpl: TemplateRef<any>;
    @ViewChild('containerTypeTmpl') containerTypeTmpl: TemplateRef<any>;
    @ViewChild('activeContainerTypeTmpl') activeContainerTypeTmpl: TemplateRef<any>;
    @ViewChild('animalContainerTypeTmpl') animalContainerTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultContainerTypeTmpl') defaultContainerTypeTmpl: TemplateRef<any>;
    @ViewChild('housingTypesTmpl') housingTypesTmpl: TemplateRef<any>;
    @ViewChild('activeHousingTypesTmpl') activeHousingTypesTmpl: TemplateRef<any>;
    @ViewChild('defaultMatingHousingTypesTmpl') defaultMatingHousingTypesTmpl: TemplateRef<any>;
    @ViewChild('defaultHousingTypesTmpl') defaultHousingTypesTmpl: TemplateRef<any>;
    @ViewChild('materialPoolStatusTmpl') materialPoolStatusTmpl: TemplateRef<any>;
    @ViewChild('activeMaterialPoolStatusTmpl') activeMaterialPoolStatusTmpl: TemplateRef<any>;
    @ViewChild('endStateMaterialPoolStatusTmpl') endStateMaterialPoolStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultMaterialPoolStatusTmpl') defaultMaterialPoolStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultEndStateMaterialPoolStatusTmpl') defaultEndStateMaterialPoolStatusTmpl: TemplateRef<any>;
    @ViewChild('facetTmpl') facetTmpl: TemplateRef<any>;
    @ViewChild('compatibilityAccessLevelTmpl') compatibilityAccessLevelTmpl: TemplateRef<any>;
    @ViewChild('activeCompatibilityAccessLevelTmpl') activeCompatibilityAccessLevelTmpl: TemplateRef<any>;
    @ViewChild('defaultCompatibilityAccessLevelTmpl') defaultCompatibilityAccessLevelTmpl: TemplateRef<any>;
    @ViewChild('sexesTmpl') sexesTmpl: TemplateRef<any>;
    @ViewChild('accessLevelsTmpl') accessLevelsTmpl: TemplateRef<any>;
    @ViewChild('sexTmpl') sexTmpl: TemplateRef<any>;
    @ViewChild('accessLevelTmpl') accessLevelTmpl: TemplateRef<any>;
    @ViewChild('dateDocumentedRangeTmpl') dateDocumentedRangeTmpl: TemplateRef<any>;
    @ViewChild('observationTypeTmpl') observationTypeTmpl: TemplateRef<any>;
    @ViewChild('observationSearchTmpl') observationSearchTmpl: TemplateRef<any>;
    @ViewChild('bodySystemTmpl') bodySystemTmpl: TemplateRef<any>;
    @ViewChild('modifier1Tmpl') modifier1Tmpl: TemplateRef<any>;
    @ViewChild('activeModifier1Tmpl') activeModifier1Tmpl: TemplateRef<any>;
    @ViewChild('modifier2Tmpl') modifier2Tmpl: TemplateRef<any>;
    @ViewChild('activeModifier2Tmpl') activeModifier2Tmpl: TemplateRef<any>;
    @ViewChild('modifier3Tmpl') modifier3Tmpl: TemplateRef<any>;
    @ViewChild('activeModifier3Tmpl') activeModifier3Tmpl: TemplateRef<any>;
    @ViewChild('modifier4Tmpl') modifier4Tmpl: TemplateRef<any>;
    @ViewChild('activeModifier4Tmpl') activeModifier4Tmpl: TemplateRef<any>;
    @ViewChild('socialGroupAccessLevelTmpl') socialGroupAccessLevelTmpl: TemplateRef<any>;
    @ViewChild('activeSocialGroupAccessLevelTmpl') activeSocialGroupAccessLevelTmpl: TemplateRef<any>;
    @ViewChild('defaultSocialGroupAccessLevelTmpl') defaultSocialGroupAccessLevelTmpl: TemplateRef<any>;
    @ViewChild('jobReportTmpl') jobReportTmpl: TemplateRef<any>;
    @ViewChild('activeJobReportTmpl') activeJobReportTmpl: TemplateRef<any>;
    @ViewChild('contactPersonTypeTmpl') contactPersonTypeTmpl: TemplateRef<any>;
    @ViewChild('activeContactPersonTypeTmpl') activeContactPersonTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultContactPersonTypeTmpl') defaultContactPersonTypeTmpl: TemplateRef<any>;
    @ViewChild('quarantineFacilityTmpl') quarantineFacilityTmpl: TemplateRef<any>;
    @ViewChild('activeQuarantineFacilityTmpl') activeQuarantineFacilityTmpl: TemplateRef<any>;
    @ViewChild('defaultQuarantineFacilityTmpl') defaultQuarantineFacilityTmpl: TemplateRef<any>;
    @ViewChild('genotypeAssayTmpl') genotypeAssayTmpl: TemplateRef<any>;
    @ViewChild('activeGenotypeAssayTmpl') activeGenotypeAssayTmpl: TemplateRef<any>;
    @ViewChild('defaultGenotypeAssayTmpl') defaultGenotypeAssayTmpl: TemplateRef<any>;
    @ViewChild('siteTypeTmpl') siteTypeTmpl: TemplateRef<any>;
    @ViewChild('activeSiteTypeTmpl') activeSiteTypeTmpl: TemplateRef<any>;
    @ViewChild('defaultSiteTypeTmpl') defaultSiteTypeTmpl: TemplateRef<any>;
    @ViewChild('outputNamesTmpl') outputNamesTmpl: TemplateRef<any>;
    @ViewChild('flagMessagesTmpl') flagMessagesTmpl: TemplateRef<any>;
    @ViewChild('unitTmpl') unitTmpl: TemplateRef<any>;
    @ViewChild('activeUnitTmpl') activeUnitTmpl: TemplateRef<any>;
    @ViewChild('defaultUnitTmpl') defaultUnitTmpl: TemplateRef<any>;
    @ViewChild('inputNameTmpl') inputNameTmpl: TemplateRef<any>;
    @ViewChild('defaultValueTmpl') defaultValueTmpl: TemplateRef<any>;
    @ViewChild('activeInputTmpl') activeInputTmpl: TemplateRef<any>;
    @ViewChild('requiredInputTmpl') requiredInputTmpl: TemplateRef<any>;
    @ViewChild('outputNameTmpl') outputNameTmpl: TemplateRef<any>;
    @ViewChild('activeOutputTmpl') activeOutputTmpl: TemplateRef<any>;
    @ViewChild('requiredOutputTmpl') requiredOutputTmpl: TemplateRef<any>;
    @ViewChild('dataTypeTmpl') dataTypeTmpl: TemplateRef<any>;
    @ViewChild('standardPhraseTmpl', { static: false }) standardPhraseTmpl: TemplateRef<any>;
    @ViewChild('activeStandardPhraseTmpl', { static: false }) activeStandardPhraseTmpl: TemplateRef<any>;
    @ViewChild('standardPhraseCategoriesTmpl', { static: false }) standardPhraseCategoriesTmpl: TemplateRef<any>;
    @ViewChild('standardPhraseLinkTypesTmpl', { static: false }) standardPhraseLinkTypesTmpl: TemplateRef<any>;
    @ViewChild('jobReportsTmpl', { static: false }) jobReportsTmpl: TemplateRef<any>;    
    @ViewChild('testArticleTmpl', { static: false }) testArticleTmpl: TemplateRef<any>;
    @ViewChild('activeTestArticleTmpl', { static: false }) activeTestArticleTmpl: TemplateRef<any>;
    @ViewChild('defaultTestArticleTmpl', { static: false }) defaultTestArticleTmpl: TemplateRef<any>;
    @ViewChild('routeOfAdministrationTmpl') routeOfAdministrationTmpl: TemplateRef<any>;
    @ViewChild('activeRouteOfAdministrationTmpl') activeRouteOfAdministrationTmpl: TemplateRef<any>;
    @ViewChild('defaultRouteOfAdministrationTmpl') defaultRouteOfAdministrationTmpl: TemplateRef<any>;
    @ViewChild('veterinaryRequestStatusTmpl') veterinaryRequestStatusTmpl: TemplateRef<any>;
    @ViewChild('activeVeterinaryRequestStatusTmpl') activeVeterinaryRequestStatusTmpl: TemplateRef<any>;
    @ViewChild('endStateVeterinaryRequestStatusTmpl') endStateVeterinaryRequestStatusTmpl: TemplateRef<any>;
    @ViewChild('defaultVeterinaryRequestStatusTmpl') defaultVeterinaryRequestStatusTmpl: TemplateRef<any>;
    @ViewChild('studyNameTmpl') studyNameTmpl: TemplateRef<any>;
    @ViewChild('studyTypeTmpl') studyTypeTmpl: TemplateRef<any>;
    @ViewChild('characteristicNameTmpl') characteristicNameTmpl: TemplateRef<any>;
    @ViewChild('characteristicValueTmpl') characteristicValueTmpl: TemplateRef<any>;
    @ViewChild('shipmentContainerConditionTmpl') shipmentContainerConditionTmpl: TemplateRef<any>;
    @ViewChild('activeShipmentContainerConditionTmpl') activeShipmentContainerConditionTmpl: TemplateRef<any>;
    @ViewChild('defaultShipmentContainerConditionTmpl') defaultShipmentContainerConditionTmpl: TemplateRef<any>;

    @Input() filter: any;

    readonly MULTI_PASTE_INPUT_LIMIT = 150;

    animalStatuses: any[] = [];
    animalCommentStatuses: any[] = [];
    birthStatuses: any[] = [];
    jobCharacteristics: any[] = [];
    jobStatuses: any[] = [];
    jobSubtypes: any[] = [];
    jobTypes: any[] = [];
    jobCharacteristicLinkTypes: cv_JobCharacteristicLinkType[] = [];
    locationTypes: any[] = [];
    materialPoolTypes: any[] = [];
    materialPoolStatuses: any[] = [];
    matingStatuses: any[] = [];
    animalMatingStatuses: any[] = [];
    matingTypes: any[] = [];
    resourceTypes: any[] = [];
    resourceGroups: any[] = [];
    taxons: any[] = [];
    studyStatuses: any[] = [];
    studyTypes: any[] = [];
    taskAliases: any[] = [];
    protocolTaskAliases: any[] = [];
    taskStatuses: any[] = [];
    taskTypes: any[] = [];
    workflowTasks: any[] = [];
    standardPhraseCategories: any[] = [];
    standardPhraseLinkTypes: any[] = [];
    jobReports: any[] = [];
    approvalNumbers: any[] = [];
    materialTypes: any[] = [];
    resourceNames: any[] = [];
    clinicalObservationStatuses: any[] = [];
    clinicalObservations: any[] = [];
    booleanValues: any[] = [];
    facetValues: any[] = [];
    sexes: any[] = [];
    accessLevels: any[] = [];
    observationTypes: any[] = [];
    bodySystems: any[] = [];
    dataTypes: any[] = [];
    sampleTypes: any[] = [];
    variablePhraseTypes: any[] = [];

    isGLP = false;

    private _controls: ControlsVisibility = new ControlsVisibility();
    private _filters: Filter[] = [];

    constructor(
        private workflowVocabService: WorkflowVocabService,
        private jobVocabService: JobVocabService,
        private clinicalVocabService: ClinicalVocabService,
        private taskVocabService: TaskVocabService,
        private housingVocabService: HousingVocabService,
        private matingVocabService: MatingVocabService,
        private studyVocabService: StudyVocabService,
        private birthVocabService: BirthVocabService,
        private vocabularyService: VocabularyService,
        private resourceVocabService: ResourceVocabService,
        private orderVocabService: OrderVocabService,
        private jobService: JobService,
        private webApiService: WebApiService,
        private loggingService: LoggingService,
        private studyService: StudyService,
        private featureFlagService: FeatureFlagService,
        private translationService: TranslationService,
        private animalVocabService: AnimalVocabService,
        private sampleVocabService: SampleVocabService,
    ) { }

    get auditFilterVisibility(): AuditFilterVisibility {
        return {
            animalIdentifiersVisible: this._controls.animalIdentifiers ? this._controls.animalIdentifiers.visible : false,
            animalPlaceholdersVisible: this._controls.animalPlaceholders ? this._controls.animalPlaceholders.visible : false,
            animalsVisible: this._controls.animals ? this._controls.animals.visible : false,
            animalStatusesVisible: this._controls.animalStatuses ? this._controls.animalStatuses.visible : false,
            animalCommentsVisible: this._controls.animalComments ? this._controls.animalComments.visible : false,
            animalCommentStatusesVisible: this._controls.animalCommentStatuses ? this._controls.animalCommentStatuses.visible : false,
            animalCommentAnimalNamesVisible: this._controls.animalCommentAnimalNames ? this._controls.animalCommentAnimalNames.visible : false,
            animalCommentAnimalIDsVisible: this._controls.animalCommentAnimalIDs ? this._controls.animalCommentAnimalIDs.visible : false,
            birthsVisible: this._controls.births ? this._controls.births.visible : false,
            birthStatusesVisible: this._controls.birthStatuses ? this._controls.birthStatuses.visible : false,
            cohortsVisible: this._controls.cohorts ? this._controls.cohorts.visible : false,
            dueDateRangeVisible: this._controls.dueDateRange ? this._controls.dueDateRange.visible : false,
            groupsVisible: this._controls.groups ? this._controls.groups.visible : false,
            groupPlaceholdersVisible: this._controls.groupPlaceholders ? this._controls.groupPlaceholders.visible : false,
            housingDateRangeVisible: this._controls.housingDateRange ? this._controls.housingDateRange.visible : false,
            housingsVisible: this._controls.housings ? this._controls.housings.visible : false,
            institutionsVisible: this._controls.institutions ? this._controls.institutions.visible : false,
            jobCharacteristicsVisible: this._controls.jobCharacteristics ? this._controls.jobCharacteristics.visible : false,
            jobsVisible: this._controls.jobs ? this._controls.jobs.visible : false,
            jobStatusesVisible: this._controls.jobStatuses ? this._controls.jobStatuses.visible : false,
            jobSubtypesVisible: this._controls.jobSubtypes ? this._controls.jobSubtypes.visible : false,
            jobTypesVisible: this._controls.jobTypes ? this._controls.jobTypes.visible : false,
            linesVisible: this._controls.lines ? this._controls.lines.visible : false,
            lineShortNamesVisible: this._controls.lineShortNames ? this._controls.lineShortNames.visible : false,
            locationsVisible: this._controls.locations ? this._controls.locations.visible : false,
            locationTypesVisible: this._controls.locationTypes ? this._controls.locationTypes.visible : false,
            materialPoolStatusesVisible: this._controls.materialPoolStatuses ? this._controls.materialPoolStatuses.visible : false,
            materialPoolTypesVisible: this._controls.materialPoolTypes ? this._controls.materialPoolTypes.visible : false,
            matingDateRangeVisible: this._controls.matingDateRange ? this._controls.matingDateRange.visible : false,
            matingsVisible: this._controls.matings ? this._controls.matings.visible : false,
            matingStatusesVisible: this._controls.matingStatuses ? this._controls.matingStatuses.visible : false,
            animalMatingStatusesVisible: this._controls.animalMatingStatuses ? this._controls.animalMatingStatuses.visible : false,
            matingTypesVisible: this._controls.matingTypes ? this._controls.matingTypes.visible : false,
            microchipIdentifiersVisible: this._controls.microchipIdentifiers ? this._controls.microchipIdentifiers.visible : false,
            parentLocationsVisible: this._controls.parentLocations ? this._controls.parentLocations.visible : false,
            protocolsVisible: this._controls.protocols ? this._controls.protocols.visible : false,
            resourceNameVisible: this._controls.resourceName ? this._controls.resourceName.visible : false,
            activeResourceVisible: this._controls.activeResource ? this._controls.activeResource.visible : false,
            emailAddressVisible: this._controls.emailAddress ? this._controls.emailAddress.visible : false,
            resourceTypesVisible: this._controls.resourceTypes ? this._controls.resourceTypes.visible : false,
            roleNameVisible: this._controls.roleName ? this._controls.roleName.visible : false,
            usernamesVisible: this._controls.usernames ? this._controls.usernames.visible : false,
            resourceGroupsVisible: this._controls.resourceGroups ? this._controls.resourceGroups.visible : false,
            sampleIdentifiersVisible: this._controls.sampleIdentifiers ? this._controls.sampleIdentifiers.visible : false,
            samplesVisible: this._controls.samples ? this._controls.samples.visible : false,
            sourceIdentifiersVisible: this._controls.sourceIdentifiers ? this._controls.sourceIdentifiers.visible : false,
            sourceMaterialsVisible: this._controls.sourceMaterials ? this._controls.sourceMaterials.visible : false,
            standardPhrasesVisible: this._controls.standardPhrases ? this._controls.standardPhrases.visible : false,
            variablePhraseTypesVisible: this._visibleOrFalse(this._controls.variablePhraseTypes),
            studiesVisible: this._controls.studies ? this._controls.studies.visible : false,
            studyStatusesVisible: this._controls.studyStatuses ? this._controls.studyStatuses.visible : false,
            studyTypesVisible: this._controls.studyTypes ? this._controls.studyTypes.visible : false,
            taskNamesVisible: this._controls.taskNames ? this._controls.taskNames.visible : false,
            protocolTaskNamesVisible: this._controls.protocolTaskNames ? this._controls.protocolTaskNames.visible : false,
            taskStatusesVisible: this._controls.taskStatuses ? this._controls.taskStatuses.visible : false,
            taskTypesVisible: this._controls.taskTypes ? this._controls.taskTypes.visible : false,
            taxonsVisible: this._controls.taxons ? this._controls.taxons.visible : false,
            testArticlesVisible: this._controls.testArticles ? this._controls.testArticles.visible : false,
            workflowTaskKeysVisible: this._controls.workflowTaskKeys ? this._controls.workflowTaskKeys.visible : false,
            usersVisible: this._controls.users ? this._controls.users.visible : false,
            userNameVisible: this._controls.userName ? this._controls.userName.visible : false,
            firstNameVisible: this._controls.firstName ? this._controls.firstName.visible : false,
            lastNameVisible: this._controls.lastName ? this._controls.lastName.visible : false,
            isStudyAdministratorVisible: this._controls.isStudyAdministrator ? this._controls.isStudyAdministrator.visible : false,
            facetNameVisible: this._controls.facetName ? this._controls.facetName.visible : false,
            hasReadAccessVisible: this._controls.hasReadAccess ? this._controls.hasReadAccess.visible : false,
            hasWriteAccessVisible: this._controls.hasWriteAccess ? this._controls.hasWriteAccess.visible : false,
            taskFlagMessageVisible: this._controls.taskFlagMessage ? this._controls.taskFlagMessage.visible : false,
            activeTaskFlagMessageVisible: this._controls.activeTaskFlagMessage ? this._controls.activeTaskFlagMessage.visible : false,
            taskStatusVisible: this._controls.taskStatus ? this._controls.taskStatus.visible : false,
            activeTaskStatusVisible: this._controls.activeTaskStatus ? this._controls.activeTaskStatus.visible : false,
            endStateTaskStatusVisible: this._controls.endStateTaskStatus ? this._controls.endStateTaskStatus.visible : false,
            defaultTaskStatusVisible: this._controls.defaultTaskStatus ? this._controls.defaultTaskStatus.visible : false,
            defaultEndStateTaskStatusVisible: this._controls.defaultEndStateTaskStatus ? this._controls.defaultEndStateTaskStatus.visible : false,
            defaultAutoEndStateTaskStatusVisible: this._controls.defaultAutoEndStateTaskStatus ? this._controls.defaultAutoEndStateTaskStatus.visible : false,
            animalClassificationVisible: this._controls.animalClassification ? this._controls.animalClassification.visible : false,
            activeAnimalClassificationVisible: this._controls.activeAnimalClassification ? this._controls.activeAnimalClassification.visible : false,
            defaultAnimalClassificationVisible: this._controls.defaultAnimalClassification ? this._controls.defaultAnimalClassification.visible : false,
            animalCommentStatusVisible: this._controls.animalCommentStatus ? this._controls.animalCommentStatus.visible : false,
            activeAnimalCommentStatusVisible: this._controls.activeAnimalCommentStatus ? this._controls.activeAnimalCommentStatus.visible : false,
            endStateAnimalCommentStatusVisible: this._controls.endStateAnimalCommentStatus ? this._controls.endStateAnimalCommentStatus.visible : false,
            defaultAnimalCommentStatusVisible: this._controls.defaultAnimalCommentStatus ? this._controls.defaultAnimalCommentStatus.visible : false,
            permanentAnimalCommentStatusVisible: this._controls.permanentAnimalCommentStatus ? this._controls.permanentAnimalCommentStatus.visible : false,
            standardPhraseVisible: this._controls.standardPhrase ? this._controls.standardPhrase.visible : false,
            activeStandardPhraseVisible: this._controls.activeStandardPhrase ? this._controls.activeStandardPhrase.visible : false,
            standardPhraseCategoriesVisible: this._controls.standardPhraseCategories ? this._controls.standardPhraseCategories.visible : false,
            standardPhraseLinkTypesVisible: this._controls.standardPhraseLinkTypes ? this._controls.standardPhraseLinkTypes.visible : false,
            jobReportsVisible: this._controls.jobReports ? this._controls.jobReports.visible : false,
            animalStatusVisible: this._controls.animalStatus ? this._controls.animalStatus.visible : false,
            activeAnimalStatusVisible: this._controls.activeAnimalStatus ? this._controls.activeAnimalStatus.visible : false,
            endStateAnimalStatusVisible: this._controls.endStateAnimalStatus ? this._controls.endStateAnimalStatus.visible : false,
            defaultAnimalStatusVisible: this._controls.defaultAnimalStatus ? this._controls.defaultAnimalStatus.visible : false,
            defaultClinicalStatusAnimalStatusVisible: this._controls.defaultClinicalStatusAnimalStatus ? this._controls.defaultClinicalStatusAnimalStatus.visible : false,
            jobSubtypeVisible: this._controls.jobSubtype ? this._controls.jobSubtype.visible : false,
            activeJobSubtypeVisible: this._controls.activeJobSubtype ? this._controls.activeJobSubtype.visible : false,
            defaultJobSubtypeVisible: this._controls.defaultJobSubtype ? this._controls.defaultJobSubtype.visible : false,
            taskOutcomeVisible: this._controls.taskOutcome ? this._controls.taskOutcome.visible : false,
            activeTaskOutcomeVisible: this._controls.activeTaskOutcome ? this._controls.activeTaskOutcome.visible : false,
            defaultTaskOutcomeVisible: this._controls.defaultTaskOutcome ? this._controls.defaultTaskOutcome.visible : false,
            jobStatusVisible: this._controls.jobStatus ? this._controls.jobStatus.visible : false,
            activeJobStatusVisible: this._controls.activeJobStatus ? this._controls.activeJobStatus.visible : false,
            endStateJobStatusVisible: this._controls.endStateJobStatus ? this._controls.endStateJobStatus.visible : false,
            draftJobStatusVisible: this._controls.draftJobStatus ? this._controls.draftJobStatus.visible : false,
            defaultJobStatusVisible: this._controls.defaultJobStatus ? this._controls.defaultJobStatus.visible : false,
            animalUseVisible: this._controls.animalUse ? this._controls.animalUse.visible : false,
            activeAnimalUseVisible: this._controls.activeAnimalUse ? this._controls.activeAnimalUse.visible : false,
            defaultAnimalUseVisible: this._controls.defaultAnimalUse ? this._controls.defaultAnimalUse.visible : false,
            jobTypeVisible: this._controls.jobType ? this._controls.jobType.visible : false,
            activeJobTypeVisible: this._controls.activeJobType ? this._controls.activeJobType.visible : false,
            defaultJobTypeVisible: this._controls.defaultJobType ? this._controls.defaultJobType.visible : false,
            physicalMarkerTypeVisible: this._controls.physicalMarkerType ? this._controls.physicalMarkerType.visible : false,
            activePhysicalMarkerTypeVisible: this._controls.activePhysicalMarkerType ? this._controls.activePhysicalMarkerType.visible : false,
            defaultPhysicalMarkerTypeVisible: this._controls.defaultPhysicalMarkerType ? this._controls.defaultPhysicalMarkerType.visible : false,
            plateStatusVisible: this._controls.plateStatus ? this._controls.plateStatus.visible : false,
            activePlateStatusVisible: this._controls.activePlateStatus ? this._controls.activePlateStatus.visible : false,
            defaultPlateStatusVisible: this._controls.defaultPlateStatus ? this._controls.defaultPlateStatus.visible : false,
            birthStatusVisible: this._controls.birthStatus ? this._controls.birthStatus.visible : false,
            activeBirthStatusVisible: this._controls.activeBirthStatus ? this._controls.activeBirthStatus.visible : false,
            defaultBirthStatusVisible: this._controls.defaultBirthStatus ? this._controls.defaultBirthStatus.visible : false,
            bodyConditionScoreVisible: this._controls.bodyConditionScore ? this._controls.bodyConditionScore.visible : false,
            activeBodyConditionScoreVisible: this._controls.activeBodyConditionScore ? this._controls.activeBodyConditionScore.visible : false,
            defaultBodyConditionScoreVisible: this._controls.defaultBodyConditionScore ? this._controls.defaultBodyConditionScore.visible : false,
            shipmentAnimalConditionVisible: this._controls.shipmentAnimalCondition ? this._controls.shipmentAnimalCondition.visible : false,
            activeShipmentAnimalConditionVisible: this._controls.activeShipmentAnimalCondition ? this._controls.activeShipmentAnimalCondition.visible : false,
            defaultShipmentAnimalConditionVisible: this._controls.defaultShipmentAnimalCondition ? this._controls.defaultShipmentAnimalCondition.visible : false,
            clinicalObservationStatusVisible: this._controls.clinicalObservationStatus ? this._controls.clinicalObservationStatus.visible : false,
            activeClinicalObservationStatusVisible: this._controls.activeClinicalObservationStatus ? this._controls.activeClinicalObservationStatus.visible : false,
            endStateClinicalObservationStatusVisible: this._controls.endStateClinicalObservationStatus ? this._controls.endStateClinicalObservationStatus.visible : false,
            defaultClinicalObservationStatusVisible: this._controls.defaultClinicalObservationStatus ? this._controls.defaultClinicalObservationStatus.visible : false,
            clinicalObservationVisible: this._controls.clinicalObservation ? this._controls.clinicalObservation.visible : false,
            activeClinicalObservationVisible: this._controls.activeClinicalObservation ? this._controls.activeClinicalObservation.visible : false,
            experimentalClinicalObservationVisible: this._controls.experimentalClinicalObservation ? this._controls.experimentalClinicalObservation.visible : false,
            defaultClinicalObservationVisible: this._controls.defaultClinicalObservation ? this._controls.defaultClinicalObservation.visible : false,
            resourceGroupVisible: this._controls.resourceGroup ? this._controls.resourceGroup.visible : false,
            activeResourceGroupVisible: this._controls.activeResourceGroup ? this._controls.activeResourceGroup.visible : false,
            defaultResourceGroupVisible: this._controls.defaultResourceGroup ? this._controls.defaultResourceGroup.visible : false,
            resourceTypeVisible: this._controls.resourceType ? this._controls.resourceType.visible : false,
            activeResourceTypeVisible: this._controls.activeResourceType ? this._controls.activeResourceType.visible : false,
            defaultResourceTypeVisible: this._controls.defaultResourceType ? this._controls.defaultResourceType.visible : false,
            iacucProtocolVisible: this._controls.iacucProtocol ? this._controls.iacucProtocol.visible : false,
            activeIACUCProtocolVisible: this._controls.activeIACUCProtocol ? this._controls.activeIACUCProtocol.visible : false,
            defaultIACUCProtocolVisible: this._controls.defaultIACUCProtocol ? this._controls.defaultIACUCProtocol.visible : false,
            matingPurposeVisible: this._controls.matingPurpose ? this._controls.matingPurpose.visible : false,
            activeMatingPurposeVisible: this._controls.activeMatingPurpose ? this._controls.activeMatingPurpose.visible : false,
            defaultMatingPurposeVisible: this._controls.defaultMatingPurpose ? this._controls.defaultMatingPurpose.visible : false,
            matingStatusVisible: this._controls.matingStatus ? this._controls.matingStatus.visible : false,
            activeMatingStatusVisible: this._controls.activeMatingStatus ? this._controls.activeMatingStatus.visible : false,
            endStateMatingStatusVisible: this._controls.endStateMatingStatus ? this._controls.endStateMatingStatus.visible : false,
            defaultMatingStatusVisible: this._controls.defaultMatingStatus ? this._controls.defaultMatingStatus.visible : false,
            paymentTermVisible: this._controls.paymentTerm ? this._controls.paymentTerm.visible : false,
            activePaymentTermVisible: this._controls.activePaymentTerm ? this._controls.activePaymentTerm.visible : false,
            defaultPaymentTermVisible: this._controls.defaultPaymentTerm ? this._controls.defaultPaymentTerm.visible : false,
            matingTypeVisible: this._controls.matingType ? this._controls.matingType.visible : false,
            activeMatingTypeVisible: this._controls.activeMatingType ? this._controls.activeMatingType.visible : false,
            defaultMatingTypeVisible: this._controls.defaultMatingTypel ? this._controls.defaultMatingType.visible : false,
            approvalNumbersVisible: this._controls.approvalNumbers ? this._controls.approvalNumbers.visible : false,
            testArticleVisible: this._controls.testArticle ? this._controls.testArticle.visible : false,
            activeTestArticleVisible: this._controls.activeTestArticle ? this._controls.activeTestArticle.visible : false,
            defaultTestArticleVisible: this._controls.defaultTestArticle ? this._controls.defaultTestArticle.visible : false,
            classNameVisible: this._controls.className ? this._controls.className.visible : false,
            itemNameVisible: this._controls.itemName ? this._controls.itemName.visible : false,
            animalCharacteristicNameVisible: this._controls.animalCharacteristicName ? this._controls.animalCharacteristicName.visible : false,
            activeAnimalCharacteristicVisible: this._controls.activeAnimalCharacteristic ? this._controls.activeAnimalCharacteristic.visible : false,
            speciesAnimalCharacteristicVisible: this._controls.speciesAnimalCharacteristic ? this._controls.speciesAnimalCharacteristic.visible : false,
            lastReviewedAnimalCharacteristicVisible: this._controls.lastReviewedAnimalCharacteristic ? this._controls.lastReviewedAnimalCharacteristic.visible : false,
            jobCharacteristicNameVisible: this._controls.jobCharacteristicName ? this._controls.jobCharacteristicName.visible : false,
            activeJobCharacteristicVisible: this._controls.activeJobCharacteristic ? this._controls.activeJobCharacteristic.visible : false,
            linkTypeJobCharacteristicVisible: this._controls.linkTypeJobCharacteristic ? this._controls.linkTypeJobCharacteristic.visible : false,
            lastReviewedJobCharacteristicVisible: this._controls.lastReviewedJobCharacteristic ? this._controls.lastReviewedJobCharacteristic.visible : false,
            studyCharacteristicNameVisible: this._controls.studyCharacteristicName ? this._controls.studyCharacteristicName.visible : false,
            activeStudyCharacteristicVisible: this._controls.activeStudyCharacteristic ? this._controls.activeStudyCharacteristic.visible : false,
            typeStudyCharacteristicVisible: this._controls.typeStudyCharacteristic ? this._controls.typeStudyCharacteristic.visible : false,
            lastReviewedStudyCharacteristicVisible: this._controls.lastReviewedStudyCharacteristic ? this._controls.lastReviewedStudyCharacteristic.visible : false,
            courierVisible: this._controls.courier ? this._controls.courier.visible : false,
            activeCourierVisible: this._controls.activeCourier ? this._controls.activeCourier.visible : false,
            defaultCourierVisible: this._controls.defaultCourier ? this._controls.defaultCourier.visible : false,
            exitReasonVisible: this._controls.exitReason ? this._controls.exitReason.visible : false,
            activeExitReasonVisible: this._controls.activeExitReason ? this._controls.activeExitReason.visible : false,
            defaultExitReasonVisible: this._controls.defaultExitReason ? this._controls.defaultExitReason.visible : false,
            genotypeVisible: this._controls.genotype ? this._controls.genotype.visible : false,
            activeGenotypeVisible: this._controls.activeGenotype ? this._controls.activeGenotype.visible : false,
            defaultGenotypeVisible: this._controls.defaultGenotype ? this._controls.defaultGenotype.visible : false,
            sampleCharacteristicNameVisible: this._controls.sampleCharacteristicName ? this._controls.sampleCharacteristicName.visible : false,
            activeSampleCharacteristicVisible: this._controls.activeSampleCharacteristic ? this._controls.activeSampleCharacteristic.visible : false,
            typeSampleCharacteristicVisible: this._controls.typeSampleCharacteristic ? this._controls.typeSampleCharacteristic.visible : false,
            lastReviewedSampleCharacteristicVisible: this._controls.lastReviewedSampleCharacteristic ? this._controls.lastReviewedSampleCharacteristic.visible : false,
            orderIdVisible: this._controls.orderId ? this._controls.orderId.visible : false,
            orderTypesVisible: this._controls.orderTypes ? this._controls.orderTypes.visible : false,
            lineStatusVisible: this._controls.lineStatus ? this._controls.lineStatus.visible : false,
            activeLineStatusVisible: this._controls.activeLineStatus ? this._controls.activeLineStatus.visible : false,
            defaultLineStatusVisible: this._controls.defaultLineStatus ? this._controls.defaultLineStatus.visible : false,
            observedByVisible: this._controls.observedBy ? this._controls.observedBy.visible : false,
            observedByUsernameVisible: this._controls.observedByUsername ? this._controls.observedByUsername.visible : false,
            observationStatusesVisible: this._controls.observationStatuses ? this._controls.observationStatuses.visible : false,
            orderIdOrdersAnimalsVisible: this._controls.orderIdOrdersAnimals ? this._controls.orderIdOrdersAnimals.visible : false,
            orderIdOrdersLocationsVisible: this._controls.orderIdOrdersLocations ? this._controls.orderIdOrdersLocations.visible : false,
            sampleAnalysisVisible: this._controls.sampleAnalysis ? this._controls.sampleAnalysis.visible : false,
            activeSampleAnalysisVisible: this._controls.activeSampleAnalysis ? this._controls.activeSampleAnalysis.visible : false,
            defaultSampleAnalysisVisible: this._controls.defaultSampleAnalysis ? this._controls.defaultSampleAnalysis.visible : false,
            sampleConditionVisible: this._controls.sampleCondition ? this._controls.sampleCondition.visible : false,
            activeSampleConditionVisible: this._controls.activeSampleCondition ? this._controls.activeSampleCondition.visible : false,
            defaultSampleConditionVisible: this._controls.defaultSampleCondition ? this._controls.defaultSampleCondition.visible : false,
            materialOriginVisible: this._controls.materialOrigin ? this._controls.materialOrigin.visible : false,
            activeMaterialOriginVisible: this._controls.activeMaterialOrigin ? this._controls.activeMaterialOrigin.visible : false,
            defaultMaterialOriginVisible: this._controls.defaultMaterialOrigin ? this._controls.defaultMaterialOrigin.visible : false,
            breedingStatusVisible: this._controls.breedingStatus ? this._controls.breedingStatus.visible : false,
            activeBreedingStatusVisible: this._controls.activeBreedingStatus ? this._controls.activeBreedingStatus.visible : false,
            defaultBreedingStatusVisible: this._controls.defaultBreedingStatus ? this._controls.defaultBreedingStatus.visible : false,
            activeTaskVisible: this._controls.activeTasks ? this._controls.activeTasks.visible : false,
            nameFormatTypeVisible: this._controls.nameFormatTypes ? this._controls.nameFormatTypes.visible : false,
            animalNameVisible: this._controls.animalName ? this._controls.animalName.visible : false,
            animalIdentifierVisible: this._controls.animalIdentifier ? this._controls.animalIdentifier.visible : false,
            assignedToVisible: this._controls.assignedTo ? this._controls.assignedTo.visible : false,
            completedVisible: this._controls.completed ? this._controls.completed.visible : false,
            completedByVisible: this._controls.completedBy ? this._controls.completedBy.visible : false,
            collectedVisible: this._controls.collected ? this._controls.collected.visible : false,
            reviewedVisible: this._controls.reviewed ? this._controls.reviewed.visible : false,
            dietVisible: this._controls.diet ? this._controls.diet.visible : false,
            activeDietVisible: this._controls.activeDiet ? this._controls.activeDiet.visible : false,
            defaultDietVisible: this._controls.defaultDiet ? this._controls.defaultDiet.visible : false,
            generationVisible: this._controls.generation ? this._controls.generation.visible : false,
            activeGenerationVisible: this._controls.activeGeneration ? this._controls.activeGeneration.visible : false,
            defaultGenerationVisible: this._controls.defaultGeneration ? this._controls.defaultGeneration.visible : false,
            locationTypeVisible: this._controls.locationType ? this._controls.locationType.visible : false,
            activeLocationTypeVisible: this._controls.activeLocationType ? this._controls.activeLocationType.visible : false,
            defaultLocationTypeVisible: this._controls.defaultLocationType ? this._controls.defaultLocationType.visible : false,
            facetsVisible: this._controls.facets ? this._controls.facets.visible : false,
            filesVisible: this._controls.files ? this._controls.files.visible : false,
            filesNameVisible: this._controls.filesName ? this._controls.filesName.visible : false,
            constructIdentifierVisible: this._controls.constructIdentifier ? this._controls.constructIdentifier.visible : false,
            urgentClinicalObservationVisible: this._controls.urgentClinicalObservation ? this._controls.urgentClinicalObservation.visible : false,
            observedDateRangeVisible: this._controls.observedDateRange ? this._controls.observedDateRange.visible : false,
            observationsVisible: this._controls.observations ? this._controls.observations.visible : false,
            defaultHealthTechVisible: this._controls.defaultHealthTechnician ? this._controls.defaultHealthTechnician.visible : false,
            inactivityTimeoutVisible: this._controls.inactivityTimeout ? this._controls.inactivityTimeout.visible : false,
            facetTypeVisible: this._controls.facetType ? this._controls.facetType.visible : false,
            facetSettingFieldVisible: this._controls.facetSettingField ? this._controls.facetSettingField.visible : false,
            activeFacetSettingVisible: this._controls.activeFacetSetting ? this._controls.activeFacetSetting.visible : false,
            requiredFacetSettingVisible: this._controls.requiredFacetSetting ? this._controls.requiredFacetSetting.visible : false,
            complianceVisible: this._controls.compliance ? this._controls.compliance.visible : false,
            activeComplianceVisible: this._controls.activeCompliance ? this._controls.activeCompliance.visible : false,
            defaultComplianceVisible: this._controls.defaultCompliance ? this._controls.defaultCompliance.visible : false,
            institutionTypeVisible: this._controls.institutionType ? this._controls.institutionType.visible : false,
            activeInstitutionTypeVisible: this._controls.activeInstitutionType ? this._controls.activeInstitutionType.visible : false,
            defaultInstitutionTypeVisible: this._controls.defaultInstitutionType ? this._controls.defaultInstitutionType.visible : false,
            lineTypeVisible: this._controls.lineType ? this._controls.lineType.visible : false,
            activeLineTypeVisible: this._controls.activeLineType ? this._controls.activeLineType.visible : false,
            defaultLineTypeVisible: this._controls.defaultLineType ? this._controls.defaultLineType.visible : false,
            materialPoolStatusVisible: this._controls.materialPoolStatus ? this._controls.materialPoolStatus.visible : false,
            activeMaterialPoolStatusVisible: this._controls.activeMaterialPoolStatus ? this._controls.activeMaterialPoolStatus.visible : false,
            endStateMaterialPoolStatusVisible: this._controls.endStateMaterialPoolStatus ? this._controls.endStateMaterialPoolStatus.visible : false,
            defaultMaterialPoolStatusVisible: this._controls.defaultMaterialPoolStatus ? this._controls.defaultMaterialPoolStatus.visible : false,
            defaultEndStateMaterialPoolStatusVisible: this._controls.defaultEndStateMaterialPoolStatus ? this._controls.defaultEndStateMaterialPoolStatus.visible : false,
            dateInRangeVisible: this._controls.dateInRange ? this._controls.dateInRange.visible : false,
            dateOutRangeVisible: this._controls.dateOutRange ? this._controls.dateOutRange.visible : false,
            compatibilityAccessLevelVisible: this._controls.compatibilityAccessLevel ? this._controls.compatibilityAccessLevel.visible : false,
            activeCompatibilityAccessLevelVisible: this._controls.activeCompatibilityAccessLevel ? this._controls.activeCompatibilityAccessLevel.visible : false,
            defaultCompatibilityAccessLevelVisible: this._controls.defaultCompatibilityAccessLevel ? this._controls.defaultCompatibilityAccessLevel.visible : false,
            accessLevelsVisible: this._controls.accessLevels ? this._controls.accessLevels.visible : false,
            observationTypeVisible: this._controls.observationType ? this._controls.observationType.visible : false,
            observationSearchVisible: this._controls.observationSearch ? this._controls.observationSearch.visible : false,
            bodySystemVisible: this._controls.bodySystem ? this._controls.bodySystem.visible : false,
            modifier1Visible: this._controls.modifier1 ? this._controls.modifier1.visible : false,
            activeModifier1Visible: this._controls.activeModifier1 ? this._controls.activeModifier1.visible : false,
            modifier2Visible: this._controls.modifier2 ? this._controls.modifier2.visible : false,
            activeModifier2Visible: this._controls.activeModifier2 ? this._controls.activeModifier2.visible : false,
            modifier3Visible: this._controls.modifier3 ? this._controls.modifier3.visible : false,
            activeModifier3Visible: this._controls.activeModifier3 ? this._controls.activeModifier3.visible : false,
            modifier4Visible: this._controls.modifier4 ? this._controls.modifier4.visible : false,
            activeModifier4Visible: this._controls.activeModifier4 ? this._controls.activeModifier4.visible : false,
            socialGroupAccessLevelVisible: this._controls.socialGroupAccessLevel ? this._controls.socialGroupAccessLevel.visible : false,
            activeSocialGroupAccessLevelVisible: this._controls.activeSocialGroupAccessLevel ? this._controls.activeSocialGroupAccessLevel.visible : false,
            defaultSocialGroupAccessLevelVisible: this._controls.defaultSocialGroupAccessLevel ? this._controls.defaultSocialGroupAccessLevel.visible : false,
            containerTypeVisible: this._controls.containerType ? this._controls.containerType.visible : false,
            activeContainerTypeVisible: this._controls.activeContainerType ? this._controls.activeContainerType.visible : false,
            animalContainerTypeVisible: this._controls.animalContainerType ? this._controls.animalContainerType.visible : false,
            defaultContainerTypeVisible: this._controls.defaultContainerType ? this._controls.defaultContainerType.visible : false,
            jobReportVisible: this._controls.jobReport ? this._controls.jobReport.visible : false,
            activeJobReportVisible: this._controls.activeJobReport ? this._controls.activeJobReport.visible : false,
            materialPoolTypeVisible: this._controls.materialPoolType ? this._controls.materialPoolType.visible : false,
            activeMaterialPoolTypesVisible: this._controls.activeMaterialPoolType ? this._controls.activeMaterialPoolType.visible : false,
            defaultMatingMaterialPoolTypesVisible: this._controls.defaultMatingMaterialPoolType ? this._controls.defaultMatingMaterialPoolType.visible : false,
            defaultMaterialPoolTypesVisible: this._controls.defaultMaterialPoolType ? this._controls.defaultMaterialPoolType.visible : false,
            contactPersonTypeVisible: this._controls.contactPersonType ? this._controls.contactPersonType.visible : false,
            activeContactPersonTypeVisible: this._controls.activeContactPersonType ? this._controls.activeContactPersonType.visible : false,
            defaultContactPersonTypeVisible: this._controls.defaultContactPersonType ? this._controls.defaultContactPersonType.visible : false,
            quarantineFacilityVisible: this._controls.quarantineFacility ? this._controls.quarantineFacility.visible : false,
            activeQuarantineFacilityVisible: this._controls.activeQuarantineFacility ? this._controls.activeQuarantineFacility.visible : false,
            defaultQuarantineFacilityVisible: this._controls.defaultQuarantineFacility ? this._controls.defaultQuarantineFacility.visible : false,
            genotypeAssayVisible: this._controls.genotypeAssay ? this._controls.genotypeAssay.visible : false,
            activeGenotypeAssayVisible: this._controls.activeGenotypeAssay ? this._controls.activeGenotypeAssay.visible : false,
            defaultGenotypeAssayVisible: this._controls.defaultGenotypeAssay ? this._controls.defaultGenotypeAssay.visible : false,
            siteTypeVisible: this._controls.siteType ? this._controls.siteType.visible : false,
            activeSiteTypeVisible: this._controls.activeSiteType ? this._controls.activeSiteType.visible : false,
            defaultSiteTypeVisible: this._controls.defaultSiteType ? this._controls.defaultSiteType.visible : false,
            outputNamesVisible: this._controls.outputNames ? this._controls.outputNames.visible : false,
            flagMessagesVisible: this._controls.flagMessages ? this._controls.flagMessages.visible : false,
            unitVisible: this._controls.unit ? this._controls.unit.visible : false,
            activeUnitVisible: this._controls.activeUnit ? this._controls.activeUnit.visible : false,
            defaultUnitVisible: this._controls.defaultUnit ? this._controls.defaultUnit.visible : false,
            inputNameVisible: this._controls.inputName ? this._controls.inputName.visible : false,
            defaultValueVisible: this._controls.defaultValue ? this._controls.defaultValue.visible : false,
            activeInputVisible: this._controls.activeInput ? this._controls.activeInput.visible : false,
            requiredInputVisible: this._controls.requiredInput ? this._controls.requiredInput.visible : false,
            outputNameVisible: this._controls.outputName ? this._controls.outputName.visible : false,
            activeOutputVisible: this._controls.activeOutput ? this._controls.activeOutput.visible : false,
            dataTypeVisible: this._controls.dataType ? this._controls.dataType.visible : false,
            routeOfAdministrationVisible: this._controls.routeOfAdministration ? this._controls.routeOfAdministration.visible : false,
            activeRouteOfAdministrationVisible: this._controls.activeRouteOfAdministration ? this._controls.activeRouteOfAdministration.visible : false,
            defaultRouteOfAdministrationVisible: this._controls.defaultRouteOfAdministration ? this._controls.defaultRouteOfAdministration.visible : false,
            veterinaryRequestStatusVisible: this._controls.veterinaryRequestStatus ? this._controls.veterinaryRequestStatus.visible : false,
            activeVeterinaryRequestStatusVisible: this._controls.activeVeterinaryRequestStatus ? this._controls.activeVeterinaryRequestStatus.visible : false,
            endStateVeterinaryRequestStatusVisible: this._controls.endStateVeterinaryRequestStatus ? this._controls.endStateVeterinaryRequestStatus.visible : false,
            defaultVeterinaryRequestStatusVisible: this._controls.defaultVeterinaryRequestStatus ? this._controls.defaultVeterinaryRequestStatus.visible : false,
            studyNameVisible: this._controls.studyName ? this._controls.studyName.visible : false,
            studyTypeVisible: this._controls.studyType ? this._controls.studyType.visible : false,
            characteristicNameVisible: this._controls.characteristicName ? this._controls.characteristicName.visible : false,
            characteristicValueVisible: this._controls.characteristicValue ? this._controls.characteristicValue.visible : false,
            shipmentContainerConditionVisible: this._visibleOrFalse(this._controls.shipmentContainerCondition),
            defaultShipmentContainerConditionVisible: this._visibleOrFalse(this._controls.defaultShipmentContainerCondition),
            activeShipmentContainerConditionVisible: this._visibleOrFalse(this._controls.activeShipmentContainerCondition)
        };
    }

    get filters(): Filter[] {
        return this._filters;
    }

    ngOnInit() {
        this.initIsGLP();
    }


    initIsGLP() {
        this.isGLP = this.featureFlagService.getIsGLP();
    }

    setSubsectionVisible(subsection: Subsection): void {
        this.loadBooleanValues();

        if (subsection === 'Animals') {
            this._controls.animals = { visible: true, order: 1, template: this.animalsTmpl };
            this._controls.animalIdentifiers = { visible: true, order: 2, template: this.animalIdentifiersTmpl };
            this._controls.microchipIdentifiers = { visible: true, order: 3, template: this.microchipIdentifiersTmpl };
        }

        if (subsection === 'Animal Tasks') {
            this._controls.animalIdentifiers = { visible: true, order: 1, template: this.animalIdentifiersTmpl };
            this._controls.taskNames = { visible: true, order: 2, template: this.taskNamesTmpl };
            this._controls.dueDateRange = { visible: true, order: 3, template: this.dueDateRangeTmpl };
        }

        if (subsection === 'Animal Comments') {
            this._controls.animalCommentAnimalIDs = { visible: true, order: 1, template: this.animalCommentAnimalIDsTmpl };
            this._controls.animalCommentAnimalNames = { visible: true, order: 2, template: this.animalCommentAnimalNamesTmpl };
            this._controls.animalComments = { visible: true, order: 3, template: this.animalCommentsTmpl };
            this._controls.animalCommentStatuses = { visible: true, order: 4, template: this.animalCommentStatusesTmpl };
        }

        if (subsection === 'Samples') {
            this._controls.samples = { visible: true, order: 1, template: this.samplesTmpl };
            this._controls.sampleIdentifiers = { visible: true, order: 2, template: this.sampleIdentifiersTmpl };
            this._controls.sourceMaterials = { visible: true, order: 3, template: this.sourceMaterialsTmpl };
            this._controls.sourceIdentifiers = { visible: true, order: 4, template: this.sourceIdentifiersTmpl };
        }

        if (subsection === 'Jobs') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.studies = { visible: true, order: 2, template: this.studiesTmpl };
            this._controls.jobStatuses = { visible: true, order: 3, template: this.jobStatusesTmpl };
            this._controls.jobTypes = { visible: true, order: 4, template: this.jobTypesTmpl };
            if (this.featureFlagService.getIsCRO()){
                this._controls.jobSubtypes = { visible: true, order: 5, template: this.jobSubtypesTmpl };
            }
        }

        if (subsection === 'Job Animals') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.animalIdentifiers = { visible: true, order: 2, template: this.animalIdentifiersTmpl };
            this._controls.animals = { visible: true, order: 3, template: this.animalsTmpl };
        }

        if (subsection === 'Job Samples') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.samples = { visible: true, order: 2, template: this.samplesTmpl };
            this._controls.sampleIdentifiers = { visible: true, order: 3, template: this.sampleIdentifiersTmpl };
        }

        if (subsection === 'Job Sample Groups') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 2, template: this.workflowTaskKeysTmpl };
        }

        if (subsection === 'Job Tasks') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.studies = { visible: true, order: 2, template: this.studiesTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 3, template: this.workflowTaskKeysTmpl };
            this._controls.taskNames = { visible: true, order: 4, template: this.taskNamesTmpl };
            this._controls.taskStatuses = { visible: true, order: 5, template: this.taskStatusesTmpl };
            this._controls.dueDateRange = { visible: true, order: 6, template: this.dueDateRangeTmpl };
        }

        if (subsection === 'Job Lines') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.lines = { visible: true, order: 2, template: this.linesTmpl };
            this._controls.taxons = { visible: true, order: 3, template: this.taxonsTmpl };
        }

        if (subsection === 'Job Line Details') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.lines = { visible: true, order: 2, template: this.linesTmpl };
        }

        if (subsection === 'Job Standard Phrases') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.standardPhrases = { visible: true, order: 2, template: this.standardPhrasesTmpl };
        }

        if (subsection === 'Job Variable Phrases') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.variablePhraseTypes = { visible: true, order: 2, template: this.variablePhraseTypesTmpl };
        }

        if (subsection === 'Job Groups') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.groups = { visible: true, order: 2, template: this.groupsTmpl };
        }

        if (subsection === 'Job Test Articles') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.testArticles = { visible: true, order: 2, template: this.testArticlesTmpl };
        }

        if (subsection === 'Job Characteristics') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.jobCharacteristics = { visible: true, order: 2, template: this.jobCharacteristicsTmpl };
        }

        if (subsection === 'Jobs Institutions') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.institutions = { visible: true, order: 2, template: this.institutionsTmpl };
        }

        if (subsection === 'Job Orders') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.studies = { visible: true, order: 2, template: this.studiesTmpl };
            this._controls.orderId = { visible: true, order: 3, template: this.orderIdTmpl };
        }

        if (subsection === 'Job Individual Placeholders') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.animalPlaceholders = { visible: true, order: 2, template: this.animalPlaceholdersTmpl };
            this._controls.animals = { visible: true, order: 3, template: this.animalsTmpl };
        }

        if (subsection === 'Job Group Placeholders') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.groupPlaceholders = { visible: true, order: 2, template: this.groupPlaceholdersTmpl };
            this._controls.cohorts = { visible: true, order: 3, template: this.cohortsTmpl };
        }

        if (subsection === 'Workflow Inputs') {
            this._controls.taskTypes = { visible: true, order: 1, template: this.taskTypesTmpl };
            this._controls.taskNames = { visible: true, order: 2, template: this.taskNamesTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 3, template: this.workflowTaskKeysTmpl };
            this._controls.jobs = { visible: true, order: 4, template: this.jobsTmpl };
            this._controls.studies = { visible: true, order: 5, template: this.studiesTmpl };
            this._controls.animals = { visible: true, order: 6, template: this.animalsTmpl };
            this._controls.samples = { visible: true, order: 7, template: this.samplesTmpl };
        }

        if (subsection === 'Workflow Outputs') {
            this._controls.taskTypes = { visible: true, order: 1, template: this.taskTypesTmpl };
            this._controls.taskNames = { visible: true, order: 2, template: this.taskNamesTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 3, template: this.workflowTaskKeysTmpl };
            this._controls.jobs = { visible: true, order: 4, template: this.jobsTmpl };
            this._controls.studies = { visible: true, order: 5, template: this.studiesTmpl };
            this._controls.animals = { visible: true, order: 6, template: this.animalsTmpl };
            this._controls.samples = { visible: true, order: 7, template: this.samplesTmpl };
        }

        if (subsection === 'Housing') {
            this._controls.housings = { visible: true, order: 1, template: this.housingsTmpl };
            this._controls.materialPoolTypes = { visible: true, order: 2, template: this.materialPoolTypesTmpl };
            this._controls.materialPoolStatuses = { visible: true, order: 3, template: this.materialPoolStatusesTmpl };
            this._controls.housingDateRange = { visible: true, order: 4, template: this.housingDateRangeTmpl };
        }

        if (subsection === 'Housing Animals') {
            this._controls.housings = { visible: true, order: 1, template: this.housingsTmpl };
            this._controls.materialPoolTypes = { visible: true, order: 2, template: this.materialPoolTypesTmpl };
            this._controls.materialPoolStatuses = { visible: true, order: 3, template: this.materialPoolStatusesTmpl };
            this._controls.animalIdentifiers = { visible: true, order: 4, template: this.animalIdentifiersTmpl };
            this._controls.animals = { visible: true, order: 5, template: this.animalsTmpl };
            this._controls.animalStatuses = { visible: true, order: 6, template: this.animalStatusesTmpl };
            this._controls.housingDateRange = { visible: true, order: 7, template: this.housingDateRangeTmpl };
        }

        if (subsection === 'Housing Tasks') {
            this._controls.housings = { visible: true, order: 1, template: this.housingsTmpl };
            this._controls.taskNames = { visible: true, order: 2, template: this.taskNamesTmpl };
            this._controls.dueDateRange = { visible: true, order: 3, template: this.dueDateRangeTmpl };
        }

        if (subsection === 'Housing Locations') {
            this._controls.housings = { visible: true, order: 1, template: this.housingsTmpl };
            this._controls.locations = { visible: true, order: 2, template: this.locationsTmpl };
            this._controls.dateInRange = { visible: true, order: 3, template: this.dateInRangeTmpl };
            this._controls.dateOutRange = { visible: true, order: 4, template: this.dateOutRangeTmpl };
        }

        if (subsection === 'Housing Social Statutes') {
            this._controls.animals = { visible: true, order: 1, template: this.animalsTmpl };
            this._controls.sexes = { visible: true, order: 2, template: this.sexesTmpl };
            this._controls.housings = { visible: true, order: 3, template: this.housingsTmpl };
            this._controls.accessLevels = { visible: true, order: 4, template: this.accessLevelsTmpl };
            this._controls.dateInRange = { visible: true, order: 5, template: this.dateInRangeTmpl };
            this._controls.dateOutRange = { visible: true, order: 6, template: this.dateOutRangeTmpl };
        }

        if (subsection === 'Matings') {
            this._controls.matings = { visible: true, order: 1, template: this.matingsTmpl };
            this._controls.housings = { visible: true, order: 2, template: this.housingsTmpl };
            this._controls.matingStatuses = { visible: true, order: 3, template: this.matingStatusesTmpl };
            this._controls.matingTypes = { visible: true, order: 4, template: this.matingTypesTmpl };
            this._controls.matingDateRange = { visible: true, order: 5, template: this.matingDateRangeTmpl };
        }

        if (subsection === 'Mating Materials') {
            this._controls.matings = { visible: true, order: 1, template: this.matingsTmpl };
            this._controls.housings = { visible: true, order: 2, template: this.housingsTmpl };
            this._controls.animalMatingStatuses = { visible: true, order: 3, template: this.animalMatingStatusesTmpl };
            this._controls.matingTypes = { visible: true, order: 4, template: this.matingTypesTmpl };
            this._controls.animalIdentifiers = { visible: true, order: 5, template: this.animalIdentifiersTmpl };
            this._controls.animals = { visible: true, order: 6, template: this.animalsTmpl };
            this._controls.matingDateRange = { visible: true, order: 7, template: this.matingDateRangeTmpl };
        }

        if (subsection === 'Cohorts') {
            this._controls.cohorts = { visible: true, order: 1, template: this.cohortsTmpl };
        }

        if (subsection === 'Cohort Animals') {
            this._controls.cohorts = { visible: true, order: 1, template: this.cohortsTmpl };
            this._controls.animalIdentifiers = { visible: true, order: 2, template: this.animalIdentifiersTmpl };
            this._controls.animals = { visible: true, order: 3, template: this.animalsTmpl };
            this._controls.animalStatuses = { visible: true, order: 4, template: this.animalStatusesTmpl };
        }

        if (subsection === 'Lines') {
            this._controls.lines = { visible: true, order: 1, template: this.linesTmpl };
            this._controls.lineShortNames = { visible: true, order: 2, template: this.lineShortNamesTmpl };
            this._controls.taxons = { visible: true, order: 3, template: this.taxonsTmpl };
        }

        if (subsection === 'Studies') {
            this._controls.studies = { visible: true, order: 1, template: this.studiesTmpl };
            this._controls.studyTypes = { visible: true, order: 2, template: this.studyTypesTmpl };
            this._controls.studyStatuses = { visible: true, order: 3, template: this.studyStatusesTmpl };
            this._controls.approvalNumbers = { visible: true, order: 4, template: this.approvalNumbersTmpl };
        }

        if (subsection === 'Study Lines') {
            this._controls.studies = { visible: true, order: 1, template: this.studiesTmpl };
            this._controls.lines = { visible: true, order: 2, template: this.linesTmpl };
        }

        if (subsection === 'Births') {
            this._controls.births = { visible: true, order: 1, template: this.birthsTmpl };
            this._controls.matings = { visible: true, order: 2, template: this.matingsTmpl };
            this._controls.housings = { visible: true, order: 3, template: this.housingsTmpl };
            this._controls.birthStatuses = { visible: true, order: 4, template: this.birthStatusesTmpl };
        }

        if (subsection === 'Locations') {
            this._controls.locations = { visible: true, order: 1, template: this.locationsTmpl };
            this._controls.locationTypes = { visible: true, order: 2, template: this.locationTypesTmpl };
            this._controls.parentLocations = { visible: true, order: 3, template: this.parentLocationsTmpl };
        }

        if (subsection === 'Clinical Observations') {
            this._controls.animalIdentifiers = { visible: true, order: 1, template: this.animalIdentifiersTmpl };
            this._controls.animals = { visible: true, order: 2, template: this.animalsTmpl };
            this._controls.observedBy = { visible: true, order: 3, template: this.observedByTmpl };
            this._controls.observationStatuses = { visible: true, order: 4, template: this.observationStatusesTmpl };
        }

        if (subsection === 'Clinical') {
            this._controls.animalIdentifiers = { visible: true, order: 1, template: this.animalIdentifiersTmpl };
            this._controls.animals = { visible: true, order: 2, template: this.animalsTmpl };
        }

        if (subsection === 'Clinical Tasks') {
            this._controls.animalName = { visible: true, order: 1, template: this.animalNameTmpl };
            this._controls.animalIdentifier = { visible: true, order: 2, template: this.animalIdentifierTmpl };
            this._controls.assignedTo = { visible: true, order: 3, template: this.assignedToTmpl };
            this._controls.completed = { visible: true, order: 4, template: this.completedTmpl };
            this._controls.completedBy = { visible: true, order: 5, template: this.completedByTmpl };
            this._controls.taskStatuses = { visible: true, order: 6, template: this.taskStatusesTmpl, context: { label: 'Status' } };
        }

        if (subsection === 'Clinical Observation Details') {
            if (this.isGLP) {
                this._controls.urgentClinicalObservation = { visible: true, order: 1, template: this.urgentClinicalObservationTmpl };
                this._controls.animalIdentifiers = { visible: true, order: 2, template: this.animalIdentifiersTmpl };
                this._controls.animals = { visible: true, order: 3, template: this.animalsTmpl };
                this._controls.observationType = { visible: true, order: 4, template: this.observationTypeTmpl };
                this._controls.observedDateRange = { visible: true, order: 5, template: this.observedDateRangeTmpl };
                this._controls.observedByUsername = { visible: true, order: 6, template: this.observedByUsernameTmpl };
                this._controls.observationStatuses = { visible: true, order: 7, template: this.observationStatusesTmpl };
                this._controls.observationSearch = { visible: true, order: 8, template: this.observationSearchTmpl };                
            } else {
                this._controls.urgentClinicalObservation = { visible: true, order: 1, template: this.urgentClinicalObservationTmpl };
                this._controls.animalIdentifiers = { visible: true, order: 2, template: this.animalIdentifiersTmpl };
                this._controls.animals = { visible: true, order: 3, template: this.animalsTmpl };
                this._controls.observedDateRange = { visible: true, order: 4, template: this.observedDateRangeTmpl };
                this._controls.observedBy = { visible: true, order: 5, template: this.observedByTmpl };
                this._controls.observationStatuses = { visible: true, order: 6, template: this.observationStatusesTmpl };
                this._controls.observations = { visible: true, order: 7, template: this.observationsTmpl };
                this._controls.experimentalClinicalObservation = { visible: true, order: 8, template: this.experimentalClinicalObservationTmpl };
            }
        }

        if (subsection === 'Tasks') {
            this._controls.workflowTaskKeys = { visible: true, order: 1, template: this.workflowTaskKeysTmpl, context: { label: 'Name' } };
            this._controls.taskTypes = { visible: true, order: 2, template: this.taskTypesTmpl };
            this._controls.activeTasks = { visible: true, order: 3, template: this.activeTaskTmpl };
        }

        if (subsection === 'Task Assignments') {
            this._controls.taskTypes = { visible: true, order: 1, template: this.taskTypesTmpl };
            this._controls.taskNames = { visible: true, order: 2, template: this.taskNamesTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 3, template: this.workflowTaskKeysTmpl };
            this._controls.jobs = { visible: true, order: 4, template: this.jobsTmpl };
            this._controls.animals = { visible: true, order: 5, template: this.animalsTmpl };
            this._controls.sampleIdentifiers = { visible: true, order: 6, template: this.sampleIdentifiersTmpl };
            this._controls.assignedTo = { visible: true, order: 7, template: this.assignedToTmpl };
        }

        if (subsection === 'Task Output Flags') {
            this._controls.taskTypes = { visible: true, order: 1, template: this.taskTypesTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 2, template: this.workflowTaskKeysTmpl };
            this._controls.outputNames = { visible: true, order: 3, template: this.outputNamesTmpl };
            this._controls.flagMessages = { visible: true, order: 4, template: this.flagMessagesTmpl };
        }

        if (subsection === 'Workflow Task Details') {
            this._controls.taskNames = { visible: true, order: 1, template: this.taskNamesTmpl };
            this._controls.collected = { visible: true, order: 2, template: this.collectedTmpl };
            this._controls.completed = { visible: true, order: 3, template: this.completedTmpl };
            this._controls.reviewed = { visible: true, order: 4, template: this.reviewedTmpl };
        }

        if (subsection === 'Files') {
            this._controls.facets = { visible: true, order: 1, template: this.facetTmpl };
            this._controls.filesName = { visible: true, order: 2, template: this.filesNameTmpl };
            this._controls.animalIdentifiers = { visible: true, order: 3, template: this.animalIdentifiersTmpl };
            this._controls.taskNames = { visible: true, order: 4, template: this.taskNamesTmpl };
            this._controls.protocols = { visible: true, order: 5, template: this.protocolsTmpl };
            this._controls.sampleIdentifiers = { visible: true, order: 6, template: this.sampleIdentifiersTmpl };
            this._controls.studies = { visible: true, order: 7, template: this.studiesTmpl };
            this._controls.lines = { visible: true, order: 8, template: this.linesTmpl };
            this._controls.constructIdentifier = { visible: true, order: 9, template: this.constructIdentifierTmpl };
            this._controls.orderId = { visible: true, order: 10, template: this.orderIdTmpl };
            this._controls.jobs = { visible: true, order: 11, template: this.jobsTmpl };
        }

        if (subsection === 'Task Inputs') {
            this._controls.inputName = { visible: true, order: 1, template: this.inputNameTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 2, template: this.workflowTaskKeysTmpl };
            this._controls.activeInput = { visible: true, order: 3, template: this.activeInputTmpl };
            this._controls.requiredInput = { visible: true, order: 4, template: this.requiredInputTmpl };
            this._controls.dataType = { visible: true, order: 5, template: this.dataTypeTmpl };
        }

        if (subsection === 'Task Outputs') {
            this._controls.outputName = { visible: true, order: 1, template: this.outputNameTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 2, template: this.workflowTaskKeysTmpl };
            this._controls.activeOutput = { visible: true, order: 3, template: this.activeOutputTmpl };
            this._controls.dataType = { visible: true, order: 4, template: this.dataTypeTmpl };
        }

        if (subsection === 'Orders') {
            this._controls.orderId = { visible: true, order: 1, template: this.orderIdTmpl };
            if (this.isGLP) {
                this._controls.jobs = { visible: true, order: 2, template: this.jobsTmpl };
            } else {
                this._controls.orderTypes = { visible: true, order: 2, template: this.orderTypesTmpl };
                this._controls.jobs = { visible: true, order: 3, template: this.jobsTmpl };
            }
        }

        if (subsection === 'Order Animals') {
            this._controls.orderIdOrdersAnimals = { visible: true, order: 1, template: this.orderIdOrdersAnimalsTmpl };
        }

        if (subsection === 'Order Locations') {
            this._controls.orderIdOrdersLocations = { visible: true, order: 1, template: this.orderIdOrdersLocationsTmpl };
        }

        if (subsection === 'Protocols') {
            this._controls.protocols = { visible: true, order: 1, template: this.protocolsTmpl };
        }

        if (subsection === 'Protocol Default Inputs') {
            this._controls.protocols = { visible: true, order: 1, template: this.protocolsTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 2, template: this.workflowTaskKeysTmpl };
            this._controls.protocolTaskNames = { visible: true, order: 3, template: this.protocolTaskNamesTmpl };
            this._controls.inputName = { visible: true, order: 4, template: this.inputNameTmpl };
            this._controls.defaultValue = { visible: true, order: 5, template: this.defaultValueTmpl };
        }

        if (subsection === 'Protocol Tasks') {
            this._controls.protocols = { visible: true, order: 1, template: this.protocolsTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 2, template: this.workflowTaskKeysTmpl };
        }

        if (subsection === 'Protocol Sample Groups') {
            this._controls.protocols = { visible: true, order: 1, template: this.protocolsTmpl };
            this._controls.workflowTaskKeys = { visible: true, order: 2, template: this.workflowTaskKeysTmpl, context: { label: 'Task Name' } };
        }

        if (subsection === 'Resources') {
            this._controls.resourceName = { visible: true, order: 1, template: this.resourceNameTmpl };
            this._controls.activeResource = { visible: true, order: 2, template: this.activeResourceTmpl };
            this._controls.emailAddress = { visible: true, order: 3, template: this.emailAddressTmpl };
            this._controls.resourceTypes = { visible: true, order: 4, template: this.resourceTypesTmpl };
            this._controls.usernames = { visible: true, order: 5, template: this.usernamesTmpl };
            this._controls.resourceGroups = { visible: true, order: 6, template: this.resourceGroupsTmpl };
        }

        if (subsection === 'Study Administrator Studies') {
            this._controls.users = { visible: true, order: 1, template: this.usersTmpl };
            this._controls.studies = { visible: true, order: 2, template: this.studiesTmpl };
        }

        if (subsection === 'User Roles') {
            this._controls.roleName = { visible: true, order: 1, template: this.roleNameTmpl };
        }

        if (subsection === 'Users') {
            this._controls.userName = { visible: true, order: 1, template: this.userNameTmpl };
            this._controls.firstName = { visible: true, order: 2, template: this.firstNameTmpl };
            this._controls.lastName = { visible: true, order: 3, template: this.lastNameTmpl };
        }

        if (subsection === 'User Details') {
            this._controls.userName = { visible: true, order: 1, template: this.userNameTmpl };
            this._controls.roleName = { visible: true, order: 2, template: this.roleNameTmpl };
            this._controls.isStudyAdministrator = { visible: true, order: 3, template: this.isStudyAdministratorTmpl };
        }

        if (subsection === 'Role Facets') {
            this._controls.facetName = { visible: true, order: 1, template: this.facetNameTmpl };
            this._controls.roleName = { visible: true, order: 2, template: this.roleNameTmpl };
            this._controls.hasReadAccess = { visible: true, order: 2, template: this.hasReadAccessTmpl };
            this._controls.hasWriteAccess = { visible: true, order: 2, template: this.hasWriteAccessTmpl };
        }

        if (subsection === 'Vocabularies: Task Flag Messages') {
            this._controls.taskFlagMessage = { visible: true, order: 1, template: this.taskFlagMessageTmpl };
            this._controls.activeTaskFlagMessage = { visible: true, order: 2, template: this.activeTaskFlagMessageTmpl };
        }

        if (subsection === 'Vocabularies: Animal Classifications') {
            this._controls.animalClassification = { visible: true, order: 1, template: this.animalClassificationTmpl };
            this._controls.activeAnimalClassification = { visible: true, order: 2, template: this.activeAnimalClassificationTmpl };
            this._controls.defaultAnimalClassification = { visible: true, order: 3, template: this.defaultAnimalClassificationTmpl };
        }

        if (subsection === 'Vocabularies: Animal Comment Statuses') {
            this._controls.animalCommentStatus = { visible: true, order: 1, template: this.animalCommentStatusTmpl };
            this._controls.activeAnimalCommentStatus = { visible: true, order: 2, template: this.activeAnimalCommentStatusTmpl };
            this._controls.endStateAnimalCommentStatus = { visible: true, order: 3, template: this.endStateAnimalCommentStatusTmpl };
            this._controls.defaultAnimalCommentStatus = { visible: true, order: 4, template: this.defaultAnimalCommentStatusTmpl };
            this._controls.permanentAnimalCommentStatus = { visible: true, order: 5, template: this.permanentAnimalCommentStatusTmpl };
        }

        if (subsection === 'Vocabularies: Animal Statuses') {
            this._controls.animalStatus = { visible: true, order: 1, template: this.animalStatusTmpl };
            this._controls.activeAnimalStatus = { visible: true, order: 2, template: this.activeAnimalStatusTmpl };
            this._controls.endStateAnimalStatus = { visible: true, order: 3, template: this.endStateAnimalStatusTmpl };
            this._controls.defaultAnimalStatus = { visible: true, order: 4, template: this.defaultAnimalStatusTmpl };
            this._controls.defaultClinicalStatusAnimalStatus = { visible: true, order: 5, template: this.defaultClinicalStatusAnimalStatusTmpl };
        }

        if (subsection === 'Vocabularies: Test Articles') {
            this._controls.testArticle = { visible: true, order: 1, template: this.testArticleTmpl };
            this._controls.activeTestArticle = { visible: true, order: 2, template: this.activeTestArticleTmpl };
            this._controls.defaultTestArticle = { visible: true, order: 3, template: this.defaultTestArticleTmpl };
        }

        if (subsection === 'Vocabularies: Job Subtypes') {
            this._controls.jobSubtype = { visible: true, order: 1, template: this.jobSubtypeTmpl };
            this._controls.activeJobSubtype = { visible: true, order: 2, template: this.activeJobSubtypeTmpl };
            this._controls.defaultJobSubtype = { visible: true, order: 3, template: this.defaultJobSubtypeTmpl };
        }

        if (subsection === 'Vocabularies: Task Outcomes') {
            this._controls.taskOutcome = { visible: true, order: 1, template: this.taskOutcomeTmpl };
            this._controls.activeTaskOutcome = { visible: true, order: 2, template: this.activeTaskOutcomeTmpl };
            this._controls.defaultTaskOutcome = { visible: true, order: 3, template: this.defaultTaskOutcomeTmpl };
        }

        if (subsection === 'Vocabularies: Line Statuses') {
            this._controls.lineStatus = { visible: true, order: 1, template: this.lineStatusTmpl };
            this._controls.activeLineStatus = { visible: true, order: 2, template: this.activeLineStatusTmpl };
            this._controls.defaultLineStatus = { visible: true, order: 3, template: this.defaultLineStatusTmpl };
        }

        if (subsection === 'Characteristics: Animals') {
            this._controls.animalCharacteristicName = { visible: true, order: 1, template: this.animalCharacteristicNameTmpl };
            this._controls.activeAnimalCharacteristic = { visible: true, order: 2, template: this.activeAnimalCharacteristicTmpl };
            this._controls.speciesAnimalCharacteristic = { visible: true, order: 3, template: this.speciesAnimalCharacteristicTmpl };
            this._controls.lastReviewedAnimalCharacteristic = { visible: true, order: 4, template: this.lastReviewedAnimalCharacteristicTmpl };
        }

        if (subsection === 'Characteristics: Jobs') {
            this._controls.jobCharacteristicName = { visible: true, order: 1, template: this.jobCharacteristicNameTmpl };
            this._controls.activeJobCharacteristic = { visible: true, order: 2, template: this.activeJobCharacteristicTmpl };
            this._controls.linkTypeJobCharacteristic = { visible: true, order: 3, template: this.linkTypeJobCharacteristicTmpl };
            this._controls.lastReviewedJobCharacteristic = { visible: true, order: 4, template: this.lastReviewedJobCharacteristicTmpl };
        }

        if (subsection === 'Characteristics: Studies') {
            this._controls.studyCharacteristicName = { visible: true, order: 1, template: this.studyCharacteristicNameTmpl };
            this._controls.activeStudyCharacteristic = { visible: true, order: 2, template: this.activeStudyCharacteristicTmpl };
            this._controls.typeStudyCharacteristic = { visible: true, order: 3, template: this.typeStudyCharacteristicTmpl };
            this._controls.lastReviewedStudyCharacteristic = { visible: true, order: 4, template: this.lastReviewedStudyCharacteristicTmpl };
        }

        if (subsection === 'Vocabularies: Job Statuses') {
            this._controls.jobStatus = { visible: true, order: 1, template: this.jobStatusTmpl };
            this._controls.activeJobStatus = { visible: true, order: 2, template: this.activeJobStatusTmpl };
            this._controls.endStateJobStatus = { visible: true, order: 3, template: this.endStateJobStatusTmpl };
            this._controls.defaultJobStatus = { visible: true, order: 5, template: this.defaultJobStatusTmpl };
        }

        if (subsection === 'Vocabularies: Animal Uses') {
            this._controls.animalUse = { visible: true, order: 1, template: this.animalUseTmpl };
            this._controls.activeAnimalUse = { visible: true, order: 2, template: this.activeAnimalUseTmpl };
            this._controls.defaultAnimalUse = { visible: true, order: 3, template: this.defaultAnimalUseTmpl };
        }

        if (subsection === 'Characteristics: Animals') {
            this._controls.animalCharacteristicName = { visible: true, order: 1, template: this.animalCharacteristicNameTmpl };
            this._controls.activeAnimalCharacteristic = { visible: true, order: 2, template: this.activeAnimalCharacteristicTmpl };
            this._controls.speciesAnimalCharacteristic = { visible: true, order: 3, template: this.speciesAnimalCharacteristicTmpl };
            this._controls.lastReviewedAnimalCharacteristic = { visible: true, order: 4, template: this.lastReviewedAnimalCharacteristicTmpl };
        }

        if (subsection === 'Characteristics: Samples') {
            this._controls.sampleCharacteristicName = { visible: true, order: 1, template: this.sampleCharacteristicNameTmpl };
            this._controls.activeSampleCharacteristic = { visible: true, order: 2, template: this.activeSampleCharacteristicTmpl };
            this._controls.typeSampleCharacteristic = { visible: true, order: 3, template: this.typeSampleCharacteristicTmpl };
            this._controls.lastReviewedSampleCharacteristic = { visible: true, order: 4, template: this.lastReviewedSampleCharacteristicTmpl };
        }

        if (subsection === 'Vocabularies: Job Types') {
            this._controls.jobType = { visible: true, order: 1, template: this.jobTypeTmpl };
            this._controls.activeJobType = { visible: true, order: 2, template: this.activeJobTypeTmpl };
            this._controls.defaultJobType = { visible: true, order: 3, template: this.defaultJobTypeTmpl };
        }

        if (subsection === 'Vocabularies: Marker Types') {
            this._controls.physicalMarkerType = { visible: true, order: 1, template: this.physicalMarkerTypeTmpl };
            this._controls.activePhysicalMarkerType = { visible: true, order: 2, template: this.activePhysicalMarkerTypeTmpl };
            this._controls.defaultPhysicalMarkerType = { visible: true, order: 3, template: this.defaultPhysicalMarkerTypeTmpl };
        }

        if (subsection === 'Vocabularies: Sample Analyses') {
            this._controls.sampleAnalysis = { visible: true, order: 1, template: this.sampleAnalysisTmpl };
            this._controls.activeSampleAnalysis = { visible: true, order: 2, template: this.activeSampleAnalysisTmpl };
            this._controls.defaultSampleAnalysis = { visible: true, order: 3, template: this.defaultSampleAnalysisTmpl };
        }

        if (subsection === 'Vocabularies: Sample Conditions') {
            this._controls.sampleCondition = { visible: true, order: 1, template: this.sampleConditionTmpl };
            this._controls.activeSampleCondition = { visible: true, order: 2, template: this.activeSampleConditionTmpl };
            this._controls.defaultSampleCondition = { visible: true, order: 3, template: this.defaultSampleConditionTmpl };
        }

        if (subsection === 'Vocabularies: Plate Statuses') {
            this._controls.plateStatus = { visible: true, order: 1, template: this.plateStatusTmpl };
            this._controls.activePlateStatus = { visible: true, order: 2, template: this.activePlateStatusTmpl };
            this._controls.defaultPlateStatus = { visible: true, order: 3, template: this.defaultPlateStatusTmpl };
        }

        if (subsection === 'Vocabularies: Animal/Sample Origins') {
            this._controls.materialOrigin = { visible: true, order: 1, template: this.materialOriginTmpl };
            this._controls.activeMaterialOrigin = { visible: true, order: 2, template: this.activeMaterialOriginTmpl };
            this._controls.defaultMaterialOrigin = { visible: true, order: 3, template: this.defaultMaterialOriginTmpl };
        }
        if (subsection === 'Vocabularies: Breeding Statuses') {
            this._controls.breedingStatus = { visible: true, order: 1, template: this.breedingStatusTmpl };
            this._controls.activeBreedingStatus = { visible: true, order: 2, template: this.activeBreedingStatusTmpl };
            this._controls.defaultBreedingStatus = { visible: true, order: 3, template: this.defaultBreedingStatusTmpl };
        }

        if (subsection === 'Settings: Naming') {
            this._controls.nameFormatTypes = { visible: true, order: 1, template: this.nameFormatTypeTmpl };
        }

        if (subsection === 'Settings: Facets') {
            this._controls.facetType = { visible: true, order: 1, template: this.facetTypeTmpl };
            this._controls.facetSettingField = { visible: true, order: 2, template: this.facetSettingFieldTmpl };
            this._controls.activeFacetSetting = { visible: true, order: 3, template: this.activeFacetSettingTmpl };
            this._controls.requiredFacetSetting = { visible: true, order: 4, template: this.requiredFacetSettingTmpl };
        }

        if (subsection === 'Vocabularies: Birth Statuses') {
            this._controls.birthStatus = { visible: true, order: 1, template: this.birthStatusTmpl };
            this._controls.activeBirthStatus = { visible: true, order: 2, template: this.activeBirthStatusTmpl };
            this._controls.defaultBirthStatus = { visible: true, order: 3, template: this.defaultBirthStatusTmpl };
        }

        if (subsection === 'Vocabularies: Shipment Animal Conditions') {
            this._controls.shipmentAnimalCondition = { visible: true, order: 1, template: this.shipmentAnimalConditionTmpl };
            this._controls.activeShipmentAnimalCondition = { visible: true, order: 2, template: this.activeShipmentAnimalConditionTmpl };
            this._controls.defaultShipmentAnimalCondition = { visible: true, order: 3, template: this.defaultShipmentAnimalConditionTmpl };
        }

        if (subsection === 'Vocabularies: Clinical Observation Statuses') {
            this._controls.clinicalObservationStatus = { visible: true, order: 1, template: this.clinicalObservationStatusTmpl };
            this._controls.activeClinicalObservationStatus = { visible: true, order: 2, template: this.activeClinicalObservationStatusTmpl };
            // Only include End State filter if isGLP is true
            if (this.isGLP) {
                this._controls.endStateClinicalObservationStatus = { visible: true, order: 3, template: this.endStateClinicalObservationStatusTmpl };
                this._controls.defaultClinicalObservationStatus = { visible: true, order: 4, template: this.defaultClinicalObservationStatusTmpl };
            } else {
                this._controls.defaultClinicalObservationStatus = { visible: true, order: 3, template: this.defaultClinicalObservationStatusTmpl };
            }
        }

        if (subsection === 'Vocabularies: Clinical Observation Body Systems') {
            this._controls.observations = { visible: true, order: 1, template: this.observationsTmpl };
            this._controls.bodySystem = { visible: true, order: 2, template: this.bodySystemTmpl };
        }

        if (subsection === 'Vocabularies: Body Condition Scores') {
            this._controls.bodyConditionScore = { visible: true, order: 1, template: this.bodyConditionScoreTmpl };
            this._controls.activeBodyConditionScore = { visible: true, order: 2, template: this.activeBodyConditionScoreTmpl };
            this._controls.defaultBodyConditionScore = { visible: true, order: 3, template: this.defaultBodyConditionScoreTmpl };
        }

        if (subsection === 'Vocabularies: Clinical Observations') {
            this._controls.clinicalObservation = { visible: true, order: 1, template: this.clinicalObservationTmpl };
            this._controls.activeClinicalObservation = { visible: true, order: 2, template: this.activeClinicalObservationTmpl };
            this._controls.experimentalClinicalObservation = { visible: true, order: 3, template: this.experimentalClinicalObservationTmpl };
            this._controls.defaultClinicalObservation = { visible: true, order: 4, template: this.defaultClinicalObservationTmpl };
        }

        if (subsection === 'Vocabularies: Diets') {
            this._controls.diet = { visible: true, order: 1, template: this.dietTmpl };
            this._controls.activeDiet = { visible: true, order: 2, template: this.activeDietTmpl };
            this._controls.defaultDiet = { visible: true, order: 3, template: this.defaultDietTmpl };
        }

        if (subsection === 'Vocabularies: Location Types') {
            this._controls.locationType = { visible: true, order: 1, template: this.locationTypeTmpl };
            this._controls.activeLocationType = { visible: true, order: 2, template: this.activeLocationTypeTmpl };
            this._controls.defaultLocationType = { visible: true, order: 3, template: this.defaultLocationTypeTmpl };
        }

        if (subsection === 'Vocabularies: Generations') {
            this._controls.generation = { visible: true, order: 1, template: this.generationTmpl };
            this._controls.activeGeneration = { visible: true, order: 2, template: this.activeGenerationTmpl };
            this._controls.defaultGeneration = { visible: true, order: 3, template: this.defaultGenerationTmpl };
        }

        if (subsection === 'Settings: Other') {
            this._controls.defaultHealthTechnician = { visible: true, order: 1, template: this.defaultHealthTechnicianTmpl };
            this._controls.inactivityTimeout = { visible: true, order: 2, template: this.inactivityTimeoutTmpl };
        }

        if (subsection === 'Enumerations') {
            this._controls.className = { visible: true, order: 1, template: this.classNameTmpl };
        }

        if (subsection === 'Enumeration Values') {
            this._controls.className = { visible: true, order: 1, template: this.classNameTmpl };
            this._controls.itemName = { visible: true, order: 2, template: this.itemNameTmpl };
        }

        if (subsection === 'Vocabularies: Resource Groups') {
            this._controls.resourceGroup = { visible: true, order: 1, template: this.resourceGroupTmpl };
            this._controls.activeResourceGroup = { visible: true, order: 2, template: this.activeResourceGroupTmpl };
            this._controls.defaultResourceGroup = { visible: true, order: 3, template: this.defaultResourceGroupTmpl };
        }

        if (subsection === 'Vocabularies: Resource Types') {
            this._controls.resourceType = { visible: true, order: 1, template: this.resourceTypeTmpl };
            this._controls.activeResourceType = { visible: true, order: 2, template: this.activeResourceTypeTmpl };
            this._controls.defaultResourceType = { visible: true, order: 3, template: this.defaultResourceTypeTmpl };
        }

        if (subsection === 'Vocabularies: Compliances') {
            this._controls.compliance = { visible: true, order: 1, template: this.complianceTmpl };
            this._controls.activeCompliance = { visible: true, order: 2, template: this.activeComplianceTmpl };
            this._controls.defaultCompliance = { visible: true, order: 3, template: this.defaultComplianceTmpl };
        }

        if (subsection === 'Vocabularies: Institution Types') {
            this._controls.institutionType = { visible: true, order: 1, template: this.institutionTypeTmpl };
            this._controls.activeInstitutionType = { visible: true, order: 2, template: this.activeInstitutionTypeTmpl };
            this._controls.defaultInstitutionType = { visible: true, order: 3, template: this.defaultInstitutionTypeTmpl };
        }

        if (subsection === 'Vocabularies: Line Types') {
            this._controls.lineType = { visible: true, order: 1, template: this.lineTypeTmpl };
            this._controls.activeLineType = { visible: true, order: 2, template: this.activeLineTypeTmpl };
            this._controls.defaultLineType = { visible: true, order: 3, template: this.defaultLineTypeTmpl };
        }

        if (subsection === 'Vocabularies: IACUC Protocols') {
            this._controls.iacucProtocol = { visible: true, order: 1, template: this.iacucProtocolTmpl };
            this._controls.activeIACUCProtocol = { visible: true, order: 2, template: this.activeIACUCProtocolTmpl };
            this._controls.defaultIACUCProtocol = { visible: true, order: 3, template: this.defaultIACUCProtocolTmpl };
        }

        if (subsection === 'Vocabularies: Couriers') {
            this._controls.courier = { visible: true, order: 1, template: this.courierTmpl };
            this._controls.activeCourier = { visible: true, order: 2, template: this.activeCourierTmpl };
            this._controls.defaultCourier = { visible: true, order: 3, template: this.defaultCourierTmpl };
        }

        if (subsection === 'Vocabularies: Exit Reasons') {
            this._controls.exitReason = { visible: true, order: 1, template: this.exitReasonTmpl };
            this._controls.activeExitReason = { visible: true, order: 2, template: this.activeExitReasonTmpl };
            this._controls.defaultExitReason = { visible: true, order: 3, template: this.defaultExitReasonTmpl };
        }

        if (subsection === 'Vocabularies: Genotypes') {
            this._controls.genotype = { visible: true, order: 1, template: this.genotypeTmpl };
            this._controls.activeGenotype = { visible: true, order: 2, template: this.activeGenotypeTmpl };
            this._controls.defaultGenotype = { visible: true, order: 3, template: this.defaultGenotypeTmpl };
        }

        if (subsection === 'Vocabularies: Housing Statuses') {
            this._controls.materialPoolStatus = { visible: true, order: 1, template: this.materialPoolStatusTmpl };
            this._controls.activeMaterialPoolStatus = { visible: true, order: 1, template: this.activeMaterialPoolStatusTmpl };
            this._controls.endStateMaterialPoolStatus = { visible: true, order: 1, template: this.endStateMaterialPoolStatusTmpl };
            this._controls.defaultMaterialPoolStatus = { visible: true, order: 1, template: this.defaultMaterialPoolStatusTmpl };
            this._controls.defaultEndStateMaterialPoolStatus = { visible: true, order: 1, template: this.defaultEndStateMaterialPoolStatusTmpl };
        }

        if (subsection === 'Vocabularies: Mating Purposes') {
            this._controls.matingPurpose = { visible: true, order: 1, template: this.matingPurposeTmpl };
            this._controls.activeMatingPurpose = { visible: true, order: 2, template: this.activeMatingPurposeTmpl };
            this._controls.defaultMatingPurpose = { visible: true, order: 3, template: this.defaultMatingPurposeTmpl };
        }

        if (subsection === 'Vocabularies: Mating Statuses') {
            this._controls.matingStatus = { visible: true, order: 1, template: this.matingStatusTmpl };
            this._controls.activeMatingStatus = { visible: true, order: 2, template: this.activeMatingStatusTmpl };
            this._controls.endStateMatingStatus = { visible: true, order: 3, template: this.endStateMatingStatusTmpl };
            this._controls.defaultMatingStatus = { visible: true, order: 4, template: this.defaultMatingStatusTmpl };
        }
        if (subsection === 'Vocabularies: Job Types') {
            this._controls.jobType = { visible: true, order: 1, template: this.jobTypeTmpl };
            this._controls.activeJobType = { visible: true, order: 2, template: this.activeJobTypeTmpl };
            this._controls.defaultJobType = { visible: true, order: 3, template: this.defaultJobTypeTmpl };
        }

        if (subsection === 'Vocabularies: Payment Terms') {
            this._controls.paymentTerm = { visible: true, order: 1, template: this.paymentTermTmpl };
            this._controls.activePaymentTerm = { visible: true, order: 2, template: this.activePaymentTermTmpl };
            this._controls.defaultPaymentTerm = { visible: true, order: 3, template: this.defaultPaymentTermTmpl };
        }

        if (subsection === 'Vocabularies: Mating Types') {
            this._controls.matingType = { visible: true, order: 1, template: this.matingTypeTmpl };
            this._controls.activeMatingType = { visible: true, order: 2, template: this.activeMatingTypeTmpl };
            this._controls.defaultMatingType = { visible: true, order: 3, template: this.defaultMatingTypeTmpl };
        }

        if (subsection === 'Vocabularies: Compatibility Access Levels') {
            this._controls.compatibilityAccessLevel = { visible: true, order: 1, template: this.compatibilityAccessLevelTmpl };
            this._controls.activeCompatibilityAccessLevel = { visible: true, order: 2, template: this.activeCompatibilityAccessLevelTmpl };
            this._controls.defaultCompatibilityAccessLevel = { visible: true, order: 3, template: this.defaultCompatibilityAccessLevelTmpl };
        }

        if (subsection === 'Vocabularies: Modifiers 1') {
            this._controls.modifier1 = { visible: true, order: 1, template: this.modifier1Tmpl };
            this._controls.activeModifier1 = { visible: true, order: 2, template: this.activeModifier1Tmpl };
        }

        if (subsection === 'Vocabularies: Modifiers 2') {
            this._controls.modifier2 = { visible: true, order: 1, template: this.modifier2Tmpl };
            this._controls.activeModifier2 = { visible: true, order: 2, template: this.activeModifier2Tmpl };
        }

        if (subsection === 'Vocabularies: Modifiers 3') {
            this._controls.modifier3 = { visible: true, order: 1, template: this.modifier3Tmpl };
            this._controls.activeModifier3 = { visible: true, order: 2, template: this.activeModifier3Tmpl };
        }

        if (subsection === 'Vocabularies: Modifiers 4') {
            this._controls.modifier4 = { visible: true, order: 1, template: this.modifier4Tmpl };
            this._controls.activeModifier4 = { visible: true, order: 2, template: this.activeModifier4Tmpl };
        }

        if (subsection === 'Vocabularies: Social Group Access Levels') {
            this._controls.socialGroupAccessLevel = { visible: true, order: 1, template: this.socialGroupAccessLevelTmpl };
            this._controls.activeSocialGroupAccessLevel = { visible: true, order: 2, template: this.activeSocialGroupAccessLevelTmpl };
            this._controls.defaultSocialGroupAccessLevel = { visible: true, order: 3, template: this.defaultSocialGroupAccessLevelTmpl };
        }

        if (subsection === 'Vocabularies: Container Types') {
            this._controls.containerType = { visible: true, order: 1, template: this.containerTypeTmpl };
            this._controls.activeContainerType = { visible: true, order: 2, template: this.activeContainerTypeTmpl };
            this._controls.animalContainerType = { visible: true, order: 3, template: this.animalContainerTypeTmpl };
            this._controls.defaultContainerType = { visible: true, order: 4, template: this.defaultContainerTypeTmpl };
        }

        if (subsection === 'Vocabularies: Job Reports') {
            this._controls.jobReport = { visible: true, order: 1, template: this.jobReportTmpl };
            this._controls.activeJobReport = { visible: true, order: 2, template: this.activeJobReportTmpl };
        }

        if (subsection === 'Vocabularies: Housing Types') {
            this._controls.materialPoolType = { visible: true, order: 1, template: this.housingTypesTmpl };
            this._controls.activeMaterialPoolType = { visible: true, order: 2, template: this.activeHousingTypesTmpl };
            this._controls.defaultMaterialPoolType = { visible: true, order: 3, template: this.defaultHousingTypesTmpl };
            this._controls.defaultMatingMaterialPoolType = { visible: true, order: 4, template: this.defaultMatingHousingTypesTmpl };
        }

        if (subsection === 'Vocabularies: Contact Types') {
            this._controls.contactPersonType = { visible: true, order: 1, template: this.contactPersonTypeTmpl };
            this._controls.activeContactPersonType = { visible: true, order: 2, template: this.activeContactPersonTypeTmpl };
            this._controls.defaultContactPersonType = { visible: true, order: 3, template: this.defaultContactPersonTypeTmpl };
        }

        if (subsection === 'Vocabularies: Quarantine Facilities') {
            this._controls.quarantineFacility = { visible: true, order: 1, template: this.quarantineFacilityTmpl };
            this._controls.activeQuarantineFacility = { visible: true, order: 2, template: this.activeQuarantineFacilityTmpl };
            this._controls.defaultQuarantineFacility = { visible: true, order: 3, template: this.defaultQuarantineFacilityTmpl };
        }

        if (subsection === 'Vocabularies: Genotype Assays') {
            this._controls.genotypeAssay = { visible: true, order: 1, template: this.genotypeAssayTmpl };
            this._controls.activeGenotypeAssay = { visible: true, order: 2, template: this.activeGenotypeAssayTmpl };
            this._controls.defaultGenotypeAssay = { visible: true, order: 3, template: this.defaultGenotypeAssayTmpl };
        }

        if (subsection === 'Vocabularies: Site Types') {
            this._controls.siteType = { visible: true, order: 1, template: this.siteTypeTmpl };
            this._controls.activeSiteType = { visible: true, order: 2, template: this.activeSiteTypeTmpl };
            this._controls.defaultSiteType = { visible: true, order: 3, template: this.defaultSiteTypeTmpl };
        }

        if (subsection === 'Vocabularies: Task Statuses') {
            this._controls.taskStatus = { visible: true, order: 1, template: this.taskStatusTmpl };
            this._controls.activeTaskStatus = { visible: true, order: 1, template: this.activeTaskStatusTmpl };
            this._controls.endStateTaskStatus = { visible: true, order: 1, template: this.endStateTaskStatusTmpl };
            this._controls.defaultTaskStatus = { visible: true, order: 1, template: this.defaultTaskStatusTmpl };
            this._controls.defaultEndStateTaskStatus = { visible: true, order: 1, template: this.defaultEndStateTaskStatusTmpl };
            this._controls.defaultAutoEndStateTaskStatus = { visible: true, order: 1, template: this.defaultAutoEndStateTaskStatusTmpl };
        }

        if (subsection === 'Vocabularies: Units') {
            this._controls.unit = { visible: true, order: 1, template: this.unitTmpl };
            this._controls.activeUnit = { visible: true, order: 2, template: this.activeUnitTmpl };
            this._controls.defaultUnit = { visible: true, order: 3, template: this.defaultUnitTmpl };
        }

        if (subsection === 'Vocabularies: Standard Phrases') {
            this._controls.standardPhrase = { visible: true, order: 1, template: this.standardPhraseTmpl };
            this._controls.activeStandardPhrase = { visible: true, order: 2, template: this.activeStandardPhraseTmpl };
            this._controls.standardPhraseCategories = { visible: true, order: 3, template: this.standardPhraseCategoriesTmpl };
            this._controls.standardPhraseLinkTypes = { visible: true, order: 4, template: this.standardPhraseLinkTypesTmpl };
            this._controls.jobReports = { visible: true, order: 5, template: this.jobReportsTmpl };
        }

        if (subsection === 'Vocabularies: Routes of Administration') {
            this._controls.routeOfAdministration = { visible: true, order: 1, template: this.routeOfAdministrationTmpl };
            this._controls.activeRouteOfAdministration = { visible: true, order: 2, template: this.activeRouteOfAdministrationTmpl };
            this._controls.defaultRouteOfAdministration = { visible: true, order: 3, template: this.defaultRouteOfAdministrationTmpl };
        }

        if (subsection === 'Vocabularies: Veterinary Request Statuses') {
            this._controls.veterinaryRequestStatus = { visible: true, order: 1, template: this.veterinaryRequestStatusTmpl };
            this._controls.activeVeterinaryRequestStatus = { visible: true, order: 2, template: this.activeVeterinaryRequestStatusTmpl };
            this._controls.endStateVeterinaryRequestStatus = { visible: true, order: 1, template: this.endStateVeterinaryRequestStatusTmpl };
            this._controls.defaultVeterinaryRequestStatus = { visible: true, order: 3, template: this.defaultVeterinaryRequestStatusTmpl };
        }

        if (subsection === 'Job Locations') {
            this._controls.jobs = { visible: true, order: 1, template: this.jobsTmpl };
            this._controls.locations = { visible: true, order: 2, template: this.locationsTmpl };
        }

        if (subsection === 'Study Characteristics') {
            this._controls.studyName = { visible: true, order: 1, template: this.studyNameTmpl };
            this._controls.studyType = { visible: true, order: 2, template: this.studyTypeTmpl };
            this._controls.characteristicName = { visible: true, order: 3, template: this.characteristicNameTmpl };
            this._controls.characteristicValue = { visible: true, order: 4, template: this.characteristicValueTmpl };
        }

        if (subsection === 'Sample Characteristics') {
            this._controls.sampleIdentifiers = { visible: true, order: 1, template: this.sampleIdentifiersTmpl };
            this._controls.samples = { visible: true, order: 2, template: this.samplesTmpl };
            this._controls.characteristicName = { visible: true, order: 3, template: this.characteristicNameTmpl };
            this._controls.characteristicValue = { visible: true, order: 4, template: this.characteristicValueTmpl };
        }

        if (subsection === 'Animal Characteristics') {
            this._controls.animalIdentifiers = { visible: true, order: 1, template: this.animalIdentifiersTmpl };
            this._controls.animals = { visible: true, order: 2, template: this.animalsTmpl };
            this._controls.characteristicName = { visible: true, order: 3, template: this.characteristicNameTmpl };
            this._controls.characteristicValue = { visible: true, order: 4, template: this.characteristicValueTmpl };
        }

        if (subsection === 'Vocabularies: Shipment Container Conditions') {
            this._controls.shipmentContainerCondition = { visible: true, order: 1, template: this.shipmentContainerConditionTmpl };
            this._controls.activeShipmentContainerCondition = { visible: true, order: 2, template: this.activeShipmentContainerConditionTmpl };
            this._controls.defaultShipmentContainerCondition = { visible: true, order: 3, template: this.defaultShipmentContainerConditionTmpl };
        }

        this._filters = Object.getOwnPropertyNames(this._controls).map((x) => ({
            controlName: x,
            order: this._controls[x].order,
            template: this._controls[x].template,
            context: this._controls[x].context,
        }));

        if (subsection === 'Jobs') {
            this.loadJobTypes();
            this.loadJobStatuses();
            this.loadJobSubtypes();
        }

        if (subsection === 'Files') {
            this.loadFacetValues();
            this.loadTaskAliases();
        }

        if (subsection === 'Job Tasks' ||
            subsection === 'Workflow Inputs' ||
            subsection === 'Workflow Outputs') {
            this.loadWorkflowTasks();
            this.loadTaskStatuses();
            this.loadTaskAliases();
        }

        if (subsection === 'Protocol Default Inputs') {
            this.loadWorkflowTasks();
            this.loadProtocolTaskAliases();
        }

        if (subsection === 'Workflow Inputs' ||
            subsection === 'Workflow Outputs') {
            this.loadTaskTypes(false);
        }

        if (subsection === 'Job Lines' ||
            subsection === 'Job Line Details' ||
            subsection === 'Lines' ||
            subsection === 'Characteristics: Animals') {
            this.loadTaxons();
        }

        if (subsection === 'Job Characteristics') {
            this.loadJobCharacteristicTypes();
        }

        if (subsection === 'Housing' || subsection === 'Housing Animals') {
            this.loadMaterialPoolTypes();
            this.loadMaterialPoolStatuses();
        }

        if (subsection === 'Housing Animals' ||
            subsection === 'Cohort Animals') {
            this.loadAnimalStatuses();
        }

        if (subsection === 'Housing Social Statutes') {
            this.loadSexes();
            this.loadAccessLevels();
        }

        if (subsection === 'Animal Comments') {
            this.loadAnimalCommentStatuses();
        }

        if (subsection === 'Matings' || subsection === 'Mating Materials') {
            this.loadMatingStatuses();
            this.loadMatingTypes();
            this.loadAnimalMatingStatuses();
        }

        if (subsection === 'Studies') {
            this.loadStudyTypes();
            this.loadStudyStatuses();
            this.loadApprovalNumbers();
        }

        if (subsection === 'Births') {
            this.loadBirthStatuses();
        }

        if (subsection === 'Locations') {
            this.loadLocationTypes();
        }

        if (subsection === 'Housing Tasks' ||
            subsection === 'Animal Tasks') {
            this.loadTaskAliases();
        }

        if (subsection === 'Tasks') {
            this.loadTaskTypes(false);
            this.loadTaskAliases();
        }

        if (subsection === 'Task Assignments') {
            this.loadTaskTypes(false);
            this.loadWorkflowTasks();
            this.loadTaskAliases();
        }

        if (subsection === 'Task Output Flags') {
            this.loadTaskTypes(true);
            this.loadWorkflowTasks();
            this.loadTaskAliases();
        }

        if (subsection === 'Tasks' ||
            subsection === 'Protocol Tasks' ||
            subsection === 'Protocol Sample Groups' ||
            subsection === 'Job Sample Groups') {
            this.loadWorkflowTasks();
        }

        if (subsection === 'Workflow Task Details') {
            this.loadTaskAliases();
        }

        if (subsection === 'Resources') {
            this.loadResourceTypes();
            this.loadResourceGroups();
        }

        if (subsection === 'Characteristics: Jobs') {
            this.loadJobCharacteristicLinkTypes();
        }

        if (subsection === 'Vocabularies: Standard Phrases') {
            this.loadStandardPhraseCategories();
            this.loadStandardPhraseLinkTypes();
            this.loadJobReports();
        }

        if (subsection === 'Characteristics: Studies' || subsection === 'Study Characteristics') {
            this.loadStudyTypes();
        }

        if (subsection === 'Characteristics: Samples') {
            this.loadSampleTypes();
        }

        if (subsection === 'Orders') {
            this.loadMaterialTypes();
        }

        if (subsection === 'Clinical Tasks') {
            this.loadTaskStatuses();
        }

        if (subsection === 'Task Inputs' || subsection === 'Task Outputs') {
            this.loadWorkflowTasks();
            this.loadDataTypes();
        }

        if (subsection === 'Clinical Observations') {
            this.loadResourceNames();
            this.loadClinicalObservationStatuses();
        }

        if (subsection === 'Clinical Observation Details') {
            this.loadResourceNames();
            this.loadClinicalObservationStatuses();
            this.loadClinicalObservations();

            if (this.isGLP) {
                this.loadObservationTypes();
            }
        }

        if (subsection === 'Vocabularies: Clinical Observation Body Systems') {
            this.loadClinicalObservations();
            this.loadBodySystems();
        }

        if (subsection === 'Job Variable Phrases') {
            this.loadVariablePhraseTypes();
        }
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }

    private loadJobTypes(): Subscription {
        return this.jobVocabService.jobTypes$.subscribe((jobTypes: any[]) => {
            this.jobTypes = jobTypes;
        });
    }

    private loadJobStatuses(): Subscription {
        return this.jobVocabService.jobStatuses$.subscribe((jobStatuses: any[]) => {
            this.jobStatuses = jobStatuses;
        });
    }

    private loadJobSubtypes(): Subscription {
        return this.jobVocabService.jobSubtypes$.subscribe((jobSubtypes: any[]) => {
            this.jobSubtypes = jobSubtypes;
        });
    }

    private loadJobCharacteristicLinkTypes(): Subscription {
        return this.jobVocabService.jobCharacteristicLinkTypes$.subscribe((jobCharacteristicLinkTypes: cv_JobCharacteristicLinkType[]) => {
            jobCharacteristicLinkTypes.forEach((item) => {
                const jobCharacteristicLinkTypeName = "JobCharacteristicLinkTypeName";
                const jobCharacteristicsLinkTypeKey = "JobCharacteristicLinkTypeKey";
                item[jobCharacteristicLinkTypeName] = item.JobCharacteristicLinkType;
                item[jobCharacteristicsLinkTypeKey] = item.JobCharacteristicLinkType;
                if (item.JobCharacteristicLinkType.toLowerCase().includes('job')) {
                    if (this.featureFlagService.getIsCRO()) {
                        item[jobCharacteristicsLinkTypeKey] = "Job Subtype";
                        item[jobCharacteristicLinkTypeName] = this.translationService.translate('Job') + " Subtype";
                    } else {
                        item[jobCharacteristicLinkTypeName] = this.translationService.translate('Job') + " Type";
                    }
                }
            });
            this.jobCharacteristicLinkTypes = jobCharacteristicLinkTypes;
        });
    }

    private loadTaskAliases(): void {
        this.webApiService.callApi('api/task/getTaskAliases')
            .then((response) => {
                this.taskAliases = response.data;
            }).catch((error) => {
                this.loggingService.logError(
                    "Couldn't get task aliases",
                    error,
                    'audit-filter',
                    true
                );
            });
    }

    private loadProtocolTaskAliases(): void {
        this.webApiService.callApi('api/task/getProtocolTaskAliases')
            .then((response) => {
                this.protocolTaskAliases = response.data;
            }).catch((error) => {
                this.loggingService.logError(
                    "Couldn't get protocol task aliases",
                    error,
                    'audit-filter',
                    true
                );
            });
    }

    private loadWorkflowTasks(): Subscription {
        return this.workflowVocabService.workflowTasks$.subscribe((data: any[]) => {
            this.workflowTasks = data;
        });
    }

    private loadTaskStatuses(): Subscription {
        return this.clinicalVocabService.taskStatuses$.subscribe((taskStatuses: any[]) => {
            this.taskStatuses = taskStatuses;
        });
    }

    private loadTaxons(): Subscription {
        return this.jobVocabService.taxons$.subscribe((taxons: any[]) => {
            this.taxons = taxons;
        });
    }

    private loadJobCharacteristicTypes(): void {
        this.jobService.getJobCharacteristicInstances().then((data: any[]) => {
            this.jobCharacteristics = uniqueArrayOnProperty(data, 'CharacteristicName');
        });
    }

    private loadMaterialPoolTypes(): Subscription {
        return this.housingVocabService.materialPoolTypes$.subscribe((data: any[]) => {
            this.materialPoolTypes = data;
        });
    }

    private loadMaterialPoolStatuses(): Subscription {
        return this.housingVocabService.materialPoolStatuses$.subscribe((data: any[]) => {
            this.materialPoolStatuses = data;
        });
    }

    private loadAnimalStatuses(): Subscription {
        return this.workflowVocabService.animalStatuses$.subscribe((data: any[]) => {
            this.animalStatuses = data;
        });
    }

    private loadAnimalCommentStatuses(): Subscription {
        return this.workflowVocabService.animalCommentStatuses$.subscribe((data: any[]) => {
            this.animalCommentStatuses = data;
        });
    }

    private loadMatingStatuses(): Subscription {
        return this.matingVocabService.matingStatuses$.subscribe((data: any[]) => {
            this.matingStatuses = data;
        });
    }

    private loadAnimalMatingStatuses(): Subscription {
        return this.matingVocabService.animalMatingStatuses$.subscribe((data: any[]) => {
            this.animalMatingStatuses = data;
        });
    }

    private loadMatingTypes(): Subscription {
        return this.matingVocabService.matingTypes$.subscribe((data: any[]) => {
            this.matingTypes = data;
        });
    }

    private loadStudyStatuses(): Subscription {
        return this.studyVocabService.studyStatuses$.subscribe((data: any[]) => {
            this.studyStatuses = data;
        });
    }

    private loadStudyTypes(): Subscription {
        return this.studyVocabService.studyTypes$.subscribe((data: any[]) => {
            this.studyTypes = data;
        });
    }

    private loadBirthStatuses(): Subscription {
        return this.birthVocabService.birthStatuses$.subscribe((data: any[]) => {
            this.birthStatuses = data;
        });
    }

    private loadLocationTypes(): void {
        this.vocabularyService.getCV(
            'cv_LocationTypes', 'LocationType', true
        ).then((data) => {
            this.locationTypes = data;
        });
    }

    private loadTaskTypes(hideHealthRecord: boolean): Subscription {
        return this.taskVocabService.taskTypes$.subscribe((taskTypes: any[]) => {
            const translated = taskTypes.map((tt: any) => ({
                TaskType: this.translationService.translate(tt.TaskType)
            }));
            const sortedTaskTypes = sortObjectArrayByAccessor(translated, (t: any) => t.TaskType);
            if (hideHealthRecord) {
                this.taskTypes = sortedTaskTypes.filter((item) => item.TaskType !== 'Health Record');
            } else {
                this.taskTypes = sortedTaskTypes;
            }
        });
    }

    setTranslateTaskType(taskTypes: any) {
        const newTaskTypes: any = [];
        for (const task of taskTypes) {
            newTaskTypes.push({
                TaskType: this.translationService.translate(task.TaskType),
                C_TaskType_key: task.C_TaskType_key
            });
        }
        return newTaskTypes;
    }

    private loadResourceTypes(): Subscription {
        return this.resourceVocabService.resourceTypes$.subscribe((resourceTypes: any[]) => {
            this.resourceTypes = resourceTypes;
        });
    }

    private loadResourceGroups(): Subscription {
        return this.resourceVocabService.resourceGroups$.subscribe((resourceGroups: any[]) => {
            this.resourceGroups = resourceGroups;
        });
    }

    private loadStandardPhraseCategories(): Subscription {
        return this.jobVocabService.standardPhraseCategories$.subscribe((standardPhraseCategories: any[]) => {
            this.standardPhraseCategories = standardPhraseCategories;
        });
    }

    private loadStandardPhraseLinkTypes(): Subscription {
        return this.jobVocabService.standardPhraseLinkTypes$.subscribe((standardPhraseLinkTypes: any[]) => {   
            const translatedStandardPhraseLinkType = standardPhraseLinkTypes.map((item: any) => ({
                StandardPhraseLinkType: this.translationService.translate(item.StandardPhraseLinkType)
            }));
            this.standardPhraseLinkTypes = translatedStandardPhraseLinkType;
        });
    }

    private async loadVariablePhraseTypes() {
        this.variablePhraseTypes = await this.vocabularyService.getCV("cv_VariablePhraseTypes", "VariablePhraseType", true, true);
    }

    private loadJobReports(): Subscription {
        return this.jobVocabService.jobReports$.subscribe((jobReports: any[]) => {
            this.jobReports = jobReports;
        });
    }

    private loadApprovalNumbers(): Promise<any> {
        return this.studyService
            .getStudiesApprovalNumbers()
            .then((data) => this.approvalNumbers = uniqueArrayOnProperty(data, 'ApprovalNumber'));
    }

    private loadSampleTypes(): Subscription {
        return this.sampleVocabService.sampleTypes$.subscribe((data: any[]) => {
            this.sampleTypes = data;
        });
    }

    private loadMaterialTypes(): Subscription {
        return this.orderVocabService.materialTypes$.subscribe((materialTypes: any[]) => {
            this.materialTypes = materialTypes;
        });
    }

    private loadResourceNames(): Subscription {
        return this.clinicalVocabService.resources$.subscribe((resources: any[]) => {
            this.resourceNames = resources.filter((resource) => !resource.IsGroup);
        });
    }

    private loadClinicalObservationStatuses(): Subscription {
        return this.clinicalVocabService.clinicalObservationStatuses$.subscribe((clinicalObservationStatuses: any[]) => {
            this.clinicalObservationStatuses = clinicalObservationStatuses;
        });
    }

    private loadClinicalObservations(): Subscription {
        return this.clinicalVocabService.clinicalObservations$.subscribe((clinicalObservations: any[]) => {
            this.clinicalObservations = clinicalObservations;
        });
    }

    private loadSexes(): Subscription {
        return this.animalVocabService.sexes$.subscribe((sexes: any[]) => {
            this.sexes = sexes;
        });
    }

    private loadAccessLevels(): Subscription {
        return this.housingVocabService.socialGroupAccessLevels$.subscribe((accessLevels: any[]) => {
            this.accessLevels = accessLevels;
        });
    }

    private loadBooleanValues(): void {
        const values: any[] = [];
        values.push({
            Value: true,
        });
        values.push({
            Value: false,
        });

        this.booleanValues = values;
    }

    private loadFacetValues(): void {
        const values: any[] = [];
        values.push({
            Value: 'Animals',
        });
        values.push({
            Value: 'Clinicals',
        });
        values.push({
            Value: 'Workflow',
        });
        values.push({
            Value: 'Protocols',
        });
        values.push({
            Value: 'Samples',
        });
        values.push({
            Value: this.translationService.translate('Studies'),
        });
        values.push({
            Value: this.translationService.translate('Lines'),
        });
        values.push({
            Value: 'Constructs',
        });
        values.push({
            Value: 'Orders',
        });
        values.push({
            Value: this.translationService.translate('Jobs'),
        });

        this.facetValues = values;
    }

    private loadDataTypes(): void {
        this.vocabularyService.getCV("cv_DataTypes", "DataType", true, true).then((data: any[]) => {
            this.dataTypes = data;
        });
    }

    private loadObservationTypes(): void {
        this.observationTypes = [{ Value: 'Clinical' }, { Value: 'Diagnostic' }];
    }

    private loadBodySystems(): Subscription {
        return this.clinicalVocabService.bodySystems$.subscribe((bodySystems: any[]) => {
            this.bodySystems = bodySystems;
        });
    }

    /**
     * Depending on if the control option has a defined "visible" property, it will either use that or return false.
     * @param controlOption 
     * @returns 
     */
    private _visibleOrFalse(controlOption: {visible: boolean}): boolean {
        return controlOption && controlOption.visible ? controlOption.visible : false;
    }
}

class ControlsVisibility {
    [controlName: string]: {
        visible: boolean;
        order: number;
        template: TemplateRef<any>;
        context?: any;
    };
}

export interface Filter {
    controlName: string;
    order: number;
    template: TemplateRef<any>;
    context?: any;
}

// NOTE: Should we be using the AuditGridView instead of this type in the future?
type Subsection =
    | 'Animals'
    | 'Animal Comments'
    | 'Animal Tasks'
    | 'Samples'
    | 'Jobs'
    | 'Job Animals'
    | 'Job Samples'
    | 'Job Sample Groups'
    | 'Job Tasks'
    | 'Job Lines'
    | 'Job Line Details'
    | 'Job Standard Phrases'
    | 'Job Variable Phrases'
    | 'Job Groups'
    | 'Job Test Articles'
    | 'Job Characteristics'
    | 'Jobs Institutions'
    | 'Job Orders'
    | 'Job Individual Placeholders'
    | 'Job Group Placeholders'
    | 'Workflow Inputs'
    | 'Workflow Outputs'
    | 'Housing'
    | 'Housing Animals'
    | 'Housing Tasks'
    | 'Housing Locations'
    | 'Housing Social Statutes'
    | 'Matings'
    | 'Mating Materials'
    | 'Cohorts'
    | 'Cohort Animals'
    | 'Lines'
    | 'Studies'
    | 'Study Lines'
    | 'Settings: Other'
    | 'Births'
    | 'Locations'
    | 'Clinical Observations'
    | 'Clinical'
    | 'Clinical Tasks'
    | 'Clinical Observation Details'
    | 'Tasks'
    | 'Task Assignments'
    | 'Task Output Flags'
    | 'Files'
    | 'Workflow Task Details'
    | 'Task Inputs'
    | 'Task Outputs'
    | 'Orders'
    | 'Order Animals'
    | 'Order Locations'
    | 'Protocols'
    | 'Protocol Default Inputs'
    | 'Protocol Tasks'
    | 'Protocol Sample Groups'
    | 'Resources'
    | 'Study Administrator Studies'
    | 'Users'
    | 'User Details'
    | 'User Roles'
    | 'Role Facets'
    | 'Enumerations'
    | 'Enumeration Values'
    | 'Characteristics: Animals'
    | 'Characteristics: Jobs'
    | 'Characteristics: Studies'
    | 'Vocabularies: Task Flag Messages'
    | 'Vocabularies: Test Articles'
    | 'Vocabularies: Job Subtypes'
    | 'Vocabularies: Task Statuses'
    | 'Vocabularies: Animal Statuses'
    | 'Vocabularies: Task Outcomes'
    | 'Vocabularies: Marker Types'
    | 'Vocabularies: Animal Classifications'
    | 'Vocabularies: Animal Comment Statuses'
    | 'Vocabularies: Line Statuses'
    | 'Vocabularies: Job Statuses'
    | 'Characteristics: Samples'
    | 'Vocabularies: Animal Uses'
    | 'Vocabularies: Sample Analyses'
    | 'Vocabularies: Plate Statuses'
    | 'Vocabularies: Sample Conditions'
    | 'Vocabularies: Animal/Sample Origins'
    | 'Vocabularies: Birth Statuses'
    | 'Vocabularies: Body Condition Scores'
    | 'Vocabularies: Breeding Statuses'
    | 'Settings: Naming'
    | 'Settings: Facets'
    | 'Vocabularies: Shipment Animal Conditions'
    | 'Vocabularies: Clinical Observations'
    | 'Vocabularies: Clinical Observation Statuses'
    | 'Vocabularies: Clinical Observation Body Systems'
    | 'Vocabularies: Diets'
    | 'Vocabularies: Generations'
    | 'Vocabularies: Location Types'
    | 'Vocabularies: Resource Groups'
    | 'Vocabularies: Resource Types'
    | 'Vocabularies: Compliances'
    | 'Vocabularies: Institution Types'
    | 'Vocabularies: Line Types'
    | 'Vocabularies: IACUC Protocols'
    | 'Vocabularies: Couriers'
    | 'Vocabularies: Exit Reasons'
    | 'Vocabularies: Genotypes'
    | 'Vocabularies: Housing Statuses'
    | 'Vocabularies: Mating Purposes'
    | 'Vocabularies: Job Types'
    | 'Vocabularies: Mating Statuses'
    | 'Vocabularies: Payment Terms'
    | 'Vocabularies: Mating Types'
    | 'Vocabularies: Compatibility Access Levels'
    | 'Vocabularies: Modifiers 1'
    | 'Vocabularies: Modifiers 2'
    | 'Vocabularies: Modifiers 3'
    | 'Vocabularies: Modifiers 4'
    | 'Vocabularies: Social Group Access Levels'
    | 'Vocabularies: Container Types'
    | 'Vocabularies: Job Reports'
    | 'Vocabularies: Housing Types'
    | 'Vocabularies: Contact Types'
    | 'Vocabularies: Quarantine Facilities'
    | 'Vocabularies: Genotype Assays'
    | 'Vocabularies: Site Types'
    | 'Vocabularies: Units'
    | 'Vocabularies: Standard Phrases'
    | 'Vocabularies: Routes of Administration'
    | 'Vocabularies: Veterinary Request Statuses'
    | 'Job Locations'
    | 'Study Characteristics'
    | 'Sample Characteristics'
    | 'Animal Characteristics'
    | 'Vocabularies: Shipment Container Conditions';

