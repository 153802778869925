import { Injectable } from '@angular/core';
import { isEmpty } from '@lodash';

import { ConfirmOptions } from './../common/confirm/confirm-options';
import { ConfirmService } from './../common/confirm/confirm.service';
import {
    getSafeProp,
    notEmpty,
    uniqueArrayFromPropertyPath
} from './../common/util';

import { EnumerationService } from '../enumerations/enumeration.service';
import { TranslationService } from './../services/translation.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';

import { IJobMaterial, JobService } from './job.service';
import { EntityQuery, Predicate } from 'breeze-client';
import { DataManagerService } from '../services/data-manager.service';
import { WorkflowLogicService } from '../workflow/services/workflow-logic.service';
import { CohortService } from '../cohort/services/cohort.service';
import { TaskMaterial, Animal, Job, JobMaterial, TaskJob, SampleGroupSourceMaterial, Entity, ExtendedJob } from '@common/types';

/**
 * Common business logic for the Job facet.
 */
@Injectable()
export class JobLogicService {

    jobTranslated: string;
    jobsTranslated: string;

    readonly ARRAY_TO_STRING_DELIMITER = ', ';

    constructor(
        private confirmService: ConfirmService,
        private enumerationService: EnumerationService,
        private jobService: JobService,
        private workflowLogicService: WorkflowLogicService,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
        private cohortService: CohortService,
        private dataManagerService: DataManagerService,
    ) {
        this.initTranslations();
    }

    private initTranslations() {
        this.jobTranslated = this.translationService.translate('Job');
        this.jobsTranslated = this.translationService.translate('Jobs');
    }

    /**
     * Create a new Job with a default Type
     */
    createNewJob(): Promise<any> {
        let newJob: any = null;

        return this.jobService.createJob().then((createdJob: any) => {
            newJob = createdJob;

            return this.vocabularyService.getCVDefault('cv_JobTypes');
        }).then((defaultJobType: any) => {
            newJob.cv_JobType = defaultJobType;

            return this.vocabularyService.getCVDefault('cv_JobSubtypes');
        }).then((defaultJobSubtype: any) => {
            newJob.cv_JobSubtype = defaultJobSubtype;

            return this.vocabularyService.getCVDefault('cv_JobStatuses');
        }).then((defaultJobStatus: any) => {
            newJob.cv_JobStatus = defaultJobStatus;

            return this.vocabularyService.getCVDefault('cv_Compliances');
        }).then((defaultCompliances: any) => {
            if (defaultCompliances) {
                newJob.C_Compliance_key = defaultCompliances.C_Compliance_key;
            }
            return this.jobService.createJobCharacteristics(newJob);
        }).then((jobCharacteristicInstances: any[]) => {
            return this.attachCharacteristicEnumerations(newJob.JobCharacteristicInstance);
        }).then(() => {
            if (this.jobService.getIsCroFlag()) {
                return this.vocabularyService.getStandardPhrasesWithDefaults();
            } else {
                return Promise.resolve([]);
            }
        }).then((standardPhrases: any[]) => {
            // Apply default standard phrases based on default JobType and default JobSubtype (Don't need others since Imaging boolean and Report field are empty by default)
            if (standardPhrases && standardPhrases.length) {
                const activePhrases = standardPhrases.filter((x: any) => x.IsActive);
                for (const phrase of activePhrases) {
                    if ((newJob.C_JobType_key && phrase.cv_StandardPhraseJobType.find((t: any) => t.C_JobType_key === newJob.C_JobType_key))
                        || (newJob.C_JobSubtype_key && phrase.cv_StandardPhraseJobSubtype.find((t: any) => t.C_JobSubtype_key === newJob.C_JobSubtype_key))) {
                        this.jobService.createJobStandardPhrase(
                            {
                                C_Job_key: newJob.C_Job_key,
                                C_StandardPhrase_key: phrase.C_StandardPhrase_key
                            }
                        );
                    }
                }
            }
            return Promise.resolve();
        }).then(() => {
            return newJob;
        });
    }

    attachCharacteristicEnumerations(characteristicInstances: any[]) {
        const promises = [];

        for (const characteristicInstance of characteristicInstances) {
            if (characteristicInstance.JobCharacteristic.C_EnumerationClass_key) {
                const promise = this.enumerationService.getEnumerationItems(
                    characteristicInstance.JobCharacteristic.C_EnumerationClass_key
                ).then((items: any[]) => {
                    characteristicInstance.EnumerationItems = items;
                });

                promises.push(promise);
            }
        }

        return Promise.all(promises);
    }

    async getAnimalsEnrolledOnlyInOtherJobs(animals: Animal[], job: Entity<ExtendedJob>): Promise<Animal[]> {
        if (isEmpty(animals)){
            return [];
        }

        await this.jobService.ensureMaterialJobsLoaded(animals);
        return animals.filter(animal => this.isAnimalEnrolledOnlyInOtherJobs(animal, job));
    }

    isAnimalEnrolledOnlyInOtherJobs(animal: Animal, job: Entity<ExtendedJob>){
        const jobMaterials = animal.Material.JobMaterial || [];
        const activeJobMaterials = jobMaterials
            .filter(jm => !jm.DateOut);
        const animalIsAlreadyInTheTargetJob = activeJobMaterials
            .some(jm => jm.C_Job_key === job.C_Job_key);

        return notEmpty(activeJobMaterials)
            && !animalIsAlreadyInTheTargetJob;
    }

    showAddAnimalsToMultipleJobsConfirm(animalsInJobs: any[]): Promise<any> {
        const confirmTitle = 'Animals Already in ' + this.jobsTranslated;
        const isDanger = false;

        let confirmMessage = '';
        const animalCount = animalsInJobs.length;
        if (animalCount > 1) {
            confirmMessage = animalCount +
                ' of these animals are already in ' +
                this.jobsTranslated.toLowerCase() +
                '. ';
        } else {
            confirmMessage = 'This animal is already in ' +
                this.jobsTranslated.toLowerCase() +
                '. ';
        }
        confirmMessage += 'Would you like to proceed?';

        // Add animal and job details to modal
        const animalNames = this.getAnimalNamesAsString(animalsInJobs);
        const jobIDs = this.getAnimalJobIDsAsString(animalsInJobs);

        const confirmOptions: ConfirmOptions = {
            title: confirmTitle,
            message: confirmMessage,
            yesButtonText: 'Add All',
            noButtonText: 'Cancel All',
            isDanger,
            details: [
                animalNames,
                jobIDs
            ]
        };

        return this.confirmService.confirm(confirmOptions);
    }

    async transferAnimalToJob(animals: Animal[], job: Entity<ExtendedJob>): Promise<JobMaterial[] | string[]> {
        const confirmTitle = 'Animals Already in ' + this.jobsTranslated;
        const isDanger = false;
        const forceAnswer = true;

        const animalsInJobs = await this.getAnimalsEnrolledOnlyInOtherJobs(animals, job);
        if (animalsInJobs.length === 0) {
            return ["no animals"];
        }

        const animalNames = this.getAnimalNamesAsString(animalsInJobs);
        const [jobMaterials, isAbleToRemove] = await Promise.all([
            this.getJobMaterialsFromAnimals(animalsInJobs),
            this.hasDefaultAutomaticEndState()
        ]);

        if (!isAbleToRemove){
            return ["cannot remove"];
        }

        const materialKeys = jobMaterials.map(jm => jm.C_Material_key);
        const taskMaterials = await this.getTaskMaterialsWithExpandedTaskInstances(materialKeys);
        const taskInstances = taskMaterials.map(tm => tm.TaskInstance);
        let confirmMessage = `${animalNames} ${animalsInJobs.length > 1 ? 'are' : 'is'} enrolled in an active ${this.jobTranslated}. `
            + `Do you wish to reassign them to ${this.jobTranslated} ${job.JobID}?`;
        if (taskInstances.length > 1) {
            const tasksCountText = `${taskInstances.length} ${taskInstances.length > 1 ? 'Tasks' : 'Task'}`;
            confirmMessage += ` ${tasksCountText} will also be set to their Default End State statuses.`;
        }

        const confirmOptions: ConfirmOptions = {
            title: confirmTitle,
            message: confirmMessage,
            yesButtonText: 'Add All',
            noButtonText: 'Cancel All',
            forceAnswer,
            isDanger,
        };

        try{
            await this.confirmService.confirm(confirmOptions);
            await this.workflowLogicService.setTasksToAutomaticEndState(taskInstances);
            return jobMaterials;
        }
        catch (ex) {
            return ["modal cancelled"];
        }
    }

    getJobMaterialsFromAnimals(animals: Animal[]): Promise<JobMaterial[]> {
        if (animals.length === 0) {
            return Promise.resolve([]);
        }

        // set the date out value of the animals attempting to be moved.
        const materialKeys = animals.map((a: any) => a.C_Material_key);
        const p1 = Predicate.create('C_Material_key', "in", materialKeys);
        const p2 = Predicate.create('DateOut', '==', null);
        const predicates: Predicate = Predicate.and([p1, p2]);

        const query = EntityQuery.from('JobMaterials')
            .where(predicates);
        return this.dataManagerService.returnQueryResults(query);
    }

    getTaskMaterialsWithExpandedTaskInstances(materialKeys: number[]): Promise<TaskMaterial[]> {
        if (materialKeys.length === 0) {
            return Promise.resolve([]);
        }

        const predicate = Predicate.create("C_Material_key", "in", materialKeys);
        const query = EntityQuery
            .from("TaskMaterials")
            .where(predicate)
            .expand('TaskInstance');

        return this.dataManagerService.returnQueryResults(query);
    }

    hasDefaultAutomaticEndState(): Promise<boolean> {
        // confirm if there's a default automatic end state
        let query = EntityQuery.from("cv_TaskStatuses");
        const predicate = Predicate.create("IsDefaultAutoEndState", "eq", "true");
        query = query.where(predicate);

        return this.dataManagerService.returnQueryResults(query).then((response: any[]) => {
            return Promise.resolve(response.length > 0);
        });
    }

    validateJobNamingField(job: any): Promise<string> {
        return this.jobService.getJobPrefixField().then((prefixField: string) => {
            // Validate that field used for job naming prefix has a value
            let isValid;
            switch (prefixField.toLowerCase()) {
                case 'code': {
                    if (job.JobCode) {
                        isValid = true;
                    }
                    break;
                }
                case 'iacuc protocol': {
                    if (job.cv_IACUCProtocol && job.cv_IACUCProtocol.IACUCProtocol) {
                        isValid = true;
                    }
                    break;
                }
                case 'line': {
                    if (job.Line && job.Line.LineName) {
                        isValid = true;
                    }
                    break;
                }
                case 'type': {
                    if (job.cv_JobType && job.cv_JobType.JobType) {
                        isValid = true;
                    }
                    break;
                }
                case 'subtype': {
                    if (job.cv_JobSubtype && job.cv_JobSubtype.JobSubtype) {
                        isValid = true;
                    }
                    break;
                }
                case 'compliance': {
                    if (job.Compliance && job.Compliance.Compliance) {
                        isValid = true;
                    }
                    break;
                }
                case 'text': {
                    isValid = true;
                    break;
                }
                default: {
                    isValid = false;
                    break;
                }
            }

            if (isValid) {
                return '';
            } else {
                return prefixField;
            }
        });
    }

    private getAnimalNamesAsString(animals: any[]): string {
        const animalNames: string[] = this.getAnimalNames(animals);

        if (notEmpty(animalNames)) {
            const animalLimit = 5;
            if (animalNames.length <= animalLimit) {
                return 'Animals: ' +
                    animalNames.join(this.ARRAY_TO_STRING_DELIMITER);
            } else {
                const firstAnimals = animalNames.slice(0, animalLimit);
                const amountString = `and ${animalNames.length - animalLimit} more`;

                return `Animals ${firstAnimals.join(this.ARRAY_TO_STRING_DELIMITER)} ${amountString}`;
            }
        } else {
            return '';
        }
    }

    private getAnimalNames(animals: any[]): string[] {
        if (notEmpty(animals)) {
            return animals.map((animal) => {
                return animal.AnimalName;
            });
        } else {
            return [];
        }
    }

    private getAnimalJobIDsAsString(animals: any[]): string {
        const jobIDs: string[] = this.getAnimalJobIDs(animals);

        if (notEmpty(jobIDs)) {
            return this.jobsTranslated + ': ' +
                jobIDs.join(this.ARRAY_TO_STRING_DELIMITER);
        } else {
            return '';
        }
    }

    private getAnimalJobIDs(animals: any[]): string[] {
        if (notEmpty(animals)) {
            // Collect as a Set to get unique values
            const jobIDsSet = new Set<string>();
            for (const animal of animals) {
                const jobMaterials = animal.Material.JobMaterial;
                for (const jobMaterial of jobMaterials) {
                    jobIDsSet.add(getSafeProp(jobMaterial, 'Job.JobID'));
                }
            }

            // Convert to sorted array
            return Array.from(jobIDsSet).sort();
        } else {
            return [];
        }
    }

    /**
     * Takes the Job Materials attached to Materials attached to the supplied Cohort.
     * It will only delete if the material ONLY belongs to the supplied cohort
     * In GLP workgroups, it will set the DateOut to the current date instead of deleting.
     * @param cohort
     */
    removeJobMaterialsFromCohort(cohort: any, job: any, isGLP: boolean): Promise<any> {

        const otherCohorts = job.JobCohort.filter((jc: any) => jc.C_Cohort_key !== cohort.C_Cohort_key);
        const otherCohortMaterialKeys = uniqueArrayFromPropertyPath(otherCohorts, 'Cohort.CohortMaterial.C_Material_key');
        return this.cohortService.ensureMaterialsExpanded([cohort]).then(() => {
            const materialKeys = uniqueArrayFromPropertyPath(cohort, 'CohortMaterial.C_Material_key');
            let jobMaterials = job.JobMaterial.filter((x: IJobMaterial) => materialKeys.includes(x.C_Material_key));
            if (isGLP) {
                jobMaterials = jobMaterials.filter((x: IJobMaterial) => !x.DateOut);
            }

            for (const jm of jobMaterials) {
                if (otherCohortMaterialKeys.indexOf(jm.C_Material_key) < 0) {
                    if (isGLP) {
                        jm.DateOut = new Date();
                        continue;
                    }
                    
                    const taskInstanceKeys = job.TaskJob.map((taskJob: TaskJob) => taskJob.C_TaskInstance_key);
                    const sampleGroupSourceMaterialToDelete = jm.Material?.SampleGroupSourceMaterial
                        .filter((x: SampleGroupSourceMaterial) => x.SampleGroup && taskInstanceKeys.includes(x.SampleGroup.C_TaskInstance_key));

                    if (sampleGroupSourceMaterialToDelete) {
                        sampleGroupSourceMaterialToDelete.forEach((element: SampleGroupSourceMaterial) => {
                            this.dataManagerService.deleteEntity(element);
                        });
                    }
                    this.jobService.deleteJobMaterial(jm);
                }
            }
        });
    }
}
