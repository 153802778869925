<table class="table table-bordered job-pharma-samples-individual">
    <thead>
        <tr class="detail-grid-header">
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allRowsSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>

            <!-- Paste/Remove -->
            <th *ngIf="!readonly">
                <a class="detail-grid-button"
                   (click)="onPasteSamples()"
                >
                    <i data-automation-id="paste-samples-button"
                       class="fa fa-paste" 
                       title="Paste Samples"></i>
                </a>
            </th>

            <!-- Name -->
            <th *ngIf="visibleColumns.name"
                droppable
                [dropDisabled]="readonly"
                (drop)="onDropSamples()"
                [attr.title]="readonly ? '' : 'Drop samples here'">
                Name
            </th>
            <th *ngIf="visibleColumns.type">Type</th>
            <th *ngIf="visibleColumns.timePoint && activeFields.includes('TimePoint')" >Time Point</th>
            <th *ngIf="visibleColumns.status">Status</th>
            <th *ngIf="visibleColumns.harvestDate && activeFields.includes('DateHarvest')">Harvest Date</th>
            <th *ngIf="visibleColumns.expirationDate && activeFields.includes('DateExpiration')">Expiration Date</th>
            <th *ngIf="visibleColumns.preservation && activeFields.includes('C_PreservationMethod_key')">Preservation</th>
            <th *ngIf="visibleColumns.container && activeFields.includes('Material.C_ContainerType_key')">Container</th>
            <th *ngIf="visibleColumns.source && activeFields.includes('Material.MaterialSourceMaterial[0]')">Source</th>
            <th *ngIf="visibleColumns.location && activeFields.includes('Location')">Location</th>
            <th *ngIf="visibleColumns.subtype && activeFields.includes('C_SampleSubtype_key')">Subtype</th>
            <th *ngIf="visibleColumns.processing && activeFields.includes('C_SampleProcessingMethod_key')">Processing</th>
            <th *ngIf="visibleColumns.sendTo && activeFields.includes('SendTo')">Send To</th>
            <th *ngIf="visibleColumns.analysis && activeFields.includes('C_SampleAnalysisMethod_key')">Analysis</th>
            <th *ngIf="visibleColumns.specialInstructions && activeFields.includes('SpecialInstructions')">Special Instructions</th>
        </tr>
    </thead>
    <!-- open -->
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <tbody *ngIf="!loading">
        <tr *ngFor="let jobMaterial of sampleJobMaterials |
                paginate: { itemsPerPage: 50, currentPage: page, id: 'samples-individual-pagination', totalItems: sampleCount };
                let index = index"
            [attr.data-key]="jobMaterial?.C_Material_key"
            [ngClass]="{'selected-row': jobMaterial?.isSelected}"
            class="job-pharma-samples-individual-row">
            <ng-container *ngIf="jobMaterial?.Material?.Sample as sample">
                <!-- Select -->
                <td *ngIf="!readonly"
                    class="icon-cell">
                    <input type="checkbox"
                           name="select-samples-individual-{{index}}"
                           [(ngModel)]="jobMaterial.isSelected"
                           (ngModelChange)="isSelectedChanged()" />
                </td>

                <!-- Remove -->
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a (click)="removeSampleJobMaterial(jobMaterial)" class="remove-individual-sample">
                        <i class="fa fa-remove remove-item" title="Remove Sample"></i>
                    </a>
                </td>

                <!-- Name -->
                <td *ngIf="visibleColumns.name">
                    <div style="cursor:move;"
                         data-drag-type="material"
                         draggable
                         (dragStart)="dragStart()"
                         (dragStop)="dragStop()"
                         id="drag-samples-individual-{{sample.C_Material_key}}"
                         class="sample-individual-name">
                        {{sample.SampleName}}
                    </div>
                </td>

                <!-- Type -->
                <td *ngIf="visibleColumns.type">
                    {{sample.cv_SampleType?.SampleType}}
                </td>

                <!-- Time Point -->
                <td *ngIf="visibleColumns.timePoint && activeFields.includes('TimePoint')">
                    {{sample.TimePoint | formatTimePoint : sample.cv_TimeUnit}}
                </td>

                <!-- Status -->
                <td *ngIf="visibleColumns.status">
                    {{sample.cv_SampleStatus?.SampleStatus}}
                </td>

                <!-- Harvest Date -->
                <td *ngIf="visibleColumns.harvestDate && activeFields.includes('DateHarvest')">
                    {{sample.DateHarvest | formatShortDate}}
                </td>

                <!-- Expiration Date -->
                <td *ngIf="visibleColumns.expirationDate && activeFields.includes('DateExpiration')">
                    {{sample.DateExpiration | formatShortDate}}
                </td>

                <!-- Preservation -->
                <td *ngIf="visibleColumns.preservation && activeFields.includes('C_PreservationMethod_key')">
                    {{sample.cv_PreservationMethod?.PreservationMethod}}
                </td>

                <!-- Container -->
                <td *ngIf="visibleColumns.container && activeFields.includes('Material.C_ContainerType_key')">
                    {{sample.Material.cv_ContainerType?.ContainerType}}
                </td>

                <!-- Source -->
                <td *ngIf="visibleColumns.source && activeFields.includes('Material.MaterialSourceMaterial[0]')">
                    <span *ngFor="let source of jobMaterial.Material?.MaterialSourceMaterial; let last = last">
                        {{source.SourceMaterial?.Animal?.AnimalName}}{{source.SourceMaterial?.Sample?.SampleName}}<span *ngIf="!last">, </span>
                    </span>
                </td>

                <!-- Location -->
                <td *ngIf="visibleColumns.location && activeFields.includes('Location')">
                    {{sample.Material.CurrentLocationPath}}
                </td>

                <!-- Subtype -->
                <td *ngIf="visibleColumns.subtype && activeFields.includes('C_SampleSubtype_key')">
                    {{sample.cv_SampleSubtype?.SampleSubtype}}
                </td>

                <!-- Processing -->
                <td *ngIf="visibleColumns.processing && activeFields.includes('C_SampleProcessingMethod_key')">
                    {{sample.cv_SampleProcessingMethod?.SampleProcessingMethod}}
                </td>

                <!-- Send To -->
                <td *ngIf="visibleColumns.sendTo && activeFields.includes('SendTo')">
                    {{sample.SendTo}}
                </td>

                <!-- Analysis -->
                <td *ngIf="visibleColumns.analysis && activeFields.includes('C_SampleAnalysisMethod_key')">
                    {{sample.cv_SampleAnalysisMethod?.SampleAnalysisMethod}}
                </td>

                <!-- Special Instructions -->
                <td *ngIf="visibleColumns.specialInstructions && activeFields.includes('SpecialInstructions')">
                    {{sample.SpecialInstructions}}
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>

<pagination-controls (pageChange)="changePage($event)"
                     (pageBoundsCorrection)="changePage($event)"
                     [autoHide]="true"
                     class="ngx-table-paginator"
                     id="samples-individual-pagination"></pagination-controls>
