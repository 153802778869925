import { TranslationService } from '../services/translation.service';
import { sortObjectArrayByProperty } from '../common/util/sort-array';

/**
 * Returns sorted configuration list for all vocabularies in vocab facet
 * @param translationService - translation service
 */
export function buildMasterCvList(translationService: TranslationService, isGLP: boolean): CVListItem[] {
    let cvList: CVListItem[] = [
        {
            friendlyName: 'Animal Statuses',
            tableName: 'cv_AnimalStatus',
            tableNameForProvider: 'cv_AnimalStatuses',
            fieldName: 'AnimalStatus',
            keyName: 'C_AnimalStatus_key',
            hasEndState: true,
            canDelete: true,
            endStateFieldName: 'IsExitStatus',
            hasDefaultHealthRecordStatus: true,
            defaultHealthRecordStatusRequired: false,
            isSelected: true,
            nullable: true,
        },
        {
            friendlyName: 'Animal Uses',
            tableName: 'cv_AnimalUse',
            tableNameForProvider: 'cv_AnimalUses',
            fieldName: 'AnimalUse',
            keyName: 'C_AnimalUse_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Animal/Sample Origins',
            tableName: 'cv_MaterialOrigin',
            tableNameForProvider: 'cv_MaterialOrigins',
            fieldName: 'MaterialOrigin',
            keyName: 'C_MaterialOrigin_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Birth') + ' Statuses',
            tableName: 'cv_BirthStatus',
            tableNameForProvider: 'cv_BirthStatuses',
            fieldName: 'BirthStatus',
            keyName: 'C_BirthStatus_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Body Condition Scores',
            tableName: 'cv_BodyConditionScore',
            tableNameForProvider: 'cv_BodyConditionScores',
            fieldName: 'BodyConditionScore',
            keyName: 'C_BodyConditionScore_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Breeding Statuses',
            tableName: 'cv_BreedingStatus',
            tableNameForProvider: 'cv_BreedingStatuses',
            fieldName: 'BreedingStatus',
            keyName: 'C_BreedingStatus_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Census Discrepancies',
            tableName: 'cv_CensusDiscrepancy',
            tableNameForProvider: 'cv_CensusDiscrepancies',
            fieldName: 'CensusDiscrepancy',
            keyName: 'C_CensusDiscrepancy_key',           
            hasEndState: true,
            endStateFieldName: 'IsEndState',
            endStateTitle: 'Discrepancy',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Census Statuses',
            tableName: 'cv_CensusStatus',
            tableNameForProvider: 'cv_CensusStatuses',
            fieldName: 'CensusStatus',
            keyName: 'C_CensusStatus_key',
            canDelete: true,
            nullable: false,
        },
        {
            friendlyName: 'Clinical Observations',
            tableName: 'cv_ClinicalObservation',
            tableNameForProvider: 'cv_ClinicalObservations',
            fieldName: 'ClinicalObservation',
            keyName: 'C_ClinicalObservation_key',
            canDelete: true,
            nullable: true,
            noDefault: isGLP,
            subFields: [
                {
                    friendlyName: 'Modifier1',
                    tableName: 'cv_Modifier1',
                    tableNameForProvider: 'cv_Modifiers1',
                    fieldName: 'Modifier1',
                    keyName: 'C_Modifier1_key',
                    canDelete: true,
                    nullable: true,
                    noDefault: true
                },
                {
                    friendlyName: 'Modifier2',
                    tableName: 'cv_Modifier2',
                    tableNameForProvider: 'cv_Modifiers2',
                    fieldName: 'Modifier2',
                    keyName: 'C_Modifier2_key',
                    canDelete: true,
                    nullable: true,
                    noDefault: true
                },
                {
                    friendlyName: 'Modifier3',
                    tableName: 'cv_Modifier3',
                    tableNameForProvider: 'cv_Modifiers3',
                    fieldName: 'Modifier3',
                    keyName: 'C_Modifier3_key',
                    canDelete: true,
                    nullable: true,
                    noDefault: true
                },
                {
                    friendlyName: 'Modifier4',
                    tableName: 'cv_Modifier4',
                    tableNameForProvider: 'cv_Modifiers4',
                    fieldName: 'Modifier4',
                    keyName: 'C_Modifier4_key',
                    canDelete: true,
                    nullable: true,
                    noDefault: true
                },

            ]
        },
        {
            friendlyName: 'Clinical Observation Statuses',
            tableName: 'cv_ClinicalObservationStatus',
            tableNameForProvider: 'cv_ClinicalObservationStatuses',
            fieldName: 'ClinicalObservationStatus',
            keyName: 'C_ClinicalObservationStatus_key',
            // Only allow setting end state if isGLP
            hasEndState: isGLP,
            endStateFieldName: 'IsEndStatus',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Contact') + ' Types',
            tableName: 'cv_ContactPersonType',
            tableNameForProvider: 'cv_ContactPersonTypes',
            fieldName: 'ContactPersonType',
            keyName: 'C_ContactPersonType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Compliance',
            tableName: 'cv_Compliance',
            tableNameForProvider: 'cv_Compliances',
            fieldName: 'Compliance',
            keyName: 'C_Compliance_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Container Types',
            tableName: 'cv_ContainerType',
            tableNameForProvider: 'cv_ContainerTypes',
            fieldName: 'ContainerType',
            keyName: 'C_ContainerType_key',
            canDelete: true,
            nullable: true,
            noDefault: true
        },
        {
            friendlyName: 'Couriers',
            tableName: 'cv_Courier',
            tableNameForProvider: 'cv_Couriers',
            fieldName: 'Courier',
            keyName: 'C_Courier_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Diets',
            tableName: 'cv_Diet',
            tableNameForProvider: 'cv_Diets',
            fieldName: 'Diet',
            keyName: 'C_Diet_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Exit Reasons',
            tableName: 'cv_ExitReason',
            tableNameForProvider: 'cv_ExitReasons',
            fieldName: 'ExitReason',
            keyName: 'C_ExitReason_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Generations',
            tableName: 'cv_Generation',
            tableNameForProvider: 'cv_Generations',
            fieldName: 'Generation',
            keyName: 'C_Generation_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Genotype Assays',
            tableName: 'cv_GenotypeAssay',
            tableNameForProvider: 'cv_GenotypeAssays',
            fieldName: 'GenotypeAssay',
            keyName: 'C_GenotypeAssay_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Genotypes',
            tableName: 'cv_GenotypeSymbol',
            tableNameForProvider: 'cv_GenotypeSymbols',
            fieldName: 'GenotypeSymbol',
            keyName: 'C_GenotypeSymbol_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Housing Status',
            tableName: 'cv_MaterialPoolStatus',
            tableNameForProvider: 'cv_MaterialPoolStatuses',
            fieldName: 'MaterialPoolStatus',
            keyName: 'C_MaterialPoolStatus_key',
            hasEndState: true,
            endStateFieldName: 'IsEndState',
            hasDefaultEndState: true,
            defaultEndStateRequired: false,
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Housing Types',
            tableName: 'cv_MaterialPoolType',
            tableNameForProvider: 'cv_MaterialPoolTypes',
            fieldName: 'MaterialPoolType',
            keyName: 'C_MaterialPoolType_key',
            canDelete: false,
            hasMaterialDefault: true,
            nullable: false,
        },
        {
            friendlyName: 'IACUC Protocols',
            tableName: 'cv_IACUCProtocol',
            tableNameForProvider: 'cv_IACUCProtocols',
            fieldName: 'IACUCProtocol',
            keyName: 'C_IACUCProtocol_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Institution') + ' Types',
            tableName: 'cv_InstitutionType',
            tableNameForProvider: 'cv_InstitutionTypes',
            fieldName: 'InstitutionType',
            keyName: 'C_InstitutionType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Job') + ' Statuses',
            tableName: 'cv_JobStatus',
            tableNameForProvider: 'cv_JobStatuses',
            fieldName: 'JobStatus',
            keyName: 'C_JobStatus_key',
            hasEndState: true,
            endStateFieldName: 'IsEndState',
            hasDraft: true,
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Job') + ' Types',
            tableName: 'cv_JobType',
            tableNameForProvider: 'cv_JobTypes',
            fieldName: 'JobType',
            keyName: 'C_JobType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Job') + ' Subtypes',
            tableName: 'cv_JobSubtype',
            tableNameForProvider: 'cv_JobSubtypes',
            fieldName: 'JobSubtype',
            keyName: 'C_JobSubtype_key',
            canDelete: true,
            nullable: true,
            hideForNotCRO: true
        },
        {
            friendlyName: translationService.translate('Line') + ' Types',
            tableName: 'cv_LineType',
            tableNameForProvider: 'cv_LineTypes',
            fieldName: 'LineType',
            keyName: 'C_LineType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Location Types',
            tableName: 'cv_LocationType',
            tableNameForProvider: 'cv_LocationTypes',
            fieldName: 'LocationType',
            keyName: 'C_LocationType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Line') + ' Statuses',
            tableName: 'cv_LineStatus',
            tableNameForProvider: 'cv_LineStatuses',
            fieldName: 'LineStatus',
            keyName: 'C_LineStatus_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Mating Purposes',
            tableName: 'cv_MatingPurpose',
            tableNameForProvider: 'cv_MatingPurposes',
            fieldName: 'MatingPurpose',
            keyName: 'C_MatingPurpose_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Mating Statuses',
            tableName: 'cv_MatingStatus',
            tableNameForProvider: 'cv_MatingStatuses',
            fieldName: 'MatingStatus',
            keyName: 'C_MatingStatus_key',
            hasEndState: true,
            endStateFieldName: 'IsExitStatus',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Mating Types',
            tableName: 'cv_MatingType',
            tableNameForProvider: 'cv_MatingTypes',
            fieldName: 'MatingType',
            keyName: 'C_MatingType_key',
            canDelete: false,
            nullable: true,
        },
        {
            friendlyName: 'Marker Types',
            tableName: 'cv_PhysicalMarkerType',
            tableNameForProvider: 'cv_PhysicalMarkerTypes',
            fieldName: 'PhysicalMarkerType',
            keyName: 'C_PhysicalMarkerType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Payment Terms',
            tableName: 'cv_PaymentTerm',
            tableNameForProvider: 'cv_PaymentTerms',
            fieldName: 'PaymentTerm',
            keyName: 'C_PaymentTerm_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Plate Statuses',
            tableName: 'cv_PlateStatus',
            tableNameForProvider: 'cv_PlateStatuses',
            fieldName: 'PlateStatus',
            keyName: 'C_PlateStatus_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Preservation Methods',
            tableName: 'cv_PreservationMethod',
            tableNameForProvider: 'cv_PreservationMethods',
            fieldName: 'PreservationMethod',
            keyName: 'C_PreservationMethod_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Resource Groups',
            tableName: 'cv_ResourceGroup',
            tableNameForProvider: 'cv_ResourceGroups',
            fieldName: 'ResourceGroup',
            keyName: 'C_ResourceGroup_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Resource Types',
            tableName: 'cv_ResourceType',
            tableNameForProvider: 'cv_ResourceTypes',
            fieldName: 'ResourceType',
            keyName: 'C_ResourceType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Sample Analysis',
            tableName: 'cv_SampleAnalysisMethod',
            tableNameForProvider: 'cv_SampleAnalysisMethods',
            fieldName: 'SampleAnalysisMethod',
            keyName: 'C_SampleAnalysisMethod_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Sample Conditions',
            tableName: 'cv_SampleCondition',
            tableNameForProvider: 'cv_SampleConditions',
            fieldName: 'SampleCondition',
            keyName: 'C_SampleCondition_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Sample Processing',
            tableName: 'cv_SampleProcessingMethod',
            tableNameForProvider: 'cv_SampleProcessingMethods',
            fieldName: 'SampleProcessingMethod',
            keyName: 'C_SampleProcessingMethod_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Sample Statuses',
            tableName: 'cv_SampleStatus',
            tableNameForProvider: 'cv_SampleStatuses',
            fieldName: 'SampleStatus',
            keyName: 'C_SampleStatus_key',
            hasEndState: false,
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Sample Subtypes',
            tableName: 'cv_SampleSubtype',
            tableNameForProvider: 'cv_SampleSubtypes',
            fieldName: 'SampleSubtype',
            keyName: 'C_SampleSubtype_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Sample Types',
            tableName: 'cv_SampleType',
            tableNameForProvider: 'cv_SampleTypes',
            fieldName: 'SampleType',
            keyName: 'C_SampleType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Site') + ' Types',
            tableName: 'cv_SiteType',
            tableNameForProvider: 'cv_SiteTypes',
            fieldName: 'SiteType',
            keyName: 'C_SiteType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Standard Phrases',
            tableName: 'cv_StandardPhrase',
            tableNameForProvider: 'cv_StandardPhrases',
            fieldName: 'StandardPhrase',
            keyName: 'C_StandardPhrase_key',
            canDelete: true,
            nullable: true,
            noDefault: true,
            hideForNotCRL: true
        },
        {
            friendlyName: 'Variable Phrase Types',
            tableName: 'cv_VariablePhraseType',
            tableNameForProvider: 'cv_VariablePhraseTypes',
            fieldName: 'VariablePhraseType',
            keyName: 'C_VariablePhraseType_key',
            canDelete: true,
            nullable: true,
            noDefault: true
        },
        {
            friendlyName: translationService.translate('Job') + ' Reports',
            tableName: 'cv_JobReport',
            tableNameForProvider: 'cv_JobReports',
            fieldName: 'JobReport',
            keyName: 'C_JobReport_key',
            canDelete: true,
            nullable: true,
            noDefault: true,
            hideForNotCRL: true
        },
        {
            friendlyName: translationService.translate('Study') + ' Statuses',
            tableName: 'cv_StudyStatus',
            tableNameForProvider: 'cv_StudyStatuses',
            fieldName: 'StudyStatus',
            keyName: 'C_StudyStatus_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: translationService.translate('Study') + ' Types',
            tableName: 'cv_StudyType',
            tableNameForProvider: 'cv_StudyTypes',
            fieldName: 'StudyType',
            keyName: 'C_StudyType_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Task Statuses',
            tableName: 'cv_TaskStatus',
            tableNameForProvider: 'cv_TaskStatuses',
            fieldName: 'TaskStatus',
            keyName: 'C_TaskStatus_key',
            hasEndState: true,
            endStateFieldName: 'IsEndState',
            hasDefaultEndState: true,
            defaultEndStateRequired: true,            
            hasDefaultAutoEndState: true,
            defaultAutoEndStateRequired: false,
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Task Outcomes',
            tableName: 'cv_TaskOutcome',
            tableNameForProvider: 'cv_TaskOutcomes',
            fieldName: 'TaskOutcome',
            keyName: 'C_TaskOutcome_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Test Article',
            tableName: 'cv_TestArticle',
            tableNameForProvider: 'cv_TestArticles',
            fieldName: 'TestArticle',
            keyName: 'C_TestArticle_key',
            canDelete: true,
            nullable: true,
            hideForDTX: true
        },
        {
            friendlyName: 'Units',
            tableName: 'cv_Unit',
            tableNameForProvider: 'cv_Units',
            fieldName: 'Unit',
            keyName: 'C_Unit_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Quarantine Facilities',
            tableName: 'cv_QuarantineFacility',
            tableNameForProvider: 'cv_QuarantineFacilities',
            fieldName: 'QuarantineFacility',
            keyName: 'C_QuarantineFacility_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Animal Condition  at Receipt',
            tableName: 'cv_ShipmentAnimalCondition',
            tableNameForProvider: 'cv_ShipmentAnimalConditions',
            fieldName: 'ShipmentAnimalCondition',
            keyName: 'C_ShipmentAnimalCondition_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Shipment Container Condition',
            tableName: 'cv_ShipmentContainerCondition',
            tableNameForProvider: 'cv_ShipmentContainerConditions',
            fieldName: 'ShipmentContainerCondition',
            keyName: 'C_ShipmentContainerCondition_key',
            canDelete: true,
            nullable: true,
        },
        {
            friendlyName: 'Animal Comment Status',
            tableName: 'cv_AnimalCommentStatus',
            tableNameForProvider: 'cv_AnimalCommentStatuses',
            fieldName: 'AnimalCommentStatus',
            keyName: 'C_AnimalCommentStatus_key',
            canDelete: true,
            nullable: true,
            hasEndState: true,
            endStateFieldName: 'IsEndState',
            hasPermanent: true,
            permanentFieldName: 'IsPermanent',
        },
        {
            friendlyName: 'Task Flag Message',
            tableName: 'cv_TaskFlagMessage',
            tableNameForProvider: 'cv_TaskFlagMessages',
            fieldName: 'TaskFlagMessage',
            keyName: 'C_TaskFlagMessage_key',
            canDelete: true,
            nullable: true,
            noDefault: true
        },
    ];

    if (isGLP) {
        cvList = [...cvList, ...[
            {
                friendlyName: 'Social Group Access Levels',
                tableName: 'cv_SocialGroupAccessLevel',
                tableNameForProvider: 'cv_SocialGroupAccessLevels',
                fieldName: 'SocialGroupAccessLevel',
                keyName: 'C_SocialGroupAccessLevel_key',
                canDelete: true,
                nullable: true,
            },
            {
                friendlyName: 'Compatibility Access Levels',
                tableName: 'cv_CompatibilityAccessLevel',
                tableNameForProvider: 'cv_CompatibilityAccessLevels',
                fieldName: 'CompatibilityAccessLevel',
                keyName: 'C_CompatibilityAccessLevel_key',
                canDelete: true,
                nullable: true,
            },
            {
                friendlyName: 'Animal Classification',
                tableName: 'cv_AnimalClassification',
                tableNameForProvider: 'cv_AnimalClassifications',
                fieldName: 'AnimalClassification',
                keyName: 'C_AnimalClassification_key',
                canDelete: true,
                nullable: true,
            },
            {
                friendlyName: 'Route of Administration',
                tableName: 'cv_RouteOfAdministration',
                tableNameForProvider: 'cv_RouteOfAdministrations',
                fieldName: 'RouteOfAdministration',
                keyName: 'C_RouteOfAdministration_key',
                canDelete: true,
                nullable: true,
            },
            {
                friendlyName: 'Veterinary Request Status',
                tableName: 'cv_VeterinaryRequestStatus',
                tableNameForProvider: 'cv_VeterinaryRequestStatuses',
                fieldName: 'VeterinaryRequestStatus',
                keyName: 'C_VeterinaryRequestStatus_key',
                canDelete: true,
                nullable: true,
                hasEndState: true,
                endStateFieldName: 'IsEndState',
            },
        ]];
    }

    return sortObjectArrayByProperty(cvList, 'friendlyName');
}

export class CVListItem {
    friendlyName: string;
    tableName: string;
    tableNameForProvider: string;
    fieldName: string;
    keyName: string;

    // Whether table has an end state flag
    hasEndState?: boolean;
    // If so, need to specify the column name
    endStateFieldName?: string;
    // If so, need to specify the title name
    endStateTitle?: string;

    // Whether table has IsDefaultEndState flag
    hasDefaultEndState?: boolean;
    // Is it required?
    defaultEndStateRequired?: boolean;

    // Whether table has IsDefaultHealthRecord flag
    hasDefaultHealthRecordStatus?: boolean;
    // Is it required?
    defaultHealthRecordStatusRequired?: boolean;

    // Flag which vocab is selected in the facet view.
    // Should only be one.
    isSelected?: boolean;

    canDelete: boolean;

    nullable: boolean;
    hasDefaultAutoEndState?: boolean;
    hasDraft?: boolean;
    subFields?: CVListItem[];
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    IsActive?: boolean;

    hideForNotGLP?: boolean;
    hideForNotCRL?: boolean;
    hideForNotCRO?: boolean;
    hideForDTX?: boolean;
    
    noDefault?: boolean;
    defaultAutoEndStateRequired?: boolean;
    hasMaterialDefault?: boolean;
    hasPermanent?: boolean;
    permanentFieldName?: string;
}
