import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    Output,
    EventEmitter,
    ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';
import { softCompare } from '@common/util';

import { DataManagerService } from '@services/data-manager.service';
import {
    JobPharmaDetailService, SampleGroupChangesMap,
} from '../services/job-pharma-detail.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { LocationService } from '../../../locations/location.service';
import { ConfirmService } from '@common/confirm';
import { Entity, SampleGroup } from '@common/types';
import { IBeforeSaveInterceptor, SaveCancellationToken, SaveChangesService } from '@services/save-changes.service';
import { SaveRecordsOverlayEvent } from './job-pharma-tasks-list-table.component';
import { isEmpty } from '@lodash';
import { FacetView, IFacet } from '@common/facet';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';
import { DataContextService } from '@services/data-context.service';
import { isAnyTaskEndState } from 'src/app/tasks/util/is-any-task-end-state';

interface DataRow {
    task: any;
    sampleGroup: SampleGroup;
    numSources: number;
    numSourceAnimals?: number;
    numSourceSamples?: number;
    numSourceAnimalPlaceholders?: number;
    taskFirst?: boolean;
    isSelected?: boolean;

    classes?: {
        [key: string]: { [key: string]: boolean };
    };
}

@Component({
    selector: 'job-pharma-samples-groups-table',
    templateUrl: './job-pharma-samples-groups-table.component.html',
})
export class JobPharmaSamplesGroupsTableComponent implements OnChanges, OnDestroy, OnInit, IBeforeSaveInterceptor {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChildren('harvestDateControl') harvestDateControls: NgModel[];
    @ViewChildren('expirationDateControl') expirationDateControls: NgModel[];
    @Input() readonly: boolean;
    @Input() job: any;

    @Input() tabset = 'samples';
    @Input() tab = 'groups';

    @Input() requiredFields: string[] = [];
    @Input() activeFields: string[] = [];

    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    tasks: any[];
    rows: DataRow[];

    loading = false;
    loadingMessage = "Loading";

    // Column selections
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };

    // Visible columns
    visible: any = {};

    // Are all the rows selected?
    allSelected = false;

    // All subscriptions
    subs = new Subscription();

    // CVs
    preservationMethods: any[];
    sampleStatuses: any[];
    containerTypes: any[];
    sampleTypes: any[];
    timeUnits: any[];
    sampleSubtypes: any[];
    sampleAnalysisMethods: any[];
    sampleProcessingMethods: any[];


    // Bulk update placeholders
    bulkNumSamples: number;
    bulkPreservationMethodKey: number;
    bulkSampleStatusKey: number;
    bulkSampleTypeKey: number;
    bulkContainerTypeKey: number;
    bulkDateHarvest: Date;
    bulkDateExpiration: Date;
    bulkTimePoint: number;
    bulkTimeUnitKey: number;
    bulkSampleSubtypeKey: string;
    bulkSampleProcessingMethodKey: string;
    bulkSendTo: string;
    bulkSampleAnalysisMethodKey: string;
    bulkSpecialInstructions: string;

    bulkExpanded = false;

    originalSampleGroupChangesMap: SampleGroupChangesMap = {};
    pendingSampleGroupChangesMap: SampleGroupChangesMap = {};
 
    readonly COMPONENT_LOG_TAG = 'job-pharma-samples-groups-table';

    isSampleGroupsEditableFlag: boolean;

    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    constructor(
        private dataManager: DataManagerService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private vocabularyService: VocabularyService,
        private locationService: LocationService,
        private confirmService: ConfirmService,
        private saveChangesService: SaveChangesService,
        private loggingService: LoggingService,
        private dataContext: DataContextService
    ) {
        // Do nothing
    }

    async ngOnInit() {
        this.saveChangesService.registerBeforeSaveInterceptor(this);
        this.loading = true;
        this.initFF();
        this.initChangeDetection();
        this.initTabActions();
        this.initColumnSelect();
        await this.initialize();
        this.initChangeDetection();
        this.loading = false;
    }
    
    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterBeforeSaveInterceptor(this);
        this.clearSelections();

        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    initFF() {
        this.isSampleGroupsEditableFlag = this.jobPharmaDetailService.isSampleGroupsEditableFlag();
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        // TODO: What to watch?
        /*
        // Watch for changes to Job.JobMaterial
        this.subs.add(this.jobPharmaDetailService.jobMaterialsChanged$.subscribe(() => {
            this.initJob();
        }));
        */
        this.subs.add(this.jobPharmaDetailService.jobTasksChanged$.subscribe(() => {
            this.initJob();
        }));
    }


    /**
     * Watch for event between the tabs
     */
    initTabActions() {
        // Listen for calls to refresh the view
        this.subs.add(
            this.jobPharmaDetailService.tabRefresh$.subscribe((event) => {
                if (event.tabset === 'samples' && event.tab === 'groups') {
                    this.initJob();
                }
            })
        );
    }

    initialize(): Promise<any> {
        return this.getCVs().then(() => {
            return this.initJob();
        });
    }

    getCVs(): Promise<any> {
        return Promise.all([
            this.vocabularyService.getCV('cv_PreservationMethods').then((data: any[]) => {
                this.preservationMethods = data;
            }),
            this.vocabularyService.getCV('cv_SampleStatuses').then((data: any[]) => {
                this.sampleStatuses = data;
            }),
            this.locationService.getContainerTypes('Sample').then((data) => {
                this.containerTypes = data;
            }),
            this.vocabularyService.getCV('cv_SampleTypes').then((data: any[]) => {
                this.sampleTypes = data;
            }),
            this.vocabularyService.getCV('cv_TimeUnits').then((data: any[]) => {
                this.timeUnits = data;
            }),
            this.vocabularyService.getCV('cv_SampleSubtypes').then((data: any[]) => {
                this.sampleSubtypes = data;
            }),
            this.vocabularyService.getCV('cv_SampleProcessingMethods').then((data: any[]) => {
                this.sampleProcessingMethods = data;
            }),
            this.vocabularyService.getCV('cv_SampleAnalysisMethods').then((data: any[]) => {
                this.sampleAnalysisMethods = data;
            }),
        ]);
    }

    public getSelectedSampleGroups(): SampleGroup[] {
        const selectedRows = this.rows.filter((row) => {
            return row.isSelected;
        });

        return selectedRows.map((row) => {
            return row.sampleGroup;
        });
    }

    /**
     * Initialize the column selections
     */
    initColumnSelect() {
        // Default Visibility
        this.visible = {
            protocol: true,
            taskAlias: true,
            // name: true,
            numSources: true,
            numSamples: true,
            numTotal: true,
            type: true,
            status: true,
            preservation: true,
            containerType: true,
            harvestDate: true,
            expirationDate: true,
            timePoint: true,
            subtype: true,
            processing: true,
            sendTo: true,
            analysis: true,
            specialInstructions: true
        };


        // Assemble the list of all columns that can be selected
        this.columnSelect.labels = [
            new ColumnSelectLabel('protocol', 'Protocol'),
            new ColumnSelectLabel('taskAlias', 'Task'),
            // new ColumnSelectLabel('name', 'Name'),
            new ColumnSelectLabel('numSources', 'Sources'),
            new ColumnSelectLabel('numSamples', 'Samples per Source'),
            new ColumnSelectLabel('numTotal', 'Samples'),
            new ColumnSelectLabel('type', 'Type'),
            new ColumnSelectLabel('status', 'Status'),
            this.activeFields.includes("C_PreservationMethod_key") ? new ColumnSelectLabel('preservation', 'Preservation') : null,
            this.activeFields.includes("Material.C_ContainerType_key") ? new ColumnSelectLabel('container', 'Container') : null,
            this.activeFields.includes("DateHarvest") ? new ColumnSelectLabel('harvestDate', 'Harvest Date') : null,
            this.activeFields.includes("DateExpiration") ? new ColumnSelectLabel('expirationDate', 'Expiration Date') : null,
            this.activeFields.includes("TimePoint") ? new ColumnSelectLabel('timePoint', 'Time Point') : null,
            this.activeFields.includes("C_SampleSubtype_key") ? new ColumnSelectLabel('subtype', 'Subtype') : null,
            this.activeFields.includes("C_SampleProcessingMethod_key") ? new ColumnSelectLabel('processing', 'Processing') : null,
            this.activeFields.includes("SendTo") ? new ColumnSelectLabel('sendTo', 'Send To') : null,
            this.activeFields.includes("C_SampleAnalysisMethod_key") ? new ColumnSelectLabel('analysis', 'Analysis') : null,
            this.activeFields.includes("SpecialInstructions") ? new ColumnSelectLabel('specialInstructions', 'Special Instructions') : null
        ];

        this.columnSelect.labels = this.columnSelect.labels.filter((label: ColumnSelectLabel) => {
            return label !== null;
        });
        this.columnSelect.model = this.columnSelect.labels.filter(
            (item) => this.visible[item.key]
        ).map((item) => item.key);

        // Register the columns
        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, this.tab, this.columnSelect,
                () => { this.updateVisible(); }
            )
        );

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    // Initialize the list of SampleGroups
    async initJob(): Promise<any> {
        this.loading = true;
        const taskExpands = [
            'SampleGroup.Sample.Material.MaterialSourceMaterial.SourceMaterial',
            // These two are needed for counting the sources
            'TaskMaterial.MaterialSourceMaterial',
            'MemberTaskInstance.TaskMaterial.MaterialSourceMaterial',
            'SampleGroup.SampleGroupSourceMaterial.Material.Animal'
        ];
        await this.jobPharmaDetailService.loadTaskInstances(this.job.C_Job_key);
        // Prepare the tasks
        const tasks = await this.jobPharmaDetailService.initTasks(this.job, taskExpands);
        // Finally ready to show these tasks
        this.tasks = tasks;
        this.initRows();
        this.loading = false;
    }

    private initRows() {
        this.originalSampleGroupChangesMap = {};
        const rows: DataRow[] = [];
        for (const task of this.tasks) {
            const taskRows: DataRow[] = [];
            if (!task.SampleGroup || (task.SampleGroup.length === 0)) {
                continue;
            }

            for (const sampleGroup of task.SampleGroup) {
                const taskRow = {
                    task,
                    sampleGroup,
                    numSources: 0,
                    numSourceAnimals: sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.Material && sm.Material.Animal !== null).length,
                    numSourceSamples: sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.Material && sm.Material.Sample !== null).length,
                    numSourceAnimalPlaceholders: sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.AnimalPlaceholder && !sm.AnimalPlaceholder.Material).length,
                };
                taskRow.numSources = taskRow.numSourceAnimals + taskRow.numSourceSamples + taskRow.numSourceAnimalPlaceholders;
                taskRows.push(taskRow);
            }
            // Mark the first row of each task and add the task rows to the table
            taskRows.forEach((row: DataRow, index: number) => {
                row.taskFirst = (index === 0);
                rows.push(row);
            });

        }
        // Process all the rows
        for (const row of rows) {
            // Prepare the cell classes
            row.classes = {
                task: { 'task-extra': !row.taskFirst },
            };

            row.isSelected = false;

            this.originalSampleGroupChangesMap[row.sampleGroup.C_SampleGroup_key] = {
                sampleGroup: row.sampleGroup,
                C_ContainerType_key: row.sampleGroup.C_ContainerType_key,
                C_PreservationMethod_key: row.sampleGroup.C_PreservationMethod_key,
                C_SampleAnalysisMethod_key: row.sampleGroup.C_SampleAnalysisMethod_key,
                C_SampleProcessingMethod_key: row.sampleGroup.C_SampleProcessingMethod_key,
                C_SampleStatus_key: row.sampleGroup.C_SampleStatus_key,
                C_SampleSubtype_key: row.sampleGroup.C_SampleSubtype_key,
                C_SampleType_key: row.sampleGroup.C_SampleType_key,
                C_TimeUnit_key: row.sampleGroup.C_TimeUnit_key,
                DateExpiration: row.sampleGroup.DateExpiration,
                DateHarvest: row.sampleGroup.DateHarvest,
                NumSamples: row.sampleGroup.NumSamples,
                SendTo: row.sampleGroup.SendTo,
                TimePoint: row.sampleGroup.TimePoint ?? 0
            };
        }

        // Use these rows
        this.rows = rows;
        this.allSelected = false;
    }

    /**
     * Unselect all the samples
     */
    clearSelections() {
        if (this.rows) {
            // Reset samples selections
            this.rows.forEach((row) => { row.isSelected = false; });
        }
        this.allSelected = false;
    }

    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.rows) {
            for (const row of this.rows) {
                if (!this.isSampleGroupLocked(row.sampleGroup)) {
                    row.isSelected = this.allSelected;
                }
            }
        }
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.rows.every((row) => row.isSelected);
    }


    /**
     * 
     * TODO: Move to service
     * 
     * Remove a SampleGroup from the Task
     * 
     * Prompts to delete all samples and related task instances that are chiildren of the sample group being removed;
     * A save occurs to prevent a foreign key constraint error as the order of requests are not guaranteed and
     * the sample group deletion request can reach the backend before the sample records have been changed
     */
    async removeSampleGroup(row: DataRow): Promise<any> {
        const sampleGroup = row.sampleGroup as Entity<SampleGroup>;
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            if (this.jobPharmaDetailService.sampleGroupsTasksHasEndState([sampleGroup])) {
                return;
            }
            const samplesWithAssociationsDeleted = await this.jobPharmaDetailService.handleSampleAssociationDeletes([sampleGroup]);
            if (!isEmpty(samplesWithAssociationsDeleted)) {
                if (this.pendingSampleGroupChangesMap[sampleGroup.C_SampleGroup_key]) {
                    delete this.pendingSampleGroupChangesMap[sampleGroup.C_SampleGroup_key];
                }
            }
        }

        if (sampleGroup.Sample && sampleGroup.Sample.length > 0) {
            return;
        }

        // Remove the SampleGroup from the task
        if (sampleGroup.SampleGroupSourceMaterial && sampleGroup.SampleGroupSourceMaterial.length > 0) {
            while (sampleGroup.SampleGroupSourceMaterial.length > 0) {
                const sampleGroupSourceMaterial = sampleGroup.SampleGroupSourceMaterial[0];
                this.dataManager.deleteEntity(sampleGroupSourceMaterial);
            }
        }

        this.dataManager.deleteEntity(sampleGroup);

        this.initRows();

        this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
        this.jobPharmaDetailService.tabRefresh('tasks', 'list');
    }

    updateBulkNumSamples() {
        this.updateBulkValue('NumSamples', this.bulkNumSamples);
    }
    updateBulkPreservationMethodKey() {
        this.updateBulkValue('C_PreservationMethod_key', this.bulkPreservationMethodKey);
    }
    updateBulkSampleStatusKey() {
        this.updateBulkValue('C_SampleStatus_key', this.bulkSampleStatusKey);
    }
    updateBulkSampleTypeKey() {
        this.updateBulkValue('C_SampleType_key', this.bulkSampleTypeKey);
    }
    updateBulkContainerTypeKey() {
        this.updateBulkValue('C_ContainerType_key', this.bulkContainerTypeKey);
    }
    updateBulkDateHarvest() {
        const msg = dateControlValidator(this.harvestDateControls);
        if (msg) {
            this.loggingService.logError(msg, null, '', true);
            return;
        }
        this.updateBulkValue('DateHarvest', this.bulkDateHarvest);
    }
    updateBulkDateExpiration() {
        const msg = dateControlValidator(this.expirationDateControls);
        if (msg) {
            this.loggingService.logError(msg, null, '', true);
            return;
        }
        this.updateBulkValue('DateExpiration', this.bulkDateExpiration);
    }
    updateBulkTimePoint() {
        this.updateBulkValue('TimePoint', this.bulkTimePoint);
        this.updateBulkValue('C_TimeUnit_key', this.bulkTimeUnitKey);
    }
    updateBulkSampleSubtypeKey() {
        this.updateBulkValue('C_SampleSubtype_key', this.bulkSampleSubtypeKey);
    }
    updateBulkSampleProcessingMethodKey() {
        this.updateBulkValue('C_SampleProcessingMethod_key', this.bulkSampleProcessingMethodKey);
    }
    updateBulkSampleAnalysisMethodKey() {
        this.updateBulkValue('C_SampleAnalysisMethod_key', this.bulkSampleAnalysisMethodKey);
    }
    updateBulkSendTo() {
        this.updateBulkValue('SendTo', this.bulkSendTo);
    }
    updateBulkSpecialInstructions() {
        this.updateBulkValue('SpecialInstructions', this.bulkSpecialInstructions);
    }

    // Bulk update handlers
    updateBulkValue(key: string, value: any) {
        if (this.readonly) {
            return;
        }
        // Update the rows in the main table
        if (this.rows) {
            for (const row of this.rows) {
                if (row.sampleGroup && (!row.sampleGroup.Sample.length || this.isSampleGroupsEditableFlag)) {
                    if (this.jobPharmaDetailService.sampleGroupsTasksHasEndState([row.sampleGroup])) {
                        continue;
                    }
                    row.sampleGroup[key] = value;
                    if (row.sampleGroup.Sample.length > 0) {
                        this.recordSampleGroupChange(value, row, key);
                    }
                }
            }
        }
    }

    // <select> formatters
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }

    removeSampleGroupSourceMaterial(sampleGroup: SampleGroup, sourceMaterial: any) {
        if ((this.jobPharmaDetailService.isSampleGroupsEditableFlag() && this.jobPharmaDetailService.sampleGroupsTasksHasEndState([sampleGroup])) || this.readonly) {
            return;
        }
        // Show modal and ask confirmation if delete from single or all instances or ignore
        this.confirmService.confirm({
            title: "Delete Sample Group",
            message: `Delete sample group? Note: Only the sample group will be deleted for the individual animal.
                      Choosing to delete all samples groups for the animal will remove all sample groups without impacting tasks.`,
            yesButtonText: "Delete Instance",
            noButtonText: "Cancel",
            extraButton: true,
            extraButtonText: "Delete All",
        })
            .then((result: any) => {
                if (softCompare(result, "extra")) {
                    this.rows.forEach((row: any) => {
                        if (row.sampleGroup.Sample.length <= 0) {
                            // delete only if samples arent created
                            row.sampleGroup.SampleGroupSourceMaterial.forEach((sm: any) => {
                                if (sourceMaterial.C_Material_key) {
                                    if (sm.C_Material_key === sourceMaterial.C_Material_key) {
                                        this.dataManager.deleteEntity(sm);
                                    }
                                } else if (sourceMaterial.C_AnimalPlaceholder_key) {
                                    if (sm.C_AnimalPlaceholder_key === sourceMaterial.C_AnimalPlaceholder_key) {
                                        this.dataManager.deleteEntity(sm);
                                    }
                                }
                            });
                        }
                    });
                } else {
                    this.dataManager.deleteEntity(sourceMaterial);
                }
                this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
                this.jobPharmaDetailService.tabRefresh('tasks', 'list');
                this.initRows();
            }).catch((error: any) => {
                // No selected
            });

    }

    bulkExpandClick() {
        this.bulkExpanded = !this.bulkExpanded;
        this.rows.forEach((row: any) => {
            row.expanded = this.bulkExpanded;
        });
    }

    rowExpandClick(row: any) {
        row.expanded = !row.expanded;
        this.bulkExpanded = this.rows.every((r: any) => r.expanded);
    }

    removeSampleGroups() {
        const message = "You are about to delete all eligible sample groups. Do you want to proceed?";
        const title = "Delete Sample Groups";

        this.confirmService.confirmDelete(title, message).then((result) => {
            if (result === "yes") {
                this.rows.forEach((row) => {
                    if (row.sampleGroup.Sample.length <= 0) {
                        const memberTaskInstances = row.sampleGroup.TaskInstance.MemberTaskInstance ?? []; 
                        if (isAnyTaskEndState(memberTaskInstances)) {
                            return;
                        }
                        if (row.sampleGroup.SampleGroupSourceMaterial && row.sampleGroup.SampleGroupSourceMaterial.length > 0) {
                            while (row.sampleGroup.SampleGroupSourceMaterial.length > 0) {
                                this.dataManager.deleteEntity(row.sampleGroup.SampleGroupSourceMaterial[0]);
                            }
                        }

                        this.dataManager.deleteEntity(row.sampleGroup);
                    }
                });
                this.initRows();
                this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
            }
        });
    }

    canRemoveAnySampleGroup(): boolean {
        if (this.rows && this.rows.length > 0) {
            const allTasksAreEndState = this.rows.every(r => {
                const memberTaskInstances = r.sampleGroup?.TaskInstance?.MemberTaskInstance ?? []; 
                return isAnyTaskEndState(memberTaskInstances);
            });
            if (allTasksAreEndState) {
                return false;
            }

            for (const row of this.rows) {
                if (row.sampleGroup.Sample.length <= 0) {
                    return true;
                }
            }
        }
        return false;
    }

    canRemoveSampleGroupSourceMaterial(sampleGroup: SampleGroup): boolean {
        const isSampleGroupEmpty = sampleGroup.Sample.length === 0;
        const isAnyTaskInEndState = isAnyTaskEndState(sampleGroup?.TaskInstance?.MemberTaskInstance);
        if (this.isSampleGroupsEditableFlag) {
            return isSampleGroupEmpty && !isAnyTaskInEndState;
        }
        return isSampleGroupEmpty;
    }

    isSampleGroupLocked(sampleGroup: SampleGroup) {
        if (this.isSampleGroupsEditableFlag) {
            return isAnyTaskEndState(sampleGroup?.TaskInstance?.MemberTaskInstance);
        }

        return this.doesSampleGroupHaveAssociatedSamples(sampleGroup);
    }

    doesSampleGroupHaveAssociatedSamples(sampleGroup: SampleGroup): boolean {
        return sampleGroup?.Sample?.length > 0;
    }

    /**
     * Records sample group changes only if the sample group has associated samples.
     * 
     * These changes will be handled in save changes on success callback.
     */
    recordSampleGroupChange(event: any, row: DataRow, field: string) {
        if (!this.isSampleGroupsEditableFlag) {
            return;
        }
        const samples = row.sampleGroup.Sample;
        if (!samples || samples.length === 0) {
            return;
        }
        /**
         * If the changed value is the same as it was originally, 
         * delete from pending changes as there are no new changes.
         */ 
        if (softCompare(this.originalSampleGroupChangesMap[row.sampleGroup.C_SampleGroup_key][field], event)) {
            if (!softCompare(this.pendingSampleGroupChangesMap?.[row.sampleGroup.C_SampleGroup_key]?.[field], null)) {
                delete this.pendingSampleGroupChangesMap[row.sampleGroup.C_SampleGroup_key][field];
            }
            /**
             * If there is at most 1 value in pending changes, delete
             * the entire pending change record as that value is just
             * a reference to the sample group.
             */
            if (this.pendingSampleGroupChangesMap?.[row.sampleGroup.C_SampleGroup_key]) {
                if (Object.values(this.pendingSampleGroupChangesMap[row.sampleGroup.C_SampleGroup_key]).length <= 1) {
                    delete this.pendingSampleGroupChangesMap[row.sampleGroup.C_SampleGroup_key];
                }
            }
            return;
        }

        if (!this.pendingSampleGroupChangesMap[row.sampleGroup.C_SampleGroup_key]) {
            this.pendingSampleGroupChangesMap[row.sampleGroup.C_SampleGroup_key] = {
                sampleGroup: row.sampleGroup
            };
        }

        this.pendingSampleGroupChangesMap[row.sampleGroup.C_SampleGroup_key][field] = event;
    }

    private async handlePendingSampleGroupChanges() {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });

            const sampleGroupKeys = Object.keys(this.pendingSampleGroupChangesMap);
            if (sampleGroupKeys.length === 0) {
                return;
            }

            for (const key of sampleGroupKeys) {
                if (this.jobPharmaDetailService.sampleGroupsTasksHasEndState([this.pendingSampleGroupChangesMap[key].sampleGroup])) {
                    await this.jobPharmaDetailService.showTasksCompletedModal();
                    return;
                }
            }

            return await this.jobPharmaDetailService.handlePendingSampleGroupChanges(this.pendingSampleGroupChangesMap);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    async beforeSave(token: SaveCancellationToken): Promise<void> {
        if (!this.isSampleGroupsEditableFlag) {
            return;
        }
        try {
            const isSuccess = await this.handlePendingSampleGroupChanges();
            if (isSuccess) {
                return;
            }
            const sampleGroupKeys = Object.keys(this.pendingSampleGroupChangesMap).map(key => parseInt(key, 10)) ?? [];
            if (sampleGroupKeys.length === 0) {
                return;
            }
            this.dataManager.rejectChangesToEntityByFilter('SampleGroup', (item: SampleGroup) => {
                return sampleGroupKeys.includes(item.C_SampleGroup_key);
            });
            this.loggingService.logWarning("SampleGroup changes have been discarded.", null, this.COMPONENT_LOG_TAG, true);

            token.cancelled = true;
        } finally {
            this.pendingSampleGroupChangesMap = {};
        }
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}
