import { Injectable } from '@angular/core';
import { GlpBaseFacetStateService } from '../../services/glp-base-facet-state.service';
import {
    Entity as InternalEntity,
    InputDefault,
    Pattern,
    Protocol,
    ProtocolTask,
    ProtocolTaskSection,
    SampleGroup,
    StoredFile,
    StoredFileMap,
    TaskGroup
}
    from '../../common/types';
import { DataManagerService } from '../../services/data-manager.service';
import { Entity as BreezeEntity } from 'breeze-client';

@Injectable()
export class ProtocolStateService extends GlpBaseFacetStateService<Protocol> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(protocol: InternalEntity<Protocol>): BreezeEntity[] {
        const changes: any = [];
        changes.push(...this.getChangesToPatterns(protocol));
        changes.push(...this.getChangesToProtocolTaskGroups(protocol));
        changes.push(...this.getChangesToProtocolTasksSection(protocol));
        changes.push(...this.getChangesToProtocolTasks(protocol));
        changes.push(...this.getChangesToInputDefaults(protocol));
        changes.push(...this.getChangesToSampleGroups(protocol));
        changes.push(...this.getChangesToFiles(protocol.C_Protocol_key));
        return changes;
    }

    public getChangesToPatterns(
        protocol: Protocol,
    ): InternalEntity<Pattern>[] {
        const patternIdsForProtocol
            = protocol.TaskGroup.flatMap(taskGroup => taskGroup.C_Pattern_key);

        const allChangedPatterns = (this.dataManagerService
            .getManager()
            .getChanges("Pattern") ?? []) as InternalEntity<Pattern>[];
        const changedPatternsForProtocol = allChangedPatterns
            .filter((pattern) =>
                patternIdsForProtocol.some(
                    (patternId) =>
                        patternId === pattern.C_Pattern_key,
                ),
            );
        return changedPatternsForProtocol;
    }

    public getChangesToProtocolTaskGroups(
        protocol: Protocol,
    ): InternalEntity<TaskGroup>[] {
        const taskGroupIdsForProtocol
            = protocol.TaskGroup.map(tasksSection => tasksSection.C_TaskGroup_key);

        const allChangedProtocolTaskGroups = (this.dataManagerService
            .getManager()
            .getChanges("TaskGroup") ?? []) as InternalEntity<TaskGroup>[];
        const changedTaskGroupsForProtocol = allChangedProtocolTaskGroups
            .filter((taskGroup) =>
                taskGroupIdsForProtocol.some(
                    (protocolTaskGroupId) =>
                        protocolTaskGroupId === taskGroup.C_TaskGroup_key,
                ),
            );
        return changedTaskGroupsForProtocol;
    }

    public getChangesToProtocolTasksSection(
        protocol: Protocol,
    ): InternalEntity<ProtocolTaskSection>[] {
        const protocolTasksSectionIdForProtocol
            = protocol.ChildProtocolTaskSection.map(tasksSection => tasksSection.C_ProtocolTaskSection_key);
        
        const allChangedProtocolTasksSections = (this.dataManagerService
            .getManager()
            .getChanges("ProtocolTaskSection") ?? []) as InternalEntity<ProtocolTaskSection>[];
        const changedTaskSectionsForProtocol = allChangedProtocolTasksSections
            .filter((tasksSections) =>
                protocolTasksSectionIdForProtocol.some(
                    (protocolTaskSectionId) =>
                        protocolTaskSectionId === tasksSections.C_ProtocolTaskSection_key,
                ),
            );
        return changedTaskSectionsForProtocol;
    }
    
    public getChangesToProtocolTasks(
        protocol: Protocol,
    ): InternalEntity<ProtocolTask>[] {
        const protocolTasksIdForProtocol
            = protocol.ProtocolTask.map(protocolTask => protocolTask.C_ProtocolTask_key);
        const allChangedProtocolTasks = (this.dataManagerService
            .getManager()
            .getChanges("ProtocolTask") ?? []) as InternalEntity<ProtocolTask>[];
        const changedProtocolsTasksForProtocol = allChangedProtocolTasks
            .filter((protocolTask) =>
                protocolTasksIdForProtocol.some(
                    (protocolTaskId) =>
                        protocolTaskId === protocolTask.C_ProtocolTask_key,
                ),
            );
        return changedProtocolsTasksForProtocol;
    }

    public getChangesToInputDefaults(
        protocol: Protocol,
    ): InternalEntity<InputDefault>[] {
        const inputDefaultsForProtocol
            = protocol.ProtocolTask.flatMap(protocolTask =>
            protocolTask.InputDefault.map(inputDefault => inputDefault.C_InputDefault_key));

        const allChangedInputDefaults = (this.dataManagerService
            .getManager()
            .getChanges("InputDefault") ?? []) as InternalEntity<InputDefault>[];
        const changedInputDefaultsForProtocol = allChangedInputDefaults
            .filter((inputDefault) =>
                inputDefaultsForProtocol.some(
                    (inputDefaultId) =>
                        inputDefaultId === inputDefault.C_InputDefault_key,
                ),
            );
        return changedInputDefaultsForProtocol;
    }

    public getChangesToSampleGroups(
        protocol: Protocol,
    ): InternalEntity<SampleGroup>[] {
        const sampleGroupsIdForProtocol
            = protocol.ProtocolTask.flatMap(protocolTask => 
            protocolTask.SampleGroup.map(sampleGroup => sampleGroup.C_SampleGroup_key));
        
        const allChangedSampleGroups = (this.dataManagerService
            .getManager()
            .getChanges("SampleGroup") ?? []) as InternalEntity<SampleGroup>[];
        const changedSampleGroupsForProtocol = allChangedSampleGroups
            .filter((sampleGroup) =>
                sampleGroupsIdForProtocol.some(
                    (sampleGroupId) =>
                        sampleGroupId === sampleGroup.C_SampleGroup_key,
                ),
            );
        return changedSampleGroupsForProtocol;
    }

    getChangesToFiles(protocolKey: number) {
        const changes: any = [];

        const storedFileMaps = (this.dataManagerService
                .getManager()
                .getChanges("StoredFileMap") ??
            []) as InternalEntity<StoredFileMap>[];
        const changedStoreFileMaps = storedFileMaps.filter(
            (storedFileMap) => storedFileMap.C_Protocol_key === protocolKey,
        );

        const storedFiles = (this.dataManagerService
            .getManager()
            .getChanges("StoredFile") ?? []) as InternalEntity<StoredFile>[];

        // Get corresponding files descriptions
        const changedStoreFiles = storedFiles.filter((storedFile) =>
            changedStoreFileMaps.some(
                (storedFileMap) =>
                    storedFileMap.C_StoredFile_key === storedFile.C_StoredFile_key,
            ),
        );

        changes.push(...changedStoreFileMaps);
        changes.push(...changedStoreFiles);
        return changes;
    }
    
    discardChanges(protocol: InternalEntity<Protocol>): void {
        // TODO: implement facet-level discard operation after release of GLP
        throw new Error('Method not implemented.');
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<Protocol>): BreezeEntity[] {
        const changes: any = [];
        changes.push(...this.getChangesToDeletedTaskGroups(entity))
        changes.push(...this.getChangesToDeletedTasksSections(entity))
        changes.push(...this.getChangesToDeletedProtocolTasks(entity))
        changes.push(...this.getChangesToDeletedDefaultInputs(entity))
        changes.push(...this.getChangesToDeletedSampleGroups(entity))
        return changes;
    }
    
    private getChangesToDeletedTaskGroups(protocol: Protocol): InternalEntity<TaskGroup>[] {
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey<TaskGroup>('TaskGroup',
                'C_Protocol_key', protocol.C_Protocol_key);
    }

    private getChangesToDeletedTasksSections(protocol: Protocol): InternalEntity<ProtocolTaskSection>[] {
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey<ProtocolTaskSection>('ProtocolTaskSection',
                'C_ParentProtocol_key', protocol.C_Protocol_key);
    }
    
    private getChangesToDeletedProtocolTasks(protocol: Protocol): InternalEntity<ProtocolTask>[] {
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey<ProtocolTask>('ProtocolTask', 
                'C_Protocol_key', protocol.C_Protocol_key);
    }
    

    private getChangesToDeletedSampleGroups(protocol: Protocol): InternalEntity<SampleGroup>[] {

        const tasksBelongingToProtocol = this.getTasksBelongingToProtocol(protocol);
        
        const changes: any = [];
        for (let protocolTask of tasksBelongingToProtocol){
           const deletedSampleGroups= this.dataManagerService
                .getChangesToRelatedDeletedEntityByKey<SampleGroup>('SampleGroup',
                    'C_ProtocolTask_key', protocolTask.C_ProtocolTask_key);
           changes.push(...deletedSampleGroups);
        }
        return changes;
    }

    private getChangesToDeletedDefaultInputs(protocol: Protocol): InternalEntity<InputDefault>[] {
        const tasksBelongingToProtocol = this.getTasksBelongingToProtocol(protocol);

        const changes: any = [];
        for (let protocolTask of tasksBelongingToProtocol) {
            const deletedInputDefaults = this.dataManagerService
                .getChangesToRelatedDeletedEntityByKey<InputDefault>('InputDefault',
                    'C_ProtocolTask_key', protocolTask.C_ProtocolTask_key);
            changes.push(...deletedInputDefaults);
        }
        return changes;
    }

    private getTasksBelongingToProtocol(protocol: Protocol) {
        const tasksBelongingToProtocol: any = [];
        const protocolTasks
            = protocol.ProtocolTask.map(protocolTask => protocolTask);

        tasksBelongingToProtocol.push(...protocolTasks);
        const deletedProtocolTasks = this.getChangesToDeletedProtocolTasks(protocol);
        tasksBelongingToProtocol.push(...deletedProtocolTasks);
        return tasksBelongingToProtocol;
    }
}
