import {
    CellFormatterService,
    FormatterOptions,
    TableOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditVocabulariesClinicalObservationStatusesTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private isGLP: boolean
    ) {
        this.options = this.getDefaultOptions();

        // Hide EndState column if not isGLP
        if (!this.isGLP) {
            this.options.columns.splice(6, 1);
        }
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'clinical-observation-statuses-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Clinical Observation Status',
                    field: 'ClinicalObservationStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ClinicalObservationStatus', 'ClinicalObservationStatus'),
                    cellClass: AuditClassFactory.createCellClass('clinicalobservationstatus'),
                },
                {
                    displayName: 'Active',
                    field: 'Active',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Active', 'Active'),
                    cellClass: AuditClassFactory.createCellClass('active'),
                },
                {
                    displayName: 'End State',
                    field: 'EndState',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('EndState', 'EndState'),
                    cellClass: AuditClassFactory.createCellClass('endstate'),
                },
                {
                    displayName: 'Default',
                    field: 'Default',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Default', 'Default'),
                    cellClass: AuditClassFactory.createCellClass('default'),
                },
                {
                    displayName: 'Sort Order',
                    field: 'SortOrder',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SortOrder', 'SortOrder'),
                    cellClass: AuditClassFactory.createCellClass('sortorder'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}
