import {Injectable} from '@angular/core';
import {GlpBaseFacetStateService} from '../../services/glp-base-facet-state.service';
import {
    Animal,
    CompatibilityMaterial,
    Entity as InternalEntity,
    MaterialLocation,
    MaterialPool,
    MaterialPoolMaterial,
    SocialGroupMaterial,
    TaskInput,
    TaskInstance,
    TaskMaterialPool,
} from '../../common/types';
import {DataManagerService} from '../../services/data-manager.service';
import {Entity as BreezeEntity} from 'breeze-client';

@Injectable()
export class HousingStateService extends GlpBaseFacetStateService<MaterialPool> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(housing: InternalEntity<MaterialPool>): BreezeEntity[] {
        const changes: any = [];
        changes.push(...this.getChangesToTaskInstances(housing));
        changes.push(...this.getChangesToMaterialPoolMaterials(housing));
        changes.push(...this.getChangesToAnimals(housing));
        changes.push(...this.getChangesToSocialGroupMaterials(housing));
        changes.push(...this.getChangesToTaskInputs(housing));
        changes.push(...this.getChangesToTaskMaterialPools(housing));
        changes.push(...this.getChangesToHousingLocations(housing));
        changes.push(...this.getChangesToCompatibilityMaterials(housing));
        return changes;
    }

    public getChangesToTaskInstances(
        housing: MaterialPool,
    ): InternalEntity<TaskInstance>[] {
        const taskInstanceIdsForHousing
            = housing.TaskMaterialPool.map(pool => pool.C_TaskInstance_key);
        const allChangedTaskInstances = (this.dataManagerService
            .getManager()
            .getChanges("TaskInstance") ?? []) as InternalEntity<TaskInstance>[];
        const taskInstancesForHousing = allChangedTaskInstances.filter((taskInstance) =>
            taskInstanceIdsForHousing.some(
                (taskInstanceId) =>
                    taskInstanceId === taskInstance.C_TaskInstance_key,
            ),
        );
        return taskInstancesForHousing;
    }

    public getChangesToMaterialPoolMaterials(
        housing: MaterialPool,
    ): InternalEntity<MaterialPoolMaterial>[] 
    {
        const animalMaterialKeys = housing.MaterialPoolMaterial.map(m => m.C_Material_key);
        const allChangedMaterialPoolMaterials = (this.dataManagerService
            .getManager()
            .getChanges("MaterialPoolMaterial") ?? []) as InternalEntity<MaterialPoolMaterial>[];
        const changes = allChangedMaterialPoolMaterials.filter((taskInstance) =>
            animalMaterialKeys.some(
                (animalMaterialKey) =>
                    animalMaterialKey === taskInstance.C_Material_key,
            ),
        );
        return changes;
    }

    public getChangesToAnimals(
        housing: MaterialPool,
    ): InternalEntity<Animal>[] {
       const animalMaterialKeys =  housing.MaterialPoolMaterial.map(m => m.C_Material_key);
        const animals = (this.dataManagerService
            .getManager()
            .getChanges("Animal") ?? []) as InternalEntity<Animal>[];

        const changedAnimals = animals.filter((animal) =>
            animalMaterialKeys.some(
                (materialKey) =>
                    materialKey === animal.C_Material_key,
            ),
        );
        return changedAnimals;
    }

    public getChangesToSocialGroupMaterials(
        housing: MaterialPool,
    ): InternalEntity<SocialGroupMaterial>[] {
        return this.dataManagerService.getChangesToRelatedCollections<SocialGroupMaterial>(
            "SocialGroupMaterial",
            "C_MaterialPool_key",
            housing.C_MaterialPool_key,
        );
    }

    public getChangesToTaskInputs(
        housing: MaterialPool,
    ): InternalEntity<TaskInput>[] {
        const taskInstanceIds = housing.TaskMaterialPool.map(pool => pool.C_TaskInstance_key);
        const taskInputs = (this.dataManagerService
            .getManager()
            .getChanges("TaskInput") ?? []) as InternalEntity<TaskInput>[];
        const changedTaskInputs = taskInputs.filter((taskInput) =>
            taskInstanceIds.some(
                (taskInstanceId) =>
                    taskInstanceId === taskInput.C_TaskInstance_key,
            ),
        );
        return changedTaskInputs;
    }


    public getChangesToTaskMaterialPools(
        housing: MaterialPool,
    ): InternalEntity<TaskMaterialPool>[] {
        return this.dataManagerService.getChangesToRelatedCollections<TaskMaterialPool>(
            "TaskMaterialPool",
            "C_MaterialPool_key",
            housing.C_MaterialPool_key,
        );
    }


    public getChangesToHousingLocations(
        housing: MaterialPool,
    ): InternalEntity<MaterialLocation>[] {
        return this.dataManagerService.getChangesToRelatedCollections<MaterialLocation>(
            "MaterialLocation",
            "C_MaterialPool_key",
            housing.C_MaterialPool_key,
        );
    }

    public getChangesToCompatibilityMaterials(
        housing: MaterialPool,
    ): InternalEntity<CompatibilityMaterial>[] {
        return this.dataManagerService.getChangesToRelatedCollections<CompatibilityMaterial>(
            "CompatibilityMaterial",
            "C_MaterialPool_key",
            housing.C_MaterialPool_key,
        );
    }
    
    discardChanges(housing: InternalEntity<MaterialPool>): void {
        if (!housing) {
            return;
        }
        this.rejectChangesToRelatedEntities(housing);
        this.dataManagerService.rejectEntityAndRelatedPropertyChanges(housing);
    }

    private rejectChangesToRelatedEntities(housing: MaterialPool) {
        this.rejectChangesToTaskMaterialPools(housing);
        this.rejectChangesToDevices();
        this.rejectChangesToMaterialLocations(housing);
        this.rejectChangesToMaterialPoolMaterials(housing);
        this.rejectChangesToAnimals(housing);
        this.rejectChangesToSocialGroupMaterials(housing);
        this.rejectChangesToCompatibilityMaterials(housing);
    }

    private rejectChangesToCompatibilityMaterials(housing: MaterialPool) {
        this.dataManagerService.rejectChangesToEntityByFilter(
            'CompatibilityMaterial', (item: any) => {
                return item.C_MaterialPool_key === housing.C_MaterialPool_key;
            }
        );
    }

    private rejectChangesToSocialGroupMaterials(housing: MaterialPool) {
        this.dataManagerService.rejectChangesToEntityByFilter(
            'SocialGroupMaterial', (item: any) => {
                return item.C_MaterialPool_key === housing.C_MaterialPool_key;
            }
        );
    }

    private rejectChangesToMaterialPoolMaterials(housing: MaterialPool) {
        const changesToMaterialPoolMaterials = this.getChangesToMaterialPoolMaterials(housing);
        for (const materialPoolMaterial of changesToMaterialPoolMaterials) {
            materialPoolMaterial.entityAspect.rejectChanges();
        }
    }

    private rejectChangesToAnimals(housing: MaterialPool) {
        const changesToAnimals = this.getChangesToAnimals(housing);
        for (const animal of changesToAnimals){
            animal.entityAspect.rejectChanges();
        }
    }

    private rejectChangesToMaterialLocations(housing: MaterialPool) {
        this.dataManagerService.rejectChangesToEntityByFilter(
            'MaterialLocation', (item: any) => {
                return item.C_MaterialPool_key === housing.C_MaterialPool_key;
            }
        );
    }

    private rejectChangesToDevices() {
        this.dataManagerService.rejectChangesToEntityByFilter(
            'Device', (item: any) => {
                // there is no way to tell which Devices may have been removed
                // It is fairly safe to just reject any changes to Device objects
                return true;
            }
        );
    }

    private rejectChangesToTaskMaterialPools(housing: MaterialPool) {
        const taskMaterialPools = this.dataManagerService.rejectChangesToEntityByFilter(
            'TaskMaterialPool', (item: any) => {
                return item.C_MaterialPool_key === housing.C_MaterialPool_key;
            }
        );
        for (const taskMaterialPool of taskMaterialPools) {
            const taskInstances = this.dataManagerService.rejectChangesToEntityByFilter(
                'TaskInstance', (item: any) => {
                    return item.C_TaskInstance_key === taskMaterialPool.C_TaskInstance_key;
                }
            );
            for (const taskInstance of taskInstances) {
                this.dataManagerService.rejectChangesToEntityByFilter(
                    'TaskInput', (item: any) => {
                        return item.C_TaskInstance_key === taskInstance.C_TaskInstance_key;
                    }
                );

            }
        }
    }
    

    getDeleteNavPropertyChanges(entity: InternalEntity<MaterialPool>): BreezeEntity[] {
        return [];
    }
}
