import { Injectable } from "@angular/core";
import { ExtendedJob, Job, JobMaterial, Material, Sample } from "@common/types";
import { Entity } from "@common/types";
import { SampleService } from "src/app/samples";
import { JobPharmaDetailService } from "./job-pharma-detail.service";
import { CopyBufferService } from "@common/services";
import { JobPharmaDataAccessService } from "./job-pharma-data-access.service";

@Injectable()
export class JobPharmaLogicService {
    constructor(
        private sampleService: SampleService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private copyBufferService: CopyBufferService,
        private jobPharmaDataAccessService: JobPharmaDataAccessService
    ){ }

    async getSampleJobMaterials(jobKey: number, pageNumber: number): Promise<JobMaterial[]> {
        return this.jobPharmaDataAccessService.getSampleJobMaterials(jobKey, pageNumber);
    }

    async getSampleCount(jobKey: number): Promise<number>{
        return this.jobPharmaDataAccessService.getSampleCount(jobKey);
    }

    // TODO: The call to the jobPharmaDetailService will be replaced with a GraphQL query
    addSamplesToJob(samples: Sample[], job: Entity<Job & ExtendedJob> | Entity<Job>): Promise<any> {
        const materials = samples.map(s => s.Material) as Entity<Material>[];
        return this.jobPharmaDetailService.addMaterialsToJobIfMissing(job, materials);
    }

    onDropSamples(job: Entity<Job & ExtendedJob> | Entity<Job>): Promise<any> {
        const samples = this.sampleService.draggedSamples;
        this.sampleService.draggedSamples = [];

        return this.addSamplesToJob(samples, job);
    }

    onPasteSamples(job: Entity<Job & ExtendedJob> | Entity<Job>): Promise<any> {
        if (!this.copyBufferService.hasSamples()) {
            return Promise.resolve();
        }

        const samples = this.copyBufferService.paste();
        return this.addSamplesToJob(samples, job);
    }

    // TODO: again, call to the jobPharmaDetailSerivce will be replaced with GraphGL query
    removeSampleJobMaterial(jobMaterial: JobMaterial): Promise<void>{
        return this.jobPharmaDetailService.removeJobMaterial(jobMaterial);
    }

}